import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class BusyIndicatorService {

  private DELAY_IN_MS: number = 666;
  private subject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _isBusy: boolean = false;
  private _interval: number;

  constructor() {
  }

  // 0 state is stasis - it means the sequence is over
  // we want to show indicators quickly
  // but even if the request is fast, don't flicker

  public change(newValue: boolean): void {

    if (!this._isBusy) {
      this._isBusy = newValue;
      this.doChange(newValue);
      return;
    }

    this._isBusy = newValue;
    clearInterval(this._interval);
    this._interval = window.setTimeout(() => this.doChange(this._isBusy), this.DELAY_IN_MS);
  }

  private doChange(value: boolean) {
    if (value === true) {
      this.subject.next(value);
    }

    if (value === false) {
      this._isBusy = value;
      this.subject.next(value);
    }
  }

  public get observable(): Observable<boolean> {
    return this.subject.asObservable();
  }
}

<div class="offset-1 col-10 mt-30 mb-30">
  <div class="paper col-12">
    <div class="taxonomy-form">
      <h1>Create a Taxonomy</h1>
      <div id="tags"  class="po-form">

        <form>
          <p>Please manually enter your company's taxonomy. This is an optional step and is only used to help identify derived topics.</p>
          <p>You can copy and paste an from an existing source using a ',' delimiter</p>
          <div class="form-group">
            <div class="input-group">
              <input type="text"
                     class="form-control mb-30 font-weight-normal ps-0"
                     id="lens-name"
                     name="lens-name"
                     placeholder="Name your taxonomy"
                     [(ngModel)]="taxonomy.name">
              <span class="input-group-addon mb-30 aRequired text-center font-weight-normal ps-0">*</span>
            </div>
          </div>

          <mat-form-field>
            <mat-label>Categories</mat-label>
            <mat-chip-grid #chipGrid aria-label="Categories">
              <mat-chip-row *ngFor="let cat of categories" (removed)="remove(cat)">
                {{cat}}
                <button matChipRemove [attr.aria-label]="'remove ' + cat">
                  <fa-icon [icon]="removeIcon"></fa-icon>
                </button>
              </mat-chip-row>
            </mat-chip-grid>
            <input placeholder="Enter or paste categories" #catInput
              [matChipInputFor]="chipGrid"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              (matChipInputTokenEnd)="add($event)"/>
          </mat-form-field>

          <!-- <tag-input name="tags" [addOnPaste]="true"
                     [pasteSplitPattern]="','"
                     [(ngModel)]='categories'
                     [editable]="true"
                     [modelAsStrings]="true"
                     [placeholder]="'Add category'"
                     [secondaryPlaceholder]="'Enter or paste categories'"
                     [trimTags]="true"></tag-input> -->
        </form>

        <div id="button-group" class="col-12">
          <button class="btn btn-primary" (click)="onSave()">Save</button>
        </div>

      </div>
    </div>
  </div>
</div>

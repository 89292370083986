import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {UBQ} from '../app-config';
import {IModel} from '../types/model';
import {Router} from '@angular/router';

@Injectable()
export class ModelService extends BaseService<IModel> {

  constructor(public _http: HttpClient, public ubq: UBQ, public router: Router) {
    super(_http, ubq, router);
    this.init('model');
  }

  serializeOne(response: any): IModel {
    const model = <IModel>response;
    if (model.topics) {
      Object.keys(model.topics).forEach(topicIndex => {
        model.topics[topicIndex] = model.topics[topicIndex].filter(t => t.trim().length);
      });
    }
    if (model.topPhrases) {
      Object.keys(model.topPhrases).forEach(topicIndex => {
        model.topPhrases[topicIndex] = model.topPhrases[topicIndex].filter(t => t.trim().length);
      });
    }
    return model;
  }

  serializeMany(response: any): Array<IModel> {
    return (response as IModel[]).map(r => this.serializeOne(r));
  }
}

import {Component} from '@angular/core';
import {UBQ} from '../app-config';

@Component({
  selector: 'app-main',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {

  constructor(public ubq: UBQ) {
  }
}

import {Component, ElementRef, EventEmitter, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'app-search-input',
  template: `
    <div class="d-flex align-items-center" (click)="showInputChange(true)">
      <a><fa-icon [icon]="['fal', 'search']"></fa-icon>&nbsp;</a>
      <input type='text'
             #search
             (keyup)="updateText($event)"
             (blur)="filterBlurred($event)"
             *ngIf="showInput"
             class="flex-grow-1 search-input"/>
      <label class="label-search mb-0 me-2 text-uppercase" *ngIf="!showInput">
        Search
      </label>
    </div>
  `,
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent {

  @Output() searchText = new EventEmitter<string>();

  @ViewChild('search') searchElement: ElementRef;

  showInput = false;

  updateText($event) {
    // console.log('updateTextFilter', $event);
    this.searchText.emit($event.target.value);
  }

  showInputChange(flag?: boolean) {
    this.showInput = flag ? flag : !this.showInput;
    if (this.showInput) {
      setTimeout(() => {
        this.searchElement.nativeElement.focus();
      }, 0);
    } else {
      this.searchText.emit('');
    }
  }

  filterBlurred($event) {
    if (!$event.target.value) {
      this.showInput = false;
    }
  }
}

<div class="down-arrow">
  <svg width="17px" height="10px" viewBox="0 0 17 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs></defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Group-41" transform="translate(-694.000000, -224.000000)" fill="#6A7883" fill-rule="nonzero" stroke="#6A7883" stroke-width="0.25">
        <g id="create-model-modal-copy-2">
          <g id="Select-Data-Copy-3" transform="translate(190.000000, 197.000000)">
            <path d="M515.850618,39.4364031 C515.930964,39.5147032 516.036443,39.5540541 516.141922,39.5540541 C516.247402,39.5540541 516.352881,39.5147032 516.433227,39.4364031 C516.59433,39.2794012 516.59433,39.0256284 516.433227,38.8686266 L509.440686,32.0541042 L516.433227,25.2395819 C516.59433,25.0825801 516.59433,24.8288073 516.433227,24.6718054 C516.272123,24.5148036 516.011721,24.5148036 515.850618,24.6718054 L508.566773,31.770216 C508.40567,31.9272178 508.40567,32.1809906 508.566773,32.3379925 L515.850618,39.4364031 Z" id="Shape" transform="translate(512.500000, 32.054054) rotate(-90.000000) translate(-512.500000, -32.054054) "></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</div>

import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {FeedCardConfig} from '../../types/feed';
import {Router} from '@angular/router';
import {ConfigService} from '../../services-http/config.service';
import { IModelAsset, ModelAssetStatus } from '../../types/model-asset';
import { faCheckCircle, faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-feed-dl-card',
  template: `
    <app-feed-card [title]="title" [time]="config?.dto.createdAt" [isError]="isError">
      <ng-container class="feed-card-body">
        <div class="p-feedcard-primary">{{config?.dto.name}}</div>
        <div class="p-feedcard-secondary">
          <span class="p-feedcard-dt">Training Data : </span>{{config?.dto?.hardLabeled?.givenName}}
        </div>
        <div class="p-feedcard-secondary">
          <span class="p-feedcard-dt">Status : </span>
          <span *ngIf="status === 'error'" class="error"><fa-icon [icon]="errorIcon"></fa-icon>&nbsp;Error: {{config.dto.taskStatus?.error?.msg}}</span>
          <span *ngIf="status === 'success'" class="success"><fa-icon [icon]="successIcon"></fa-icon>&nbsp;Completed</span>
          <span *ngIf="status === 'queued'" class="queued"><app-hourglass></app-hourglass>&nbsp;Queued</span>
          <span *ngIf="status === 'inProgress'" class="inProgress">Processing</span>
        </div>
        <div *ngIf="status === 'inProgress'" class="p-feedcard-secondary">
          <span class="p-feedcard-dt">Epoch : </span> {{epoch()}}
        </div>
        <div *ngIf="status === 'inProgress'" class="p-feedcard-progress" (click)="gotoDL()">
          <app-gradient-progress-bar [value]="epochProgress()"></app-gradient-progress-bar>
        </div>
      </ng-container>
      <ng-container class="feed-card-footer">
        <button class="btn btn-primary" (click)="gotoDL()">View Model</button>
        <button *ngIf="status === 'success'" type="button" class="btn btn-primary" (click)="doDeploy()">
          Deploy Model
        </button>
      </ng-container>
    </app-feed-card>
  `,
  styleUrls: ['feed-card.component.scss']
})
export class DeepLearningCardComponent implements OnChanges {

  @Input() config: FeedCardConfig<IModelAsset>;

  title = 'Deep Learning Model';
  status: ModelAssetStatus;
  isError = false;
  canDeploy = false;

  errorIcon = faExclamationTriangle;
  successIcon = faCheckCircle;

  constructor(private configService: ConfigService,
              private router: Router) {
    this.configService.getPiensoApps().toPromise().then(apps => {
      this.canDeploy = apps.includes('ANALYSIS');
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const status = this.status = this.config.dto.status;
    this.isError = status === 'error';
    this.canDeploy = status === 'success';
  }

  // lastEntry() {
  //   const th = this.config?.dto?.trainingHistory;
  //   return (th && th.length) ? th[th.length - 1] : null;
  // }

  epoch() {
    return this.config?.dto?.taskStatus?.currentTrainingEpoch ?? '-';
  }

  epochProgress() {
    switch (this.status) {
      case 'error':
      case 'queued':
        return 0;
      case 'success':
        return 100;
      case 'inProgress':
        return (this.config?.dto?.trainingHistory?.[this.epoch()].epochPct ?? 0) * 100;
      default:
        return 0;
    }
  }

  gotoDL() {
    this.router.navigateByUrl(`/platform/dl/${this.config.dto.id}`);
  }

  doDeploy() {
    window.location.href = window.location.origin + '/analysis/deploy?model=' + encodeURIComponent(this.config.dto.uuid);
  }
}

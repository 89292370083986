import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {UBQ} from '../app-config';
import {Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import type {LicensePayload} from '../types/license';

@Injectable({providedIn: 'root'})
export class ConfigService {

  private readonly endpoint: string;

  private cache = new Map<string, string>();
  private license: {customTopics: number, analysisSlots: number} = null;

  constructor(private http: HttpClient, private ubq: UBQ) {
    this.endpoint = ubq.apiEndpoint + '/config/';
  }

  httpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }

  getPiensoApps(): Observable<string[]> {
    return this.get('pienso_apps').pipe(
      map(s => s[0].replace(/\"/g, '').split(' '))
    );
  }

  async getLicense(): Promise<LicensePayload> {
    // bypass nginx error: auth request unexpected status: 304 while sending to client
    const httpHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Cache-Control': 'no-store, no-cache'
      })
    };
    return this.license = await this.http
      .get<LicensePayload>(this.endpoint + `license/`, httpHeaders).pipe(
        map(license => {
          license.terminationDate = new Date(+license.terminationDate);
          return license;
        })
      ).toPromise();
  }

  get(...params: string[]): Observable<string[]> {
    let hasAll = true;
    const cached: string[] = [];
    for (let i = 0; i < params.length; i++) {
      const v = this.cache.get(params[i]);
      if (!v) {
        hasAll = false;
        break;
      }
      cached.push(v);
    }
    if (hasAll) {
      return of(cached);
    }
    return this.http.get<string[]>(this.endpoint + params.join('+'), this.httpOptions())
      .pipe(
        tap(values => params.forEach((p, i) => { this.cache.set(p, values[i]); }))
      );
  }

}

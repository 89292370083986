import {IViewBy} from '../shared/top-filter/iviewby.interface';

export const SELECT_FILTER_UA_OPTIONS: IViewBy[] = [
  {
    key: 'createdAt',
    label: 'Most Recent',
    order: 'desc',
    selectedByDefault: true,
    isFilter: false
  },
  {
    key: 'isSoftDeleted',
    label: 'Hidden',
    order: 'desc',
    isFilter: true
  }
];

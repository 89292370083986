import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { LensesIndexComponent } from './lenses.component';
import { LensListComponent } from './lens-list/lens-list.component';
import { StopWordListComponent } from './stop-word-list/stop-word-list.component';
import { MatTabsModule } from '@angular/material/tabs';
import { SharedModule } from '../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LensService } from '../services-http/lens.service';
import { ModelTagModule } from '../model-tags/model-tag.module';
import { faCircleNotch, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { CreateLensComponent } from './create-lens/create-lens.component';
import { ListFilterService } from '../shared/top-filter/list-filter.service';
import { ModelDistributionGraphComponent } from './model-distribution-graph/model-distribution-graph.component';
import { BoxSelectorComponent } from './box-selector/box-selector.component';
import { TaxonomyInputComponent } from './taxonomy-input/taxonomy-input.component';
import { StopWordsUploadComponent } from './stop-words-upload/stop-words-upload.component';
import { StopWordEditorModule } from '../stop-word-editor/stop-word-editor.module';
import { StopwordCloneModalComponent } from './stop-word-list/stopword-clone-modal/stopword-clone-modal.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { ngfModule } from 'angular-file';
import { MatChipsModule } from '@angular/material/chips';
import { ModelService } from '../services-http/models.service';
import { TaxonomyService } from '../services-http/taxonomy.service';
import { GlobalStopWordService } from '../services-http/global-stop-word.service';
import { UBQ } from '../app-config';
import { UbqToastService } from '../ubq-toast.service';
import { TopicColorService } from '../shared/topic-color.service';
import { SourceService } from '../services-http/source.service';
import { UtilsService } from '../utils/utils.service';
import { MatPaginatorModule } from '@angular/material/paginator';
import { LensesRoutingModule } from './lenses-routing.module';
import { MatSortModule } from '@angular/material/sort';

@NgModule({
    imports: [
        CdkTableModule,
        CommonModule,
        FontAwesomeModule,
        FormsModule,
        LensesRoutingModule,
        MatChipsModule,
        MatPaginatorModule,
        MatFormFieldModule,
        MatSortModule,
        MatTabsModule,
        ModelTagModule,
        NgbModule,
        ngfModule,
        SharedModule,
        StopWordEditorModule
    ],
    providers: [
        GlobalStopWordService,
        LensService,
        ListFilterService,
        ModelService,
        SourceService,
        TaxonomyService,
        TopicColorService,
        UBQ,
        UbqToastService,
        UtilsService,
    ],
    declarations: [
        BoxSelectorComponent,
        CreateLensComponent,
        LensesIndexComponent,
        LensListComponent,
        ModelDistributionGraphComponent,
        StopwordCloneModalComponent,
        StopWordListComponent,
        StopWordsUploadComponent,
        TaxonomyInputComponent
    ],
    exports: [
        RouterModule,
        CreateLensComponent
    ]
})
export class LensesModule {
    constructor(library: FaIconLibrary) {
        library.addIcons(faInfoCircle);
        library.addIcons(faCircleNotch)
    }
 }
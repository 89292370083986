import {Injectable} from '@angular/core';
import * as TinyColor from 'tinycolor2';

@Injectable()
export class UBQ {

  private CATEGORICAL_COLORS = [
    '#3f77f4',
    '#643b2d',
    '#2a933d',
    '#e6434e',
    '#37541c',
    '#a66213',
    '#a63a62',
    '#4479b1',
    '#d93290',
    '#96678b',
    '#c85a4a',
    '#318188',
    '#3a2684',
    '#6d8e17',
    '#394725',
    '#6e4915',
    '#3a3c63',
    '#5f235a',
    '#712830',
    '#325caf',
    '#761617',
    '#d05917',
    '#386d55',
    '#98686d',
    '#bb5bbc',
    '#4f8031',
    '#d53570',
    '#236438',
    '#7c8039',
    '#583750',
    '#a06a50',
    '#404716',
    '#b55c52',
    '#642548',
    '#b46378',
    '#9e7f2b',
    '#851d93',
    '#9f6dba',
    '#3c7ca0',
    '#897242',
    '#9869e7',
    '#ca700e',
    '#7f7755',
    '#8d1c74',
    '#cb5364',
    '#654b7d',
    '#9e242f',
    '#ad6d6b',
    '#a9673c',
    '#682817',
    '#b7211d',
    '#9c7046',
    '#b86144',
    '#224e6d',
    '#3746bb',
    '#6477de',
    '#84759b',
    '#751456',
    '#956c57',
    '#64498c',
    '#214923',
    '#a77228',
    '#d84961',
    '#7854b9',
    '#7b1b33',
    '#5c5414',
    '#85381e',
    '#612938',
    '#6a3428',
    '#906881',
    '#5e1a6c',
    '#269087',
    '#533d1f',
    '#b06392',
    '#3f326a',
    '#4c5791',
    '#c65d95',
    '#65383a',
    '#5d7a53',
    '#329063',
    '#245715',
    '#39951e',
    '#584313',
    '#ae2661',
    '#31924f',
    '#30307f',
    '#8e8712',
    '#205344',
    '#8b3c72',
    '#697f46',
    '#63769d',
    '#81337a',
    '#688238',
    '#9d2d43',
    '#c3552c',
    '#603415',
    '#7574a7',
    '#3f7ec4',
    '#55286c',
    '#912c1a',
    '#771e49',
    '#8f3d54',
    '#244475',
    '#7c445c',
    '#283f83',
    '#84464d',
    '#866ead',
    '#6c7abf',
    '#573459',
    '#7f490e',
    '#767821',
    '#5b5376',
    '#3881d6',
    '#9c6861',
    '#2d7960',
    '#451e8e',
    '#548650',
    '#ad1f46',
    '#58512c',
    '#b07814',
    '#c35c76',
    '#d7478e',
    '#af6165',
    '#78522f',
    '#77753f',
    '#8e7450',
    '#c75aa6',
    '#29711c',
    '#b61e8e',
    '#9c6a9a',
    '#84446b',
    '#515e15',
    '#994399',
    '#335ca1',
    '#407991',
    '#3650ab',
    '#5f157c',
    '#a43093',
    '#aa6877',
    '#ae551c',
    '#a65d4c',
    '#8d154b',
    '#df305a',
    '#1e7438',
    '#836423',
    '#303ca2',
    '#7b437a',
    '#2789b4',
    '#b05da3',
    '#508575',
    '#6c77d1',
    '#703b90',
    '#99393a',
    '#7b401b',
    '#d2567f',
    '#d95453',
    '#697956',
    '#b85c81',
    '#816010',
    '#4e8158',
    '#b55960',
    '#914bb4',
    '#976aaa',
    '#a22b17',
    '#b12177',
    '#ab6788',
    '#9165c1',
    '#43881d',
    '#ca4b25',
    '#bd613b',
    '#7c74e6',
    '#b058d0',
    '#317fe8',
    '#912168',
    '#c12e38',
    '#834a94',
    '#9e5d51',
    '#544697',
    '#278a6d',
    '#3c5dc5',
    '#5a3c9b',
    '#c64a87',
    '#b84135',
    '#a08013',
    '#9f5923',
    '#637d24',
    '#c753c1',
    '#6f57cd',
    '#a16a7f',
    '#8b7a34',
    '#91723b',
    '#80322a',
    '#e22f7a',
    '#753299',
    '#5b7aca',
    '#de4934',
    '#9d6cd9',
    '#bf7131',
    '#4a74a3',
    '#a764a1',
    '#c55c59',
    '#578141',
    '#d54873',
    '#a14075',
    '#a86f36',
    '#8a3811',
    '#54892b',
    '#a734a8',
    '#757b4c',
    '#c36820',
    '#7f72ba',
    '#238bcb',
    '#c84ea6',
    '#ad3f87',
    '#d844ad',
    '#7a6dcd',
    '#aa63b5',
    '#896a92',
    '#824bc5',
    '#5c5aa9',
    '#742ba4',
    '#3460d7',
    '#801d7f',
    '#a1733e',
    '#307bf0',
    '#ab65c4',
    '#7b811b',
    '#5738ac',
    '#5b57c4',
    '#b7674e',
    '#438546',
    '#8d6fa6',
    '#88793c',
    '#866abb',
    '#85741d',
    '#aa6947',
    '#686eeb',
    '#3a7ddd',
    '#d5439c',
    '#5879e8'
  ];

  constructor() {
  }

  get apiEndpoint(): string {
    let dynamicPort: string;
    if (window.location.port === '') {
      if (window.location.protocol.indexOf('https') !== -1) {
        dynamicPort = '443';
      } else {
        dynamicPort = '80';
      }
    } else {
      dynamicPort = window.location.port;
    }

    return `${window.location.protocol}//${window.location.hostname}:${dynamicPort}/core/api`;
  }

  public getHighlights(): string [] {
    return this.CATEGORICAL_COLORS.map<string>((hex: string) => TinyColor(hex).darken(10).toHexString());
  }

  public getColors(): string [] {
    return this.CATEGORICAL_COLORS;
  }
}

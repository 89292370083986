import { Injectable } from '@angular/core';
import { UBQ } from '../app-config';
import { BaseService } from './base.service';
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ISourceFile } from '../types/source';
import { ITrainingDataItem } from '../types/trainingDataItem';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class SourceService extends BaseService<ISourceFile> {

  constructor(public _http: HttpClient, public ubq: UBQ, public router: Router) {
    super(_http, ubq, router);
    this.init('source');
  }

  loadAllSync(allUsers: boolean): Observable<ISourceFile[]> {
    let params = new HttpParams();
    if (allUsers) {
      params = params.set('allUsers', 'true');
    }
    return this._http.get(`${this.baseURL}/${this.endpoint}`, { params }).pipe(
      map(response => this.serializeMany(response)),
      tap(sources => this._elementsIO.next(sources)));
  }

  getTrainingDataItems(request: GetTrainingDataItemsRequest): Observable<ITrainingDataItem[]> {
    let params = new HttpParams()
      .set('allUsers', request.allUsers.toString())
      .set('onlyLabeled', request.onlyLabeled.toString());
    return this._http.get<ITrainingDataItem[]>(`${this.baseURL}/${this.endpoint}/trainingDataItems`, { params });
  }

  softDeleteTrainingDataItem(isDeleted: boolean, trainindDataItemId: string): Observable<ITrainingDataItem> {
    return this._http.put<ITrainingDataItem>(`${this.baseURL}/${this.endpoint}/softDeleteTrainingDataItem`, {
      id: trainindDataItemId,
      isDeleted
    });
  }
}

export interface GetTrainingDataItemsRequest {
  allUsers: boolean,
  onlyLabeled: boolean
}
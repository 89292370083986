import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {IModelDocument} from '../types/document';
import {HttpClient} from '@angular/common/http';
import {UBQ} from '../app-config';
import {Router} from '@angular/router';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class ModelDocumentService {

  protected baseURL: string;
  protected endpoint: string;

  constructor(public _http: HttpClient, public ubq: UBQ, public router: Router) {
    this.baseURL = ubq.apiEndpoint;
    this.endpoint = 'modeldocs';
  }

  public loadRange(ids: number[] | string[], parentId: number = null, subUrl: string = null): Observable<IModelDocument[]> {
    const payload: any = {ids: ids, parentId: parentId};
    let url = `${this.baseURL}/${this.endpoint}`;
    if (subUrl) {
      url += subUrl;
    }

    return this._http.post<IModelDocument[]>(url, payload).pipe(
      catchError(this.errorHandler)
    );
  }

  // Not used:
  // public loadInteresting(topicId: string | number, modelId: string | number): void {
  //   this._http.get(`${this.baseURL}/documents/model/${modelId}/topic/${topicId}`).pipe(
  //     map(res => plainToInstance(IDocument, res  as IDocument[])),
  //     catchError(error => observableThrowError(error)))
  //     .subscribe((docs: IDocument[]) => this.setList(docs),
  //       (error: Error) => console.log('Load Intersection: Error', error),
  //       () => console.log('Load Documents Call Complete'));
  // }
  //
  // Not used:
  // getDocument = (lensId: number, documentId: string): Promise<IDocument> => {
  //   const url: string = `${this.ubq.apiEndpoint}/documents/${lensId}/${documentId}`;
  //   return this._http
  //     .get(url).pipe(
  //       map((response) => {
  //         return plainToInstance(IDocument, response as IDocument);
  //       }))
  //     .toPromise();
  // }

  private errorHandler(error: any, caught: Observable<any>): any {
    if (error.status === 402) {
      console.log('License Has Expired');
      this.router.navigate(['/license']);

    }
    console.error(error);
    throw error;
  }

}

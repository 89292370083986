import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class UbqToastService {

  private _subject: BehaviorSubject<ToastMessage> = new BehaviorSubject<ToastMessage>(null);

  public sendMsg(message: ToastMessage) {
    this._subject.next(message);
  }

  public get observable(): Observable<ToastMessage> {
    return this._subject.asObservable();
  }
}

export class ToastMessage {
  status: ToastStatus;
  link: string;
  message: string;
  title: string;
  showClose: boolean;
}

export enum ToastStatus {
  Error,
  Success,
  Info
}

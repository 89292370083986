import {BaseService} from './base.service';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UBQ} from '../app-config';
import {Router} from '@angular/router';
import {IDocument} from '../types/document';

@Injectable()
export class DocumentService extends BaseService<IDocument> {

  constructor(public _http: HttpClient, public ubq: UBQ, public router: Router) {
    super(_http, ubq, router);
    this.init('doc');
  }

}

import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {ToastMessage, ToastStatus, UbqToastService} from '../ubq-toast.service';
import {UBQ} from '../app-config';
import {NgForm} from '@angular/forms';
import {LensService} from '../services-http/lens.service';
import {ILens} from '../types/lens';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {ConfigService} from '../services-http/config.service';
import {BehaviorSubject, Observable, Subject, Subscription, throwError} from 'rxjs';
import {UtilsService} from '../utils/utils.service';
import {catchError, filter, map, take, takeUntil} from 'rxjs/operators';
import {KeycloakService} from 'keycloak-angular';
import {IUnitAnalysis} from '../types/unit-analysis';
import {IDocumentCollection} from '../types/document.collection';
import {DocumentCollectionService} from '../services-http/document-collection.service';
import { faCircleNotch } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-unit-upload',
  templateUrl: './unit-upload.component.html',
  styleUrls: ['./unit-upload.component.scss']
})
export class UnitUploadComponent implements OnInit, OnChanges, OnDestroy {

  @Input() allUsersInput: boolean = false;
  public givenName: string = '';
  public selectedLens: ILens;
  public isPosting: boolean = false;
  public lensesCompleted: Observable<ILens[]>;
  public dropDownText: string = 'Select Fingerprint Model';
  public files: Array<any>;

  public allUsers$ = new BehaviorSubject<boolean>(false);
  public collections$: Observable<IDocumentCollection[]>;
  public selectedCollection: IDocumentCollection;
  public selectedCollectionName: string;

  public ingestDataId = -1; // >= 0 if modal is being instantiated with the id of unit data uploaded from Ingest
  public ingestDataName = 'Ingest Data Set';
  public isIngestDataSelected = false;

  public isFormSubmitable: boolean = false;

  public progressIcon = faCircleNotch;

  public rowLimits = ['', ''];

  @Output() onUploadComplete: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('unitForm', { static: true }) creatForm: NgForm;

  private paramSubscription: Subscription;

  private destroy$ = new Subject<boolean>();

  constructor(private http: HttpClient,
              private ubq: UBQ,
              private ubqToast: UbqToastService,
              private route: ActivatedRoute,
              public configService: ConfigService,
              public collectionService: DocumentCollectionService,
              private utilsService: UtilsService,
              private kc: KeycloakService,
              public lensService: LensService) {
  }

  ngOnInit() {
    this.defaultTrainingData();

    this.configService.get('unit_row_min', 'unit_row_max').toPromise()
    .then(values => this.rowLimits = values);

    this.allUsers$.next(this.allUsersInput);
    this.allUsers$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (allUsers) => {
        this.lensService.loadAll(allUsers);
        this.collectionService.loadAll(allUsers);
      }
    });

    this.collections$ = this.collectionService.elements.pipe(
      filter(val => !!val),
      map((collections: IDocumentCollection[]) => {
        return collections.filter((collection: IDocumentCollection) => !collection.isSoftDeleted);
      })
    );

    this.lensesCompleted = this.lensService.elements.pipe(
      filter(val => !!val),
      map((lenses: ILens[]) => {
        return lenses.filter((lens: ILens) => lens.isFinished && !lens.isSoftDeleted);
      })
    );

    this.paramSubscription = this.route.queryParams.subscribe(params => {
      const from = params['from'] || 'model';
      if (from === 'ingest') {
        if (params['name']) {
          this.ingestDataName = params['name'];
        }
        if (params['source']) {
          // source param refers to a DTOUnitSourceFile
          this.ingestDataId = +(params['source']);
          setTimeout(() => this.selectIngestData());
        }
      } else if (from === 'model') {
        if (params['collection']) {
          // source param refers to a ISourceFile
          this.collections$.pipe(
            filter(collections => !!collections && !!collections.length),
            take(1),
            map(collections => collections.filter(collection => collection.id === +(params['collection'])))
          ).subscribe(collections => {
            if (collections.length) {
              setTimeout(() => this.setCollection(collections[0]));
            }
          });
        }
        if (params['lens']) {
          this.lensesCompleted.pipe(
            filter(lenses => !!lenses && !!lenses.length),
            take(1),
            map(lenses => lenses.filter(lens => lens.id === +(params['lens'])))
          ).subscribe(lenses => {
            if (lenses.length) {
              setTimeout(() => this.setLens(lenses[0]));
            }
          });
        }
      }
    });
  }

  ngOnChanges() {
    this.isFormSubmitable = this.isSubmitable();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.paramSubscription.unsubscribe();
  }

  onSubmit() {
    this.doPost();
  }

  setAllUsers(all: boolean) {
    this.allUsers$.next(all);
  }

  async doPost() {
    this.isPosting = true;

    const profile = await this.kc.loadUserProfile();

    if (this.isIngestDataSelected) {
      this.http.get(this.ubq.apiEndpoint +
          `/analysis/analyze/ingest?sourceId=${this.ingestDataId}&lensId=${this.selectedLens.id}&givenName=${this.givenName}`)
        .pipe(
          catchError(error => throwError(error))
        ).subscribe(this.onComplete.bind(this), this.handleError.bind(this), () => this.isPosting = false);
    } else {
      // use a previously uploaded piece of training data
      this.http.post(this.ubq.apiEndpoint + '/analysis/upload/trainingdata/' + this.selectedCollection.id,
        {lensId: this.selectedLens.id, givenName: this.givenName})
        .pipe(
          catchError(error => throwError(error))
        ).subscribe(this.onComplete.bind(this), this.handleError.bind(this), () => this.isPosting = false);
    }
  }

  handleError(error: Error) {
    const msg: ToastMessage = new ToastMessage();
    msg.title = 'Failure';
    msg.message = 'File Upload Failed!';
    msg.status = ToastStatus.Error;
    this.ubqToast.sendMsg(msg);
  }

  setCollection(collection: IDocumentCollection) {
    this.isIngestDataSelected = false;
    this.selectedCollection = collection;
    this.selectedCollectionName = collection.name;
    this.ngOnChanges();
  }

  selectIngestData() {
    this.selectedCollection = null;
    this.selectedCollectionName = this.ingestDataName;
    this.isIngestDataSelected = true;
    this.ngOnChanges();
  }

  setLens(lens: ILens): void {
    this.selectedLens = lens;
    this.dropDownText = lens.name;
    this.ngOnChanges();
  }

  onComplete(ua: IUnitAnalysis) {
    const msg: ToastMessage = new ToastMessage();
    msg.title = 'Success';
    msg.message = 'File Upload!';
    msg.status = ToastStatus.Success;
    msg.link = '/platform/units';
    this.ubqToast.sendMsg(msg);
    this.creatForm.reset();
    this.onUploadComplete.emit(true);
  }

  defaultTrainingData() {
    this.selectedCollection = null;
    this.selectedCollectionName = 'Select From Training Data';
  }

  isSubmitable(): boolean {
    if (this.isPosting) {
      return false;
    }
    try {
      if (this.isIngestDataSelected) {
        return !!this.selectedLens.id;
      } else {
        // use training data
        if (this.givenName.trim().length &&
          this.selectedCollection &&
          this.selectedCollection.id &&
          !!this.selectedLens.id) {
          return true;
        }
      }
    } catch (e) {
      return false;
    }
  }

  ingestUpload() {
    window.location.href = '/ingest/upload';
  }
}

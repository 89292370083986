import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {UBQ} from '../app-config';
import {HttpClient} from '@angular/common/http';
import { ISourceFile } from '../types/source';

@Injectable()
export class UtilsService {

  constructor() {

  }

  static TitleCase(phrase: string): string {
      return phrase.toLowerCase().split(' ').map(function(word) {
        return (word.charAt(0).toUpperCase() + word.slice(1));
      }).join(' ');
  }

  fixFilesNamesTrainingData(sources: ISourceFile[]) {
    const temp = _.groupBy(sources, (source) => {
      return source.originalname;
    });

    _.forEach(temp, (source: ISourceFile[]) => {
      if (source.length > 1) {
        source= _.orderBy(source, ['id'], ['asc']);
        _.forEach(source, (data, index) => {
          if (index !== 0) {
            if (data.originalname.indexOf('.') !== -1) {
              data.givenName = data.originalname.substring(0, data.originalname.lastIndexOf('.'))
                + '(' + index + ')'
                + data.originalname.substring(data.originalname.lastIndexOf('.'));
            }
          }
        })
      }
    });
  }
}

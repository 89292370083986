import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {LensService} from '../services-http/lens.service';
import {ILens, LensActions} from '../types/lens';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TopicCounts, TopicPanelService} from '../model-workspace/topic-panel/topic-panel.service';
import {ConfigService} from '../services-http/config.service';
import {ModifiersQuery} from '../model-workspace/modifier-state/modifiers.query';
import {Subject} from 'rxjs';
import {map, pluck, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-modifier-review',
  templateUrl: './modifier-review.component.html',
  styleUrls: ['./modifier-review.component.scss']
})
export class ModifierReviewComponent implements OnInit, OnDestroy {

  @Input() lens: ILens;
  @Input() errorMessage: string = null;

  counts: TopicCounts;

  isPostingLens: boolean = false;
  isFinishingLens: boolean = false;
  showChart: boolean = false;
  hasDuplicateTopicNames = false;
  hasNewCustomTopic = false;
  maxCustomTopics: number;
  public LensAction = LensActions;

  private destroy$ = new Subject<boolean>();

  constructor(public lensService: LensService,
              public query: ModifiersQuery,
              public panelService: TopicPanelService,
              private configService: ConfigService,
              public router: Router,
              public modal: NgbModal,
              public route: ActivatedRoute) {
    configService.getLicense().then(response => this.maxCustomTopics = response.customTopics);
  }

  ngOnInit() {
    // this.counts = this.panelService.calcCounts(this.query.getAll());
    this.panelService.topicCounts$.pipe(takeUntil(this.destroy$)).subscribe(counts => {
      this.counts = counts;
      this.showChart = Object.values(counts).some(v => v > 0);
    });
    this.query.topicIdData$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(data => {
      this.hasDuplicateTopicNames = data.isDuplicateNames.some(d => d);
      this.hasNewCustomTopic = data.isNewCustomTopics.some(d => d);
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  isActionDisabled(): boolean {
    return this.isPostingLens || this.counts.blank > 0 || this.hasDuplicateTopicNames || this.counts.customWithoutSeed > 0 || this.query.isLocked;
  }

  isAtMaxTopicsLimit() {
    return (this.lens.modifiers.filter(mod => !mod.customTopic).length + this.lens.numberOfTopics) > this.query.maxTopics;
  }

  isFindMoreTopicsDisabled(): boolean {
    return this.isActionDisabled() || this.hasNewCustomTopic || this.isAtMaxTopicsLimit();
  }

  isFinalizeDisabled(): boolean {
    return this.isActionDisabled() || this.hasNewCustomTopic;
  }

  isCustomTopicsUnseeded(): boolean {
    return this.counts.customWithoutSeed > 0;
  }

  public onAction(id, action: LensActions): void {
    this.isPostingLens = true;
    this.isFinishingLens = (action === LensActions.FINISH);

    this.lensService.publish(id, action, (response: ILens) => {
      this.router.navigateByUrl(`/platform/lenses/training/${response.id}`).then(() => {
        this.modal.dismissAll();
      });
    });
  }

}

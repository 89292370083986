import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-sand-box',
  template: `<h1>Load Auth Info....</h1>`
})
export class SandBoxComponent implements OnInit {

  public payload: any;

  public constructor(public _http: HttpClient) {

  }

  async ngOnInit() {
    this.payload = await this._http.get(`${window.location.protocol}//localhost:8001/api/lens/1`).toPromise();
  }
}

import { Injectable, OnDestroy } from "@angular/core";
import { Event, Router, RouterEvent } from "@angular/router";
import { NgbModalRef, NgbModalOptions, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject } from "rxjs";
import { filter, take, takeUntil } from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class ModalService implements OnDestroy {

  private modalRefs: NgbModalRef[] = []; // maintain a stack

  private destroy$ = new Subject<boolean>();

  constructor(private modalService: NgbModal, private router: Router) {
    this.router.events.pipe(
      takeUntil(this.destroy$),
      filter((event: Event | RouterEvent) => event instanceof RouterEvent),
      take(1)
    ).subscribe(() => {
      while (this.modalRefs.length) {
        this.closeModal();
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  openModal(content, options?: Partial<NgbModalOptions>): NgbModalRef {
    options = options || {};
    options = {
      // windowClass: 'modal-gt',
      centered: true,
      size: 'lg',
      ...options
    };

    const modalRef = this.modalService.open(content, options);
    this.modalRefs.push(modalRef);
    return modalRef;
  }

  closeModal() {
    if (this.modalRefs.length) {
      const modalRef = this.modalRefs.pop();
      modalRef.close();
    }
  }

}

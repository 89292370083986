import { MODAL_CONFIG } from './../modal/modal.config';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import {ToastMessage, ToastStatus, UbqToastService} from '../ubq-toast.service';
import {ToastrService, ActiveToast} from 'ngx-toastr';
import {KeycloakService} from 'keycloak-angular';
import {Router} from '@angular/router';
import {LensService} from '../services-http/lens.service';
import {ConfigService} from '../services-http/config.service';
import {filter, take} from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'app-platform',
  templateUrl: './platform.component.html',
  styleUrls: ['./platform.component.scss']
})
export class PlatformComponent implements OnInit {

  public profile: Keycloak.KeycloakProfile;
  public modalRef: NgbModalRef;

  @ViewChild('ammModal', { static: true }) ammModalElement: ElementRef;
  piensoApps: string[];

  constructor(public ubqToast: UbqToastService,
              public keycloak: KeycloakService,
              private toastyService: ToastrService,
              public router: Router,
              public modalService: NgbModal,
              public configService: ConfigService,
              public lensService: LensService) {
  }

  async ngOnInit() {
    this.piensoApps = await this.configService.getPiensoApps().toPromise();
    this.profile = await this.keycloak.loadUserProfile();

    // dev only hack
    const awindow: any = window;
    awindow.amm = this.amm.bind(this);

    this.ubqToast.observable.pipe(
      filter(payload => !!payload))
      .subscribe((payload: ToastMessage) => {
        // we only show 1 at a time - this allows us to use template scope to set the link
        let toast: ActiveToast<any>;
        switch (payload.status) {
          case ToastStatus.Error:
            toast = this.toastyService.error(payload.message, payload.title);
            break;

          case ToastStatus.Success:
            toast = this.toastyService.success(payload.message, payload.title);
            break;

          case ToastStatus.Info:
            toast = this.toastyService.info(payload.message, payload.title);
            break;
        }
        if (payload.link) {
          toast.onTap.pipe(take(1)).subscribe(() => {
            this.handleClick(payload.link);
          });
        }
      });
  }

  amm(): void {
    const options: NgbModalOptions = _.cloneDeep(MODAL_CONFIG);
    options.windowClass = 'modal-gt';
    this.modalRef = this.modalService.open(this.ammModalElement, options);
  }

  handleClick(route) {
    // console.log('route handler', route)
    if (route) {
      this.router.navigate([route]);
    }
  }

  redirect(route) {
    window.location.href = window.location.origin + route;
  }
}

import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class TopFilterService {
  private showFilterSource = new BehaviorSubject<boolean>(false);
  showFilter = this.showFilterSource.asObservable();

  changeShowFilterValue(value: boolean) {
    this.showFilterSource.next(value);
  }

}

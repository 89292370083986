import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {IDocument, IModelDocument} from '../../../types/document';
import {DocumentViewStateService} from '../../document-view-state.service';
import {getLookupId, IModifier} from '../../../types/modifier';
import {DocumentTopicDistributionService} from '../../document-topic-distribution/document-topic-distribution.service';
import {compareArrays} from '../../../utils/array-utils';

@Component({
  selector: 'app-conversational-document-container',
  templateUrl: './conversational-document-container.component.html',
  styleUrls: ['./conversational-document-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConversationalDocumentContainerComponent implements OnInit, OnChanges {

  @Input() modifiers: IModifier[];
  @Input() modifier: IModifier;
  @Input() documents: IModelDocument[];

  public threshold: number;
  public modifierTerms: string[];
  public modifierPhrases: string[];

  public isLoadComplete = false;

  focusTopicIds: number[];

  constructor(public viewService: DocumentViewStateService,
              public distributionService: DocumentTopicDistributionService) {
  }

  ngOnInit(): void {
    this.threshold = 1 / this.modifiers.length;
    this.isLoadComplete = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.modifier) {
      const ftid = getLookupId(this.modifier);
      this.focusTopicIds = this.focusTopicIds && this.focusTopicIds[0] === ftid ? this.focusTopicIds : [ftid];
      if (!compareArrays(this.modifierTerms, this.modifier.modifierModel.topicTerms)) {
        this.modifierTerms = this.modifier.modifierModel.topicTerms;
      }
      if (!compareArrays(this.modifierPhrases, this.modifier.modifierModel.topicPhrases)) {
        this.modifierPhrases = this.modifier.modifierModel.topicPhrases;
      }
    }
    if (changes.modifiers) {
      this.distributionService.modifiers = this.modifiers;
    }
  }

  trackFn(index, doc: IDocument) {
    return doc.id;
  }
}

import {UBQ} from '../app-config';
import * as msgpack from 'msgpack-lite';
import {Router} from '@angular/router';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {saveAs} from 'file-saver';
import {catchError, map} from 'rxjs/operators';
import {throwError as observableThrowError} from 'rxjs/internal/observable/throwError';
import {BehaviorSubject, Observable} from 'rxjs';
import {IBase} from '../types/base';
import { Injectable } from '@angular/core';

@Injectable()
export abstract class BaseService<T extends IBase> {

  static msgpack = msgpack;
  public isPayloadLoading = false;
  public isEmptyList: boolean = null;

  protected _elementsIO: BehaviorSubject<T[]>;
  protected _elementIO: BehaviorSubject<T>;

  protected baseURL: string;
  protected endpoint: string;

  constructor(public _http: HttpClient, public ubq: UBQ, public router: Router) {
    // Todo: This should not be an injectable service - it should just be an instantiated helper class
    // This also doesn't really make sense with the new angular http client post v2

    this.baseURL = ubq.apiEndpoint;
    this._elementsIO = <BehaviorSubject<T[]>>new BehaviorSubject(([]));
    this._elementIO = new BehaviorSubject<T>(null);
  }

  public init(endpoint: string): void {
    this.endpoint = endpoint;
  }

  public clear() {
    this._elementsIO.next(null);
    this._elementIO.next(null);
  }

  public getEndpoint(): string {
    return `${this.baseURL}/${this.endpoint}`;
  }

  get elements(): Observable<T[]> {
    return this._elementsIO.asObservable();
  }

  get element(): Observable<T> {
    return this._elementIO.asObservable();
  }

  public loadAll(includeAllUsers = false): Observable<T[]> {
    let params = new HttpParams();
    if (includeAllUsers) {
      params = params.set('allUsers', 'true');
    }
    this._http.get(`${this.baseURL}/${this.endpoint}`, {params}).pipe(
      map(response => this.serializeMany(response)))
      .subscribe(
        data => {
          this._elementsIO.next(data);
          this.isEmptyList = (data.length === 0);
        },
        error => console.log('Could not load DTOs.', this.errorMiddleware(error))
      );

    return this._elementsIO.asObservable();
  }

  public loadByAttribute(attributeId: number, attributeName: string): void {
    const url = `${this.baseURL}/${this.endpoint}/${attributeName}/${attributeId}`;
    this._http.get(url).pipe(
      map(response => this.serializeMany(response)))
      .subscribe(
        data => {
          this._elementsIO.next(data);
          this.isEmptyList = (data.length === 0);
        },
        error => console.log('Could not load by attributes', this.errorMiddleware(error))
      );
  }

  public loadRange(ids: number[] | string[], parentId: number = null, subUrl: string = null): void {
    this.isPayloadLoading = true;
    const payload: any = {ids: ids, parentId: parentId};
    let url = `${this.baseURL}/${this.endpoint}`;
    if (subUrl) {
      url += subUrl;
    }

    this._http.post(url, payload).pipe(
      map(response => this.serializeMany(response)))
      .subscribe(
        data => {
          this._elementsIO.next(data);
          this.isEmptyList = (data.length === 0);
          this.isPayloadLoading = false;
        },
        error => console.log('Could not load DTO.', this.errorMiddleware(error))
      );
  }

  update(dto: any, callback: Function = null): Promise<T | T[]> {
    this.isPayloadLoading = true;
    return new Promise((resolve, reject) => {
      this._http.put(`${this.baseURL}/${this.endpoint}`, dto).pipe(
        map(response => {
          if (Array.isArray(response)) {
            return this.serializeMany(response);
          } else {
            return this.serializeOne(response);
          }
        }),
        catchError(error => observableThrowError(error)))
        .subscribe(result => {
          console.log('base.service::update::result', result);
          if (Array.isArray(result)) {
            const list: T[] = this._elementsIO.getValue();
            const index: number = list.findIndex((item) => item['id'] === result['id']);
            list[index] = result as any;
            this._elementsIO.next(list);
          } else {
            const items: any[] = this._elementsIO.getValue();
            // @ts-ignore
            if (items) {
              const match: any = items.find(val => val.id === (<T>result).id);
              if (!!match) {
                // @ts-ignore
                const nitems: any = this._elementsIO.getValue().map(val => (val.id === result.id) ? result : val);
                this._elementsIO.next(nitems);
              }
            }
            this._elementIO.next(result as T);
          }
          if (callback) {
            callback();
          }
          this.isPayloadLoading = false;
          resolve(result);
        }, reject);
    });
  }

  public load(id: number | string, property: string = 'id', showLoader: boolean = true): Observable<T> {
    this.isPayloadLoading = showLoader;
    this._http.get(`${this.baseURL}/${this.endpoint}/${id}`).pipe(
      map(response => this.serializeOne(response)))
      .subscribe((data: any) => {
        this._elementIO.next(data);
        this.isPayloadLoading = false;
      }, error => console.log(`Could not load DTO from: ${this.endpoint}`, this.errorMiddleware(error)));

    return this.element;
  }

  private errorMiddleware(error: Response): any {
    if (error.status === 402) {
      console.log('License Has Expired');
      this.router.navigate(['/license']);

    }

    return error;
  }

  private classClone(item: any): any {
    return Object.assign(Object.create(Object.getPrototypeOf(item)), item);
  }

  protected setList(data: Array<T>): void {
    this.isPayloadLoading = false;
    this.isEmptyList = (data.length > 0);
    this._elementsIO.next(this.classClone(data));
    this._elementIO.next(this.classClone(data[0] || null));
  }

  public downloadFileToDisk(url: string, customFileName: string = null): Observable<string> {
    return this._http.get(url, {observe: 'response', responseType: 'blob'}).pipe(map((response: HttpResponse<any>) => {
      let filename: string = response.headers.get('content-disposition');
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(filename);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }

      filename = (filename) ? filename : 'pienso_file';
      saveAs(response.body, customFileName ? customFileName : filename);
      return filename;
    }));
  }

  serializeOne(response: any): T {
    return <T>response;
  }

  serializeMany(response: any): Array<T> {
    return <T[]>response;
  }
}

import {IBase} from "./base";

export enum FeedCardType {
    WELCOME,
    TRAINING_DATA,
    ANNOTATION,
    LENS,
    DEEP_LEARNING,
    UNIT_ANALYSIS,
    CLASSIFIER
}

export interface FeedCardConfig<T extends {createdAt: Date}> {
    type: FeedCardType;
    dto?: T;
}

import {ActiveState, applyTransaction, EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {IModifier, ModifierState} from '../../types/modifier';
import {ILens} from '../../types/lens';
import {Injectable} from '@angular/core';

export enum TopicLayout {
  DOCUMENTS,
  OVERVIEW_CARDS
}

export interface ModifiersState extends EntityState<IModifier, number>, ActiveState<number> {
  lens: ILens;
  history: ILens[];
  leaf: ILens | null;
  ui: {
    topicLayout: TopicLayout;
    overviewShowTerms: boolean;
    overviewShowPhrases: boolean;
    topicFilter: ModifierState | null;
    isCreatingCustomTopic: boolean;
    hoverTerm: {topicId: number, term: string} | null;
  };
}

@Injectable()
@StoreConfig({'name': 'modifiers', idKey: 'topicId'})
export class ModifiersStore extends EntityStore<ModifiersState> {

  constructor() {
    super({
      lens: null,
      history: [],
      leaf: null,
      active: 0,
      ui: {
        isCreatingCustomTopic: false,
        topicLayout: TopicLayout.DOCUMENTS,
        overviewShowTerms: true,
        overviewShowPhrases: false,
        topicFilter: null,
        hoverTerm: null
      }
    });
  }

  update(
    idsOrFnOrState: any,
    newStateOrFn?: any
  ) {
    if (newStateOrFn == null && idsOrFnOrState.lens) {
      // if we're updating the lens, then update the modifiers from its lens
      const activeId = idsOrFnOrState.active != null ? idsOrFnOrState.active : this.getValue().active;
      // console.log('active: ' + activeId);
      applyTransaction(() => {
        const value = this.getValue();
        const isNewLens = idsOrFnOrState.lens && (!value.lens || idsOrFnOrState.lens.id !== value.lens.id);
        super.update(idsOrFnOrState);
        if (idsOrFnOrState.lens) {
          if (isNewLens) {
            // If the lens changed then completely overwrite everything
            this.set(idsOrFnOrState.lens.modifiers, {activeId});
          } else {
            // Preserve custom topic IModifierModel data:
            idsOrFnOrState.lens.modifiers = idsOrFnOrState.lens.modifiers.map(m => {
              if (!m.customTopic) {
                return m;
              }
              const existing = value.entities[m.topicId];
              return existing ? {...m, modifierModel: existing.modifierModel} : m;
            });
            this.set(idsOrFnOrState.lens.modifiers);
            // this.upsertMany(idsOrFnOrState.lens.modifiers);
          }
        }
      });
      return;
    }
    // If we're updating the modifier entities, then just call super
    return super.update(idsOrFnOrState, newStateOrFn);
  }

}

import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {MODAL_CONFIG} from '../../modal/modal.config';
import {STOPWORD_VIEW_OPTIONS} from './stop-word-list.constants';
import {Observable} from 'rxjs/internal/Observable';
import {take, takeUntil} from 'rxjs/operators';
import {TopFilterComponent} from '../../shared/top-filter/top-filter.component';
import {ListFilterService} from '../../shared/top-filter/list-filter.service';
import {GlobalStopWordService} from '../../services-http/global-stop-word.service';
import {IStopWordsGlobal} from '../../types/stop-words-global';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-stop-word-list',
  templateUrl: './stop-word-list.component.html',
  styleUrls: ['./stop-word-list.component.scss']
})
export class StopWordListComponent implements OnInit, OnDestroy {
  public isStopWordDownloading: boolean;
  public selectOptions: Array<any>;
  public modalRef: NgbModalRef;
  public list$: Observable<IStopWordsGlobal[]>;

  public selected: IStopWordsGlobal;

  @ViewChild(TopFilterComponent, { static: true })
  private topFilter: TopFilterComponent;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource = new MatTableDataSource<IStopWordsGlobal>();


  private destroy$ = new Subject<void>();    

  constructor(public listFilterService: ListFilterService,
              public gws: GlobalStopWordService,
              public modalService: NgbModal) {
  }

  ngOnInit() {
    this.selectOptions = STOPWORD_VIEW_OPTIONS;
    this.list$ = this.listFilterService.createTablePipeline<IStopWordsGlobal>(
      this.gws,
      this.topFilter,
      this.selectOptions[0],
      ['name', 'createdBy']);

    this.list$.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.dataSource.data = [...data]
      this.dataSource.paginator = this.paginator
    });
  }

  ngAfterViewInit(){
    setTimeout(()=>{
      this.list$.pipe(takeUntil(this.destroy$)).subscribe(data => {
        this.dataSource.data = [...data]
        this.dataSource.paginator = this.paginator
      });
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    console.log('destroy stopword-list');
  }

  openModal(content, modalClass: string = '') {
    const options: NgbModalOptions = Object.assign({}, MODAL_CONFIG);
    options.windowClass = !!modalClass ? modalClass : options.windowClass;
    this.modalRef = this.modalService.open(content, options);
  }

  set(value: IStopWordsGlobal) {
    this.selected = value;
  }

  onHide(uDTO: IStopWordsGlobal): void {
    uDTO.isSoftDeleted = !uDTO.isSoftDeleted;
    this.gws.update(uDTO);
  }

  closeModal() {
    this.gws.loadAll(true);
    this.gws.elements.pipe(
      take(1)
    ).subscribe(() => {
      this.modalService.dismissAll();
      this.modalRef = null;
    });
  }

  downloadCSV(id: number): void {
    const url = `${this.gws.getEndpoint()}/download/${id}`;
    this.isStopWordDownloading = true;
    this.gws.downloadFileToDisk(url).subscribe({
      complete: () => {
        this.isStopWordDownloading = false;
      }
    });
  }
}

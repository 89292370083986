import {QueryEntity} from '@datorama/akita';
import {ModifiersState, ModifiersStore} from './modifiers.store';
import {Injectable, OnDestroy} from '@angular/core';
import {getModifierState, IModifier, isMerged, isNewCustomTopic as isNewCustom, ModifierState, isDuplicateTopic} from '../../types/modifier';
import {ILens} from '../../types/lens';
import {suggestionState, ISuggestion} from '../../types/model';
import {ShareReplayConfig, distinctUntilChanged, filter, map, observeOn, shareReplay, takeUntil} from 'rxjs/operators';
import {StopWordsService} from '../../services-http/stop-words.service';
import {compareArrays, objectKeysEqualityTest} from '../../utils/array-utils';
import {asapScheduler, BehaviorSubject, combineLatest, Observable, Subject} from 'rxjs';
import {ConfigService} from '../../services-http/config.service';

export class IStageRestore {
  constructor(
    public topic: number,
    public lens: ILens,
    public leaf: ILens,
    public history: ILens[],
    public isRelic: boolean
  ) {
  }
}

export interface TopicIdData {
  modifiers: IModifier[];
  states: ModifierState[];
  labels: string[];
  suggestions: ISuggestion[];
  suggestionStates: ModifierState[];
  isDuplicateNames: boolean[];
  mergeClusterIds: number[];
  merges: number[][];
  mergeThreads: number[][];
  mergeDisplayThreads: number[][];
  mergeRoots: number[];
  isActiveMergeThreads: boolean[];
  duplicates: number[];
  isDuplicates: boolean[];
  hasDuplicates: boolean[];
  duplicateRoots: number[];
  isActiveDuplicateThreads: boolean[];
  labelPlaceholders: string[];
  displayLabels: string[];
  labelClasses: string[];
  isNewCustomTopics: boolean[];
  isProcessings: boolean[];
}

export interface ModifierRenderData {
  modifier: IModifier;
  state: ModifierState;
  suggestionState: ModifierState;
  isDuplicateName: boolean;
  mergeClusterId: number | null;
  merge: number[] | null;
  mergeThread: number[] | null;
  mergeDisplayThread: number[] | null;
  mergeRoot: number | null;
  isActiveMergeThread: boolean;
  isDuplicate: boolean;
  duplicateRoot: number | null;
  isActiveDuplicateThread: boolean;
  labelPlaceholder: string;
  displayLabel: string;
  labelClass: string;
  isNewCustomTopic: boolean;
  isProcessing: boolean;
}

// tslint:disable-next-line: max-classes-per-file
@Injectable()
export class ModifiersQuery extends QueryEntity<ModifiersState> implements OnDestroy {

  public readonly lens$ = this.select(state => state.lens);
  public readonly history$ = this.select(state => state.history);
  public readonly leaf$ = this.select(state => state.leaf);
  public readonly isHistorical$ = this.select(state => !!state.leaf);
  public readonly isFinished$ = this.select(state => state.lens && state.lens.isFinished);

  public readonly isLocked$ = combineLatest([this.isHistorical$, this.isFinished$, this.select(state => state.lens.source)], asapScheduler).pipe(
    map(([isRelic, isFinished, source]) => isRelic || isFinished || source.validationState === -1),
    distinctUntilChanged(),
    this.shareReplay()
  );

  public readonly topicLayout$ = this.select(state => state.ui.topicLayout);
  public readonly overviewShowTerms$ = this.select(state => state.ui.overviewShowTerms);
  public readonly overviewShowPhrases$ = this.select(state => state.ui.overviewShowPhrases);
  public readonly topicFilter$ = this.select(state => state.ui.topicFilter);
  public readonly hoverTerm$ = this.select(state => state.ui.hoverTerm);

  // active topic values
  public readonly activePhrases$ = this.selectActive(m => m.filterPhrases);
  public readonly stageRestore$ = this.selectActive(m => {
    const state = this.getValue();
    return new IStageRestore(m.topicId, state.lens, state.leaf, state.history, !!state.leaf);
  });

  /*
   * Some UI states depend on how all the topics relate to each other, specifically duplicate topic names,
   * which topics are duplicates of each other, and which topics are merged together. To avoid calculating
   * these values independently for each topic (O(n^2)), we calculate them once and reused as necessary (O(n)).
   * All these values are indexed by topicId. Note that by deleting custom topics it's possible for topicId values
   * to be non-consecutive, resulting in empty values in the arrays.
   */

  private _topicIdData$ = new BehaviorSubject<TopicIdData>({
    modifiers: [],
    states: [],
    labels: [],
    suggestions: [],
    suggestionStates: [],
    isDuplicateNames: [],
    mergeClusterIds: [],
    merges: [],
    mergeThreads: [],
    mergeDisplayThreads: [],
    mergeRoots: [],
    isActiveMergeThreads: [],
    duplicates: [],
    isDuplicates: [],
    hasDuplicates: [],
    duplicateRoots: [],
    isActiveDuplicateThreads: [],
    labelPlaceholders: [],
    displayLabels: [],
    labelClasses: [],
    isNewCustomTopics: [],
    isProcessings: []
  });

  public readonly topicIdData$ = this._topicIdData$.asObservable().pipe(observeOn(asapScheduler), this.shareReplay());
  public readonly topicIdModifierDatas$ = this._topicIdData$.pipe(map(this.calcTopicIdModiferData));
  public readonly modifierDatas$ = this.topicIdModifierDatas$.pipe(map(data => data.filter(d => !!d)));

  public readonly filterdModifierData$ = combineLatest([this.modifierDatas$, this.topicFilter$]).pipe(
    map(([modifierData, filterState]) => this.filterTopics(modifierData, filterState)));

  private _maxTopics: number = 120;

  private destroy$ = new Subject<boolean>();

  constructor(protected store: ModifiersStore,
              private stopWordsService: StopWordsService,
              private configService: ConfigService) {
    super(store);

    this.selectAll().pipe(
      takeUntil(this.destroy$),
      filter(mods => !!mods.length)
    ).subscribe(modifiers => {
      this.updateTopicIdData(modifiers);
    });

    configService.get('model_topics_max').toPromise().then(v => this._maxTopics = +v);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  public get maxTopics() { return this._maxTopics; }

  public selectModifierData(topicId: number) {
    return this.topicIdModifierDatas$.pipe(
      map(data => data[topicId]),
      distinctUntilChanged((prev, next) => !next ? false : this.compareObjects(prev, next, ['lens', 'modifierModel'])),
      this.shareReplay()
    );
  }

  private updateTopicIdData(modifiers: IModifier[]) {
    const activeId = this.getActiveId();
    const isLocked = this.isLocked;
    const prev = this._topicIdData$.getValue();
    const next: Partial<TopicIdData> = {};
    const changed = new Map<string, boolean>();
    const updateChanged = <K extends keyof TopicIdData>(k: K) => {
      const equalityTest = k === 'suggestions' ? objectKeysEqualityTest : null;
      changed.set(k, !compareArrays<TopicIdData[K]>(prev[k] as any[], next[k] as any[], equalityTest));
    };
    const updateAllChanged = () => {
      Object.keys(next).forEach(k => {
        if (!changed.has(k)) {
          updateChanged(k as keyof TopicIdData);
        }
      });
    };
    const needsUpdate = (...dependencies: string[]) => dependencies.some(d => changed.get(d));
    const calcNext = <K extends keyof TopicIdData>(key: K, dependencies: K[], calcFn: ((...args) => TopicIdData[K])) => {
      let nextValue;
      if (needsUpdate(...dependencies)) {
        nextValue = <TopicIdData[K]>calcFn(...dependencies.map(d => next[d]));
        updateChanged(key);
      } else {
        nextValue = prev[key];
        changed.set(key, false);
      }
      next[key] = nextValue;
    };

    // convert modifiers to a topicId indexed array:
    const arr = new Array<IModifier>(modifiers.length);
    modifiers.forEach(m => arr[m.topicId] = m);
    modifiers = arr;

    next.states = modifiers.map(m => m ? getModifierState(m) : undefined);
    next.labels = modifiers.map(m => m ? m.label : undefined);
    next.suggestions = modifiers.map(m => m ? m.modifierModel.suggestMap : undefined);
    next.mergeClusterIds = modifiers.map(m => m ? isMerged(m) ? m.mergedClusterId : null : undefined);
    next.isDuplicates = modifiers.map(m => m ? isDuplicateTopic(m) : undefined);
    next.isNewCustomTopics = modifiers.map(m => m ? this.isNewCustomTopic(m) : undefined);
    updateAllChanged();

    calcNext('suggestionStates', ['suggestions'], suggestions => suggestions.map(s => s ? suggestionState(s) : undefined));
    calcNext('duplicates', ['isDuplicates'],
      (dupes) => dupes.map((d, i) => d === undefined ? undefined : (d ? modifiers[i].duplicateOfTopicId : null)));
    calcNext('isDuplicateNames', ['states', 'labels', 'suggestionStates'], this.calcIsDuplicateNames.bind(this));

    calcNext('merges', ['mergeClusterIds'], clusterIds => clusterIds.map((clusterId, topicId) =>
      clusterId == null ? clusterId : clusterIds.map((c, i) => c === topicId ? i : null).filter(v => v != null)
    ));
    calcNext('mergeThreads', ['merges'],
        clusters => clusters.map((cluster) => cluster && cluster.length ? this.calcMergeThread(cluster, clusters) : undefined));
    calcNext('mergeDisplayThreads', ['mergeThreads'], threads => threads.map(thread =>
      thread == null ? thread : thread.map(t => t + 1)));
    calcNext('mergeRoots', ['mergeClusterIds'], clusterIds => clusterIds.map((clusterId, topicId) => {
      if (this.getEntity(topicId) == null) {
        return undefined;
      }
      if (clusterId == null) {
        return null;
      }
      let root = clusterId;
      while (clusterIds[root] != null) {
        root = clusterIds[root];
      }
      return root;
    }));
    calcNext('isActiveMergeThreads', ['mergeRoots'], roots => roots.map((root, topicId) => roots[topicId] === activeId));

    calcNext('hasDuplicates', ['duplicates'], dupes => dupes.map((d, topicId) => dupes.some(dd => dd === topicId)));
    calcNext('duplicateRoots', ['duplicates', 'isDuplicates', 'hasDuplicates'], (dupes, isDupes, hasDupes) =>
      hasDupes.map((has, topicId) => has === undefined ? undefined :
        (has ? topicId : isDupes[topicId] ? dupes[topicId] : null)));
    calcNext('isActiveDuplicateThreads', ['duplicateRoots'],
      (dupeRoots) => dupeRoots.map(root => root != null && root === dupeRoots[activeId]));

    calcNext('labelPlaceholders', ['states', 'suggestions', 'suggestionStates', 'labels', 'mergeClusterIds'],
      this.calcLabelPlaceholders.bind(this));
    calcNext('displayLabels', ['states', 'labels', 'labelPlaceholders'], (states, labels, placeholders) =>
      states.map((state, i) => labels[i] && ((isLocked && state === ModifierState.LABELED) || !isLocked) ? labels[i] : placeholders[i]));
    calcNext('labelClasses', ['states', 'suggestionStates', 'labels'], (states, sugStates, labels) =>
      states.map((state, topicId) => this.labelClasses(state, sugStates[topicId], labels[topicId], isLocked)));

    calcNext('isProcessings', ['isNewCustomTopics'], (isNews) =>
      isNews.map((isNew, topicId) => isNew == null ? undefined : this.isProcessingNewCustomTopic(topicId)));

    next.modifiers = modifiers;
    this._topicIdData$.next(next as TopicIdData);
  }

  private calcTopicIdModiferData(data: TopicIdData): ModifierRenderData[] {
    return data.modifiers.map((m, i) => ({
      modifier: data.modifiers[i],
      state: data.states[i],
      suggestionState: data.suggestionStates[i],
      isDuplicateName: data.isDuplicateNames[i],
      mergeClusterId: data.mergeClusterIds[i],
      merge: data.merges[i],
      mergeThread: data.mergeThreads[i],
      mergeDisplayThread: data.mergeDisplayThreads[i],
      mergeRoot: data.mergeRoots[i],
      isActiveMergeThread: data.isActiveMergeThreads[i],
      isDuplicate: data.isDuplicates[i],
      duplicateRoot: data.duplicateRoots[i],
      isActiveDuplicateThread: data.isActiveDuplicateThreads[i],
      labelPlaceholder: data.labelPlaceholders[i],
      displayLabel: data.displayLabels[i],
      labelClass: data.labelClasses[i],
      isNewCustomTopic: data.isNewCustomTopics[i],
      isProcessing: data.isProcessings[i]
    }));
  }

  private compareObjects(o1, o2, skipKeys: string[]) {
    const keys = Object.keys(o1);
    if (keys.length !== Object.keys(o2).length) {
      return false;
    }
    return keys.filter(k => !skipKeys.includes(k)).every(k => {
      const v1 = o1[k], v2 = o2[k];
      switch (typeof v1) {
        case 'undefined':
          return v1 === v2;
        case 'number':
        // case 'bigint':
        case 'string':
        case 'symbol':
          return v1 === v2;
        case 'object':
          return (v1 === null && v2 === null) || this.compareObjects(v1, v2, skipKeys);
      }
    });
  }

  private calcIsDuplicateNames(states, labels, suggestionStates) {
    return states.map((state, i) => {
      if (!state) {
        return undefined;
      }
      const suggestions = this.getEntity(i).modifierModel.suggestMap;
      let name = state === ModifierState.LABELED ?
        labels[i] : state === ModifierState.UNLABELED && suggestions && suggestionStates[i] === ModifierState.LABELED ?
          suggestions.suggestedLabel : null;
      if (name) {
        name = name.toLowerCase();
        for (let j = 0; j < states.length; j++) {
          if (i === j) {
            continue;
          }
          const jState = states[j];
          if (jState === ModifierState.LABELED && labels[j]) {
            if (labels[j].toLowerCase() === name) {
              return true;
            }
          } else if (jState === ModifierState.UNLABELED) {
            if (suggestionStates[j] === ModifierState.LABELED
              && this.getEntity(j).modifierModel.suggestMap.suggestedLabel.toLowerCase() === name) {
              return true;
            }
          }
        }
      }
      return false;
    });
  }

  private calcLabelPlaceholders(states: ModifierState[],
                                suggestions: ISuggestion[],
                                sugStates: ModifierState[],
                                labels: string[],
                                mergeClusterIds: number[]) {
    return states.map((state, topicId) => {
      if (!state) {
        return undefined;
      }
      switch (state) {
        case ModifierState.CUSTOM:
          return labels[topicId] ? labels[topicId] : this.getEntity(topicId).customTopic.seed; // suggestions[topicId].suggestedLabel;
        case ModifierState.MERGED:
          return 'Merged with ' + (mergeClusterIds[topicId] + 1);
        case ModifierState.UNCLEAR:
          return 'Unclear';
        case ModifierState.UNLABELED:
          const suggestion = suggestions[topicId];
          if (suggestion) {
            switch (sugStates[topicId]) {
              case ModifierState.LABELED:
                return suggestion.suggestedLabel;
              case ModifierState.MERGED:
                return 'Merged with ' + (suggestion.suggestedMerge + 1);
            }
          }
      }
      return '?'; // 'Topic ' + (this.dtoModifier.topicId + 1);
    });
  }

  /** Utility function to pluck a value from an array of values and optionally add a distinct check and share operator */
  public pluckIndex<T>(observable: Observable<T[]>, index: number, addDistinctCheck = true, addShareReplay = false): Observable<T> {
    const ops = [
      map(values => values[index])
    ];
    if (addDistinctCheck) {
      ops.push(distinctUntilChanged());
    }
    if (addShareReplay) {
      ops.push(this.shareReplay());
    }
    // @ts-ignore
    return observable.pipe(...ops);
  }

  public shareReplay<T>(options?: ShareReplayConfig) {
    return shareReplay<T>(options || {bufferSize: 1, refCount: true});
  }

  private calcMergeThread(cluster: number[], clusters: number[][]) {
    const thread: number[] = [].concat(...cluster.map(c => [c, ...this.calcMergeThread(clusters[c], clusters)]));
    thread.sort();
    return thread;
  }

  private filterTopics(modifierData: ModifierRenderData[], filterState: ModifierState) {
    switch (filterState) {
      case null:
      case undefined:
        break;
      case 'custom':
        modifierData = modifierData.filter(m => !!m.modifier.customTopic);
        break;
      default:
        modifierData = modifierData.filter(m => !m.modifier.customTopic).filter(m => {
          return (m.state !== ModifierState.UNLABELED && m.state === filterState)
            || (m.state === ModifierState.UNLABELED && suggestionState(m.modifier.modifierModel.suggestMap) === filterState);
        });
    }
    return modifierData;
  }

  public get lens() { return this.store.getValue().lens; }
  public get history() { return this.store.getValue().history; }
  public get leaf() { return this.store.getValue().leaf; }
  public get isHistorical() { return !!this.store.getValue().leaf; }
  public get isCreatingCustomTopic() { return this.store.getValue().ui.isCreatingCustomTopic; }

  public get isFinished() {
    const lens = this.lens;
    return lens && lens.isFinished;
  }
  public get isLocked() { return this.isHistorical || this.isFinished || this.lens.source.validationState === -1; }

  public get topicLayout() { return this.store.getValue().ui.topicLayout; }
  public get topicFilter() { return this.store.getValue().ui.topicFilter; }

  public isNewCustomTopic(modifierOrTopicId: IModifier | number) {
    const modifier = typeof modifierOrTopicId === 'number' ? this.getEntity(modifierOrTopicId) : modifierOrTopicId;
    return isNewCustom(modifier, this.lens);
  }
  public isSeededNewCustomTopic(modifier: IModifier) { return this.isNewCustomTopic(modifier) && !!modifier.customTopic.seed; }
  public isProcessingNewCustomTopic(modifierOrTopicId: IModifier | number) {
    const modifier = typeof modifierOrTopicId === 'number' ? this.getEntity(modifierOrTopicId) : modifierOrTopicId;
    return this.isNewCustomTopic(modifier) && !!modifier.customTopicStatus && modifier.customTopicStatus.status === 'PROCESSING';
  }

  public canDuplicateTopics() {
    return this.lens.modifiers.filter(mod => !mod.customTopic).length < this._maxTopics;
  }
  public isDuplicateTopic(modifier: IModifier) {
    return isDuplicateTopic(modifier);
  }
  public hasDuplicateTopic(modifier: IModifier) {
    return this.getAll().some(m => m.duplicateOfTopicId === modifier.topicId);
  }
  public getDuplicateRoot(modifier: IModifier): IModifier | null {
    return this.isDuplicateTopic(modifier) ? this.getEntity(modifier.duplicateOfTopicId)
      : this.hasDuplicateTopic(modifier) ? modifier : null;
  }
  public topicsDuplicated(m1: IModifier, m2: IModifier) {
    return m1.duplicateOfTopicId === m2.topicId || m2.duplicateOfTopicId === m1.topicId;
    // const root = this.getDuplicateRoot(m1);
    // return root ? (root.topicId === m2.topicId || root.topicId === m2.duplicateOfTopicId) : false;
  }

  public isMergedTopic(modifier: IModifier) {
    return isMerged(modifier);
  }
  public getMergedTopics(modifier: IModifier, recurse = true) {
    const merges = this.getAll().filter(m => m.mergedClusterId === modifier.topicId);
    return recurse ? [].concat(...merges.map(m => [m, ...this.getMergedTopics(m)])) : merges;
  }
  public hasMergedTopic(modifier: IModifier) {
    return this.getMergedTopics(modifier, false).length > 0;
  }
  public getMergeRoot(modifier: IModifier): IModifier {
    return this.isMergedTopic(modifier) ? this.getMergeRoot(this.getEntity(modifier.mergedClusterId)) : modifier;
  }
  /** Returns true if the two topics share a merge thread */
  public topicsMerged(m1: IModifier, m2: IModifier) {
    const root = this.getMergeRoot(m1);
    return m2.topicId === root.topicId || this.getMergedTopics(root).some(m => m.topicId === m2.topicId);
  }

  public isActive(modifier: IModifier) { return modifier.topicId === this.getActiveId(); }

  public shouldShowSuggestedStopWords() {
    return this.stopWordsService.showSuggested(this.lens);
  }

  private labelClasses(state: ModifierState, sugState: ModifierState, label: string, isLocked: boolean): string {
    if (isLocked) {
      return 'locked';
    }
    switch (state) {
      case ModifierState.CUSTOM:
        // we already  know its a custom topic
        // if a label has never been applied by a human a seed string is presented
        // this does not mean a user has approved it
        return !!label ? 'custom' : 'suggested custom';
      case ModifierState.LABELED:
        return 'labeled';
      case ModifierState.MERGED:
        return 'merged';
      case ModifierState.UNCLEAR:
        return 'unclear';
      case ModifierState.UNLABELED:
      default:
        // show suggestions
        switch (sugState) {
          case ModifierState.UNCLEAR:
            return 'suggested unclear';
          case ModifierState.MERGED:
            return 'suggested merged';
          case ModifierState.LABELED:
            return 'suggested labeled';
          case ModifierState.UNLABELED:
          default:
            return 'suggested';
        }
    }
    return '';
  }
}

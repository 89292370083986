import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { invalidateQueries } from '../../utils/query/invalidateQuery';
import { RxQueryNames } from '../../utils/query/RxQueryNames';
import { UBQ } from '../../app-config';
import { SourceService } from '../../services-http/source.service';
import { ModalService } from '../../shared/modal.service';
import { ITrainingDataItem } from '../../types/trainingDataItem';
import { mutation } from '../../utils/query/mutation';


@Component({
  selector: 'app-training-data-list-item',
  templateUrl: './training-data-list-item.component.html',
  styleUrls: ['./training-data-list-item.component.scss']
})
export class TrainingDataListItemComponent {
  @Input()
  itemType: 'downloadAction' | 'deleteAction'

  @Input()
  row: ITrainingDataItem

  constructor(
    public ubq: UBQ,
    public modalService: ModalService,
    public sourceService: SourceService,
  ) { }

  todiskMutation$ = mutation(
    RxQueryNames.downloadToDiskMutation,
    (row: ITrainingDataItem) => {
      const url = this.ubq.apiEndpoint + "/source/downloadTrainingData/" + encodeURIComponent(row.id);
      return this.sourceService.downloadFileToDisk(url);
    });

  softDeleteMutation$ = mutation(
    RxQueryNames.softDeleteTrainingDataMutation,
    (row: ITrainingDataItem) =>
      this.sourceService.softDeleteTrainingDataItem(!row.isSoftDeleted, row.id).pipe(
        invalidateQueries((entry) => entry.key.startsWith(RxQueryNames.trainingDatas)),
        catchError((err) => throwError(err.statusText))
      ));
}

export enum ItemType { }

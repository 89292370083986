import {Injectable} from '@angular/core';
import {ModifierState, IModifier, getModifierState} from '../../types/modifier';
import {Observable} from 'rxjs';
import {ISuggestion, suggestionState} from '../../types/model';
import {saveAs} from 'file-saver';
import {ModifiersQuery} from '../modifier-state/modifiers.query';
import {map} from 'rxjs/operators';

export interface TopicCounts {
  currentTopics: number;
  initialTopics: number;
  labeled: number;
  userLabeled: number;
  suggestionLabeled: number;
  merged: number;
  userMerged: number;
  suggestionMerged: number;
  unclear: number;
  userUnclear: number;
  suggestionUnclear: number;
  blank: number;
  suggestions: number;
  custom: number;
  customWithoutSeed: number;
}

@Injectable()
export class TopicPanelService {

  public readonly topicCounts$: Observable<TopicCounts>;

  constructor(private query: ModifiersQuery) {
    this.topicCounts$ = query.selectAll().pipe(map(modifiers => this.calcCounts(modifiers)));
  }

  public calcCounts(modifiers: IModifier[]): TopicCounts {
    const suggestions = new Map<number, ISuggestion>();
    modifiers.forEach(m => suggestions.set(m.topicId, m.modifierModel.suggestMap));

    // // some modifiers for this stage might represent 'user duplicated' topics
    // // the suggestionMap wont fill in until the next stage
    // // add useful suggestions from the parent topic where needed
    // const duplicatedModifiers: DTOModifier[] = lens.modifiers.filter(mod => mod.isDuplicateTopic());
    // duplicatedModifiers.forEach(mod => {
    //   const tempSug: ISuggestion = lens.model.suggestionMap[mod.duplicateOfTopicId];
    //   lens.model.suggestionMap[mod.topicId] = tempSug;
    // });

    const nonCustomModifiers = modifiers.filter(mod => !mod.customTopic);
    const custom = modifiers.length - nonCustomModifiers.length;
    const customWithoutSeed: number = modifiers
      .filter(mod => getModifierState(mod) === ModifierState.CUSTOM)
      .filter(mod => !mod.customTopic.seed).length;

    const userLabeled = nonCustomModifiers.reduce(
      (prev: number, curr: IModifier) => prev + (getModifierState(curr) === ModifierState.LABELED ? 1 : 0), 0);
    const suggestionLabeled = suggestions ? nonCustomModifiers.reduce(
      (prev: number, curr: IModifier) => {
        const s = suggestions.get(curr.topicId);
        return prev +
          (getModifierState(curr) === ModifierState.UNLABELED && s.suggestedLabel && s.suggestedMerge < 0 && !s.suggestUnclear ? 1 : 0);
      }, 0) : 0;
    const labeled = userLabeled + suggestionLabeled;

    const userMerged = nonCustomModifiers.reduce(
      (prev: number, curr: IModifier) => prev + (getModifierState(curr) === ModifierState.MERGED ? 1 : 0), 0);
    const suggestionMerged = suggestions ? nonCustomModifiers.reduce(
      (prev: number, curr: IModifier) => {
        const s = suggestions.get(curr.topicId);
        return prev + (getModifierState(curr) === ModifierState.UNLABELED && s.suggestedMerge >= 0 && !s.suggestUnclear ? 1 : 0);
      }, 0) : 0;
    const merged = userMerged + suggestionMerged;

    const userUnclear = nonCustomModifiers.reduce(
      (prev: number, curr: IModifier) => prev + (getModifierState(curr) === ModifierState.UNCLEAR ? 1 : 0), 0);
    const suggestionUnclear = suggestions ? nonCustomModifiers.reduce(
      (prev: number, curr: IModifier) => {
        const s = suggestions.get(curr.topicId);
        return prev + (getModifierState(curr) === ModifierState.UNLABELED && s.suggestUnclear ? 1 : 0);
      }, 0) : 0;
    const unclear = userUnclear + suggestionUnclear;

    const blank = nonCustomModifiers.reduce(
      (prev: number, curr: IModifier) => {
        const s = suggestions ? suggestions.get(curr.topicId) : null;
        return prev +
          (getModifierState(curr) === ModifierState.UNLABELED &&
          (s ? !s.suggestedLabel && s.suggestedMerge < 0 && !s.suggestUnclear : true) ? 1 : 0);
      }, 0);

    return {
      currentTopics: modifiers.length  - userUnclear - userMerged,
      initialTopics: modifiers.length,
      labeled,
      userLabeled,
      suggestionLabeled,
      merged,
      userMerged,
      suggestionMerged,
      unclear,
      userUnclear,
      suggestionUnclear,
      blank,
      suggestions: suggestionLabeled + suggestionMerged + suggestionUnclear,
      custom,
      customWithoutSeed
    };
  }
}

import { combineLatest, concat, Observable, of, OperatorFunction } from 'rxjs';
import { MatSort, Sort } from '@angular/material/sort';
import { map } from 'rxjs/operators';

export function materialDataSorter<T>(matSort: MatSort): OperatorFunction<T[], T[]> {
  return function (source$: Observable<T[]>) {
    return combineLatest([source$, concat(of<Sort>({
      active: matSort.active,
      direction: matSort.direction
    }), matSort.sortChange)]).pipe(
      map(([data, sort]) => sortData(data, sort))
    );
  };
}

export function sortData(data: any[], sort: Sort) {
  const accessor = propertyAccessor(sort.active); //  (d: any) => d[sort.active];
  const nulls = data.filter(d => isNullUndefined(accessor(d)));
  const notNulls = data.filter(d => !isNullUndefined(accessor(d)));
  const comparator = (dA: any, dB: any) => {
    const a = accessor(dA);
    const b = accessor(dB);
    const isA = a !== null && a !== undefined;
    const isB = b !== null && b !== undefined;
    const result = (isA && isB) ? (a > b ? 1 : a < b ? -1 : 0) : isA ? a : isB ? b : 0;
    return result * (sort.direction === 'asc' ? 1 : -1);
  };
  return notNulls.sort(comparator).concat(nulls);
}

function isNullUndefined(d: any) {
  return d === null || d === undefined;
}

function propertyAccessor(prop: string): (any) => any {
  const split = prop.split('.');
  return function (d: any): any {
    const v = split.reduce((prev: any, p: string) => prev && prev[p], d);
    return typeof v === 'string' ? v.toLowerCase() : v;
  }
}

import { Component } from '@angular/core';
import { faHourglassStart, faHourglassHalf, faHourglassEnd } from '@fortawesome/pro-light-svg-icons';
import { timer } from 'rxjs';
import { share } from 'rxjs/operators';

@Component({
  selector: 'app-hourglass',
  template: `<fa-icon [icon]="icon(t$ | async)" [ngClass]="{reset: (t$ | async) % 4 === 3}"></fa-icon>`,
  styleUrls: ['./hourglass.component.scss']
})
export class HourglassComponent {
  static timer$ = timer(0, 1000).pipe(share());

  t$ = HourglassComponent.timer$;

  icon0 = faHourglassStart;
  icon1 = faHourglassHalf;
  icon2 = faHourglassEnd;

  icon(step: number) {
    switch (step % 4) {
      case 0: return this.icon0;
      case 1: return this.icon1;
      case 2: return this.icon2;
      case 3: return this.icon2;
    }
  }

}
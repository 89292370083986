import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as _ from 'lodash';
import {IViewBy} from '../iviewby.interface';

@Component({
  selector: 'app-filter-select',
  templateUrl: './filter-select.component.html',
  styleUrls: ['./filter-select.component.scss']
})
export class FilterSelectComponent implements OnInit {
  /**
   * options must be an Array of {key: '', label: '', order: ''} see training-data-list.constatns.ts as example.
   */
  @Input() options: Array<IViewBy>;
  @Output() optionSelected: EventEmitter<IViewBy> = new EventEmitter();
  optSelected: any;

  optSelectedChanged() {
    this.optionSelected.emit(this.optSelected);
  }

  ngOnInit() {
    this.optSelected = _.find(this.options, {'selectedByDefault': true});
    this.optSelectedChanged();
  }
}

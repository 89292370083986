import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {TopicCounts} from '../../model-workspace/topic-panel/topic-panel.service';
import * as d3Sel from 'd3-selection';
import * as d3Shape from 'd3-shape';

@Component({
  selector: 'app-model-progress-chart',
  templateUrl: './model-progress-chart.component.html',
  styleUrls: ['./model-progress-chart.component.scss']
})
export class ModelProgressChartComponent implements AfterViewInit {

  @Input() counts: TopicCounts;
  @Input() busy: boolean;

  @Input() width = 200;

  canDraw = false;

  @ViewChild('svg', { static: true }) svg: ElementRef;

  constructor() {
  }

  ngAfterViewInit(): void {
    if (!this.counts) {
      return;
    }

    this.canDraw = true;

    const data = [
      { classes: 'label', value: this.counts.userLabeled },
      { classes: 'label sug', value: this.counts.suggestionLabeled },
      { classes: 'merge', value: this.counts.userMerged },
      { classes: 'merge sug', value: this.counts.suggestionMerged },
      { classes: 'unclear', value: this.counts.userUnclear },
      { classes: 'unclear sug', value: this.counts.suggestionUnclear },
      { classes: 'custom', value: this.counts.custom },
      { classes: 'blank', value: this.counts.blank },
    ];

    const r = this.width * 0.5;
    const root = d3Sel.select(this.svg.nativeElement).append('g')
      .attr('transform', `translate(${r},${r})`);
    const arcs = d3Shape.pie<any>().value((d: any) => d.value).sort(d => d.index)(data);
    root.selectAll('path').data(arcs)
      .enter()
      .append('path')
      .attr('d', d3Shape.arc<any>().innerRadius(r * .5).outerRadius(r))
      .attr('class', d => d.data.classes);
  }

}

import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {IDistributionBase} from '../../types/attributes';
import {UBQ} from '../../app-config';

@Component({
  selector: 'app-document-topic-distribution',
  templateUrl: './document-topic-distribution.component.html',
  styleUrls: ['./document-topic-distribution.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentTopicDistributionComponent implements OnInit, OnChanges {

  @Input() public distributions: IDistributionBase[];
  @Input() public threshold: number;
  @Input() public focusTopicIds: number[] = []; // focus topics come first
  @Input() public topicIdLabels: string[]; // array of strings mapping topicId to display label
  @Input() public numLabelColumns = 2;
  @Input() public maxThreshold = 1;
  @Input() public selectedTopicId = -1;

  @Output() public topicClicked = new EventEmitter<number>(); // returns topicId

  public labelDistributions: IDistributionBase[];
  public hoverDistribution: IDistributionBase;
  public colors: any[];

  constructor(private ubq: UBQ) {
    this.colors = this.ubq.getColors();
  }

  ngOnInit() {
    this.doUpdate();
  }

  ngOnChanges(): void {
    this.doUpdate();
  }

  public doUpdate(): void {
    if (this.distributions == null) {
      return;
    }

    if (this.threshold == null) {
      this.threshold = 1 / this.distributions.length;
    }

    this.distributions = this.distributions
      // .filter(dist => dist.topicId === this.topicId || dist.weightFloat >= this.threshold)
      .sort((a: IDistributionBase, b: IDistributionBase) => b.weightFloat - a.weightFloat);

    // move current topic to front - sort rest
    if (this.distributions.length > 0) {
      for (let i = this.focusTopicIds.length - 1; i >= 0; i--) {
        const index =  this.distributions.findIndex(d => d.topicId === this.focusTopicIds[i]);
        if (index >= 0) {
          const dist: IDistributionBase = this.distributions.splice(index, 1)[0];
          this.distributions.unshift(dist);
        }
      }
    }

    this.labelDistributions = this.distributions.filter(
      (dist, i) => i === 0 || dist.weightFloat >= this.threshold || dist.topicId === this.selectedTopicId);
  }

  // we render a second row with the same logic as the first
  // it is set to visible hidden via css
  // it does not impact the top row to acquire class visible
  public isHover(dist: IDistributionBase): boolean {
    const bool = (!!this.hoverDistribution && !!dist && this.hoverDistribution.topicId === dist.topicId)
      || (dist.topicId === this.selectedTopicId);
    return bool;
  }

  public onHoverCell(topicId: number) {
    this.hoverDistribution = this.distributions.find((dist: IDistributionBase) => dist.topicId === topicId);
  }

  public clearHover(): void {
    this.hoverDistribution = null;
  }

  public getTopicColor(topicId: number): string {
    return this.colors[topicId];
  }

  public getTopicLabel(topicId: number): string {
    return this.topicIdLabels[topicId];
  }

  public getEmptyPercent(): number {
    if (this.distributions.length === 0) {
      return 1;
    }
    const total: number = this.distributions
      .map((dist: IDistributionBase) => dist.weightFloat)
      .reduce((prev, curr) => prev + curr);
    return 1 - total;
  }

  getColor(dist: IDistributionBase) {
    let w = dist.weightFloat;
    // w = Math.exp( -4 * Math.pow(this.maxThreshold - w, 6)); // Use an exponential step function to smoothly interpolate values towards 0
    w = w / this.maxThreshold;
    const a = this.isHover(dist) ? 'ff' : ('0' + Math.round(w * 255).toString(16)).substr(-2);
    return this.getTopicColor(dist.topicId) + a;
  }

  getDotPercentFontWeight(dist: IDistributionBase) {
    return this.isHover(dist) ? '600' : this.focusTopicIds.includes(dist.topicId) ? '500' : '400';
  }
  getDotTopicFontWeight(dist: IDistributionBase) {
    return this.isHover(dist) ? '500' : this.focusTopicIds.includes(dist.topicId) ? '400' : '300';
  }

  distTrackBy(index: number, dist: IDistributionBase) {
    return dist.topicId;
  }
}

import {ISourceFile} from './source';
import {IDocumentCollection} from './document.collection';
import {IMapNumberArray, IMapStringArray, INumTMap, IStringTMap} from './ubq.types';
import {IDocumentIds} from './documentIds';
import {ILens} from './lens';
import {IPadmaDocDistribution} from './attributes';
import {IBase} from './base';
import {ModifierState} from './modifier';

export interface IModel extends IBase {
    uid: string;
    updatedAt: Date;
    source: ISourceFile;
    sourceId: number;
    documentCollection: IDocumentCollection;
    documentCollectionId: number;
    burnInRate: number;
    optimization: boolean;
    numIteration: number;
    numberOfTopics: number;
    isCompleted: boolean;
    isFailed: boolean;
    isTimedOut: boolean;
    topics: IMapStringArray;
    topTermWeights: ITermWeight[][];
    topDocumentIds: IMapNumberArray;
    allTopDocumentIds: IDocumentIds;
    parentLens: ILens;
    parentModel: IModel;
    parentModelId: number;
    attributes: { docTopicDistributions: IPadmaDocDistribution[] };
    topicWeights: number[];
    topPhrases: IMapStringArray;
    mapping: IStringTMap<string>;
    documentCountByTopic: IStringTMap<number>;
    documentCount: number;
    status: IModelStatus;
    modelMemory: number;
    suggestedStopWords: string[];
    suggestionMap: INumTMap<ISuggestion>;
    thresholds: IStringTMap<number>;
    uuid: string;
}

export interface ITermWeight {
    [0]: string;
    [1]: number;
}

export class IPhrases {
    id: string;
    phrases: string[];

    constructor(id: string, phrases: string[]) {
        this.id = id;
        this.phrases = phrases;
    }
}

export interface IModelStatus {
    status: string;
    msg: string;
    completion: number;
    updatedAt?: Date;
}

export interface ISuggestion {
    suggestedLabel: string;
    suggestedMerge: number; // takes precedence over suggested labels
    suggestUnclear: boolean; // not currently used
    suggestedSubLabel: string;
    suggestedUnknown: boolean; // the system doesn't have a suggestion
}

export function suggestionState(s: ISuggestion): ModifierState {
    if (s.suggestedMerge >= 0) {
        return ModifierState.MERGED;
    } else if (s.suggestUnclear) {
        return ModifierState.UNCLEAR;
    } else if (s.suggestedLabel) {
        return ModifierState.LABELED;
    } else {
        return ModifierState.UNLABELED;
    }
}

import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {TopFilterService} from './top-filter.service';
import {IViewBy} from './iviewby.interface';

@Component({
  selector: 'app-top-filter',
  templateUrl: 'top-filter.component.html',
  styleUrls: ['./top-filter.component.scss'],
  providers: [TopFilterService]
})
export class TopFilterComponent {

  allUsers = this.getInitialState();
  showFilter: boolean = true;
  showTextFilter: boolean = false;
  @Input() selectOptions: Array<IViewBy>;
  @Input() hasOrderFilter: boolean = false;
  @Input() hasTextFilter: boolean = false;
  @Input() source: Array<any>;
  @Output() orderOptionSelected: EventEmitter<IViewBy> = new EventEmitter<IViewBy>();
  @Output() textFilterChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() onFilterMe: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onAllUsers = new EventEmitter<boolean>();

  @ViewChild('search') searchElement: ElementRef;

  constructor(private topFilterService: TopFilterService) {
    this.topFilterService.showFilter.subscribe(value => this.showFilter = value);
  }

  getInitialState() {
    const savedState = localStorage.getItem('allUsers');
    if (savedState !== null) {
      return JSON.parse(savedState);
    }
    return false;
  }

  ngOnInit() {
    this.onAllUsers.emit(this.allUsers);
  }

  handleOrderOptionSelected($event: IViewBy) {
    // console.log('handleOrderOptionSelected', $event);
    this.onFilterMe.emit($event.isFilter);
    this.orderOptionSelected.emit($event);
  }

  updateTextFilter($event) {
    // console.log('updateTextFilter', $event);
    this.textFilterChange.emit($event);
  }

  checkboxChange() {
    localStorage.setItem('allUsers', JSON.stringify(this.allUsers))
    this.onAllUsers.emit(this.allUsers)
  }
}

import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

export type RoundedOn = 'top' | 'bottom' | 'both';
export type HighlightOn = 'word' | 'box';
export type FontSize = 'sm' | 'lg';

export class TabSelector {
  text: string;
  subtext: number;
  selected: boolean = false;
}

@Component({
  selector: 'app-half-tabs',
  templateUrl: './half-tabs.component.html',
  styleUrls: ['./half-tabs.component.scss']
})
export class HalfTabsComponent implements OnInit, OnChanges {

  @Input() tabs: TabSelector[];
  @Input() defaultTo: TabSelector;
  @Input() roundedOn: RoundedOn = 'bottom';
  @Input() hightlight: HighlightOn = 'word';
  @Input() fontSize: FontSize = 'sm';
  @Output() tabClicked: EventEmitter<TabSelector> = new EventEmitter<TabSelector>();

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.defaultTo) {
      const tab: TabSelector = this.tabs.find(atab => atab.text === this.defaultTo.text);
      if (!!tab) {
        this.onMouseCLicked(tab);
      }
    }
  }

  hasSubtext(tab: TabSelector): boolean {
    if (!tab.subtext) {
      return false;
    }

    return true;
  }

  onMouseCLicked(tab: TabSelector) {
    this.tabs.forEach(atab => atab.selected = false);
    this.tabs.find(aTab => aTab.text === tab.text).selected = true;
    this.tabClicked.emit(tab);
    console.log('emitted', tab);
  }
}


import { Mutator, query } from 'rx-query';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export function mutation<Output>(
    key: string,
    mutator?: (data: unknown) => Observable<Output>
) {
    const initial: WrapperQueryResult<Output> = {
        type: 'initial'
    };

    const toQueryResult = (result: Output) => {
        const res: WrapperQueryResult<Output> = {
            type: 'result',
            value: result
        }
        return res;
    }

    // Generate random query name for mutations
    const mutationKey = key + uuidv4();

    const q = query(mutationKey, () => {
        return of(initial);
    }, {
        retries: 0,
        mutator: (data) => {
            const mutate$ = mutator(data);
            return mutate$.pipe(
                map(item => {
                    return toQueryResult(item);
                })
            )
        }
    }).pipe(
        map(item => {
            const res: MutationResult<Output> = {
                type: 'idle',
                mutate: (data, updater) => {
                    item.mutate(data, (current) => {
                        if (updater) {
                            return toQueryResult(updater(current.value));
                        }
                        return toQueryResult(current.value);
                    })
                },
            };

            if (item.data?.type == 'initial') {
                return res;
            }

            switch (item.status) {
                case 'idle':
                case 'loading':
                    res.type = 'idle';
                    break;
                case 'mutating':
                    res.type = 'loading';
                    break;
                case 'success':
                    res.type = 'success';
                    res.value = item.data.value;
                    break;
                case 'mutate-error':
                    res.type = 'error';
                    break;
            }
            return res;
        })
    );

    return q;
}

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

interface MutationResult<T> {
    type: 'idle' | 'loading' | 'success' | 'error';
    value?: T;
    mutate: Mutator<T>;
}

interface WrapperQueryResult<T> {
    type: 'initial' | 'result'
    value?: T
}
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {ILens} from '../../types/lens';
import {LensService} from '../../services-http/lens.service';
import {Observable} from 'rxjs/internal/Observable';
import {filter, map, take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LensResolver  {

  constructor(private lensService: LensService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ILens> {
    const lensId: number = parseInt(route.paramMap.get('id'));
    console.log('lens-resolver');
    return this.lensService.getById(lensId).pipe(
      filter(val => !!val),
      filter((lens: ILens) => lens.id === lensId),
      take(1),
      map((lens: ILens) => {
        return lens;
      })
    );
  }
}

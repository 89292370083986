import {Injectable, OnDestroy} from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient} from '@angular/common/http';
import {UBQ} from '../app-config';
import {NavigationStart, Router, Event} from '@angular/router';
import {IStopWords} from '../types/stop-words';
import {ILens} from '../types/lens';
import {BehaviorSubject, Subject} from 'rxjs';
import {filter, pairwise, takeUntil} from 'rxjs/operators';

@Injectable()
export class StopWordsService extends BaseService<IStopWords> implements OnDestroy {

  private _isOpen: boolean = false;
  public selected: IStopWords;

  // Based on how we arrived on the current page, should we show suggested stop words (if available)?
  private showSuggestedNavigation$ = new BehaviorSubject(true);

  private destroy$ = new Subject<boolean>();

  constructor(public _http: HttpClient,
              public ubq: UBQ,
              public router: Router) {
    super(_http, ubq, router);
    this.init('stopwords');
    router.events.pipe(
      takeUntil(this.destroy$),
      filter(event => event instanceof NavigationStart),
      pairwise(),
    ).subscribe(([prev, curr]: [Event, Event]) => this.showSuggestedNavigation$.next(!(prev as NavigationStart).url.endsWith('merge')));
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  showSuggested(lens: ILens): boolean {
    // handle already
    if (lens.hasViewedGeneratedStopwords) {
      return false;
    }

    if (lens.model.suggestedStopWords.length > 0) {
      return this.showSuggestedNavigation$.getValue();
    } else if (lens.model.suggestedStopWords.length === 0) {
      // Shouldn't happen, since we mark it as viewed if there aren't any
      return false;
    } else {
      console.log('unknown showSuggested state', lens.model.suggestedStopWords, lens.hasViewedGeneratedStopwords);
      return false;
    }
  }

  get isOpen(): boolean {
    return this._isOpen;
  }

  togglePanel(value: boolean = null): void {
    if (value !== null) {
      this._isOpen = value;
    } else {
      this._isOpen = !this._isOpen;
    }
  }

  openToCustom(custom: IStopWords): void {
    this.selected = custom;
    this._isOpen = true;
  }
}

import {NgModule} from '@angular/core';
import {StopWordEditorComponent} from './stop-word-editor.component';
import {StopwordApplyModalComponent} from './stopword-apply-modal/stopword-apply-modal.component';
import {StopwordExportModalComponent} from './stopword-export-modal/stopword-export-modal.component';
import {StopwordImportModalComponent} from './stopword-import-modal/stopword-import-modal.component';
import {SharedModule} from '../shared/shared.module';
import {CommonModule} from '@angular/common';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {FormsModule} from '@angular/forms';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    NgbDropdownModule,
    SharedModule
  ],
  declarations: [
    StopWordEditorComponent,
    StopwordApplyModalComponent,
    StopwordExportModalComponent,
    StopwordImportModalComponent
  ],
  exports: [
    StopWordEditorComponent,
    StopwordApplyModalComponent,
    StopwordExportModalComponent,
    StopwordImportModalComponent
  ]
})
export class StopWordEditorModule {}

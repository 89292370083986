import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-feed-card',
  templateUrl: './feed-card.component.html',
  styleUrls: ['./feed-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FeedCardComponent implements OnInit {

  @Input() title: string;
  @Input() time: Date;
  @Input() isError = false;

  constructor() { }

  ngOnInit() {
    // console.log(`${this.title}  |  ${this.time}`);
  }

}

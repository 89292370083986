<div class="p-feedcard">
  <div class="p-feedcard-header d-flex justify-content-between align-items-baseline" [ngClass]="{'p-feedcard-failed': isError}">
    <div class="p-feedcard-title">{{title}}</div>
    <div class="p-feedcard-time">{{time | date:'shortTime'}}</div>
  </div>
  <div class="p-feedcard-body">
    <ng-content select=".feed-card-body"></ng-content>
  </div>
  <div class="p-feedcard-footer">
    <ng-content select=".feed-card-footer"></ng-content>
  </div>
</div>

<div class="paper" [ngClass]="{embedded: embedded, disableEdit: (!embedded && !isOwned)}">
  <div class="edit-stopwords-form">

    <section *ngIf="!embedded">
      <div class="exit">
        <span class="btn" (click)="onClose()">
          <fa-icon [size]="'lg'" [icon]="['fal', 'times']"></fa-icon>
        </span>
      </div>

      <h1>Viewing Stopword List</h1>
    </section>

    <div id="name-input" class="po-form" *ngIf="!embedded">
      <form>
        <div class="form-group">
          <div class="row">
            <div class="col-12">
              <div class="input-group">
                <input type="text"
                       id="list-name"
                       name="list-name"
                       placeholder="Name for new Stop Word list"
                       [(ngModel)]="dtoStopWords.name"
                       (input)="onNameChange()"
                       class="form-control inherit-height"
                       maxlength="35">
                <div class="meta">
                  <span *ngIf="dupName" class="name-error">Stopword List name already exists!</span>
                  <span class="word-count">{{ dtoStopWords.wordCount }} words</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div id="word-input" class="po-form">
      <div class="form-group">
        <div class="inputRow">
          <div class="input-group">
            <input type="text"
                    id="list-word"
                    name="list-word"
                    placeholder="Type Stop Words"
                    [(ngModel)]="inputNewWord"
                    (keydown)="onKeydown($event)"
                    class="form-control inherit-height"
                    maxlength="35">
              <span class="input-group-text">
                <fa-icon [size]="'sm'" [icon]="['fal', 'level-down']" [rotate]="90"></fa-icon>
              </span>
          </div>
        </div>
      </div>
    </div>

    <div [hidden]="!embedded" id="tabs">
      <app-half-tabs [tabs]="tabs" [roundedOn]="'top'" [defaultTo]="tabs[0]" (tabClicked)="onTabChanged($event)"></app-half-tabs>
    </div>

    <div id="stopwords" class="w-100">

      <div id="legend">
        <span *ngFor="let letter of legend" class="aLetter" (click)="gotoLetter(letter)"> {{ letter }}</span>
      </div>

      <div id="word-area" [ngClass]="wordClass">
        <div *ngFor="let word of renderWords" class="aRow clearfix">
          <span class="aWord" (click)="onToggleWord(word)" [ngClass]="{isChangedWord: isChangedWord(word), isAppliedToModel: !isWordAlreadyApplied(word), isNewWord: isWordAlreadyApplied(word)}">
            <span class="content">{{ word }}
              <span class="removeNewWord">
                <fa-icon [size]="'sm'" [icon]="['fal', 'times']"></fa-icon>
              </span>
            </span>
          </span>
          <span class="suggested align-middle" *ngIf="isSuggestedWord(word)"><fa-icon [icon]="['fas', 'bolt']"></fa-icon></span>
        </div>
      </div>
    </div>
  </div>
</div>

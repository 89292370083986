import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {UBQ} from '../app-config';
import {BaseService} from './base.service';
import {saveAs} from 'file-saver';
import {Router} from '@angular/router';
import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';
import {LensService} from './lens.service';
import { IUnitAnalysis } from '../types/unit-analysis';

@Injectable()
export class UnitAnalysisService extends BaseService<IUnitAnalysis>  {

  constructor(public _http: HttpClient, public ubq: UBQ, public router: Router, private lensService: LensService) {
    super(_http, ubq, router);
    this.init('analysis');
  }

  getAutoReportByLensId(id: number): Observable<IUnitAnalysis> {
    return this._http.get(`${this.baseURL}/${this.endpoint}/lens/${id}`).pipe(
      map(response => this.serializeOne(response)));
  }

  export(analysisId: number, unitIds: number[], callback?: (error, savedFileContents?) => void) {
    const payload: any = {parentId: analysisId, ids: unitIds};
    this._http.post(`${this.baseURL}/${this.endpoint}/export`, payload, {observe: 'response', responseType: 'blob'})
      .subscribe({
        next: (response: HttpResponse<Blob>) => {
          if (response.ok) {
            const disp = response.headers.get('Content-Disposition');
            let filename = 'bookmarks';
            if (disp) {
              const fi = disp.indexOf('filename=');
              if (fi >= 0) {
                let fn = disp.substring(fi + 9);
                if (fn.charAt(0) === '"') {
                  fn = fn.substring(1, fn.indexOf('"', 1));
                } else {
                  fn = fn.split(' ')[0];
                }
                if (fn.length > 0) {
                  filename = fn;
                }
              }
            }
            if (!filename.endsWith('.tsv')) {
              filename = filename + '.tsv';
            }
            saveAs(response.body, filename);
            if (callback) {
              callback(null, response.body);
            }
          } else {
            if (callback) {
               callback(response);
            }
          }
        },
        error: (err) => { if (callback) { callback(err); } }
      });
  }

  /** Rename returns a 200 response on success and will throw on error */
  async rename(id: number, name: string): Promise<any> {
    const url = `${this.baseURL}/${this.endpoint}/rename/${id}`;
    return this._http.post(url, {name}).toPromise();
  }

  serializeOne(response: any): any {
    const uc = response as IUnitAnalysis;
    uc.lens = this.lensService.serializeOne(uc.lens);
    return uc;
  }

  serializeMany(response: any[]): Array<any> {
    return response.map(r => this.serializeOne(r));
  }
}

<div class=" h-100">
  <div class="paper h-100" *ngIf="isLoadComplete && modifier">
    <div class="feed">
      <div class="row document" *ngFor="let document of documents; index as i; trackBy: trackFn">
        <div class="col-7 py-2 ps-0">
          <div class="row">
            <div class="col-12">
              <div class="document-card-title">
                {{document.title}}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 document-card-comment">
              <app-document-topic-text [text]="document.content" [index]="i"
                                       [topicTerms]="modifierTerms"
                                       [phrases]="modifierPhrases"></app-document-topic-text>
              <!--{{ document.content }}-->
            </div>
          </div>
        </div>
        <div class="col-5 pt-2 pb-2S pe-30">
          <app-document-topic-distribution
            [focusTopicIds]="focusTopicIds"
            [threshold]="threshold"
            [topicIdLabels]="distributionService.distributionLabels$ | async"
            [distributions]="document.distributions"></app-document-topic-distribution>
        </div>
      </div>
    </div>
  </div>
</div>

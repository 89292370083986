import {throwError as observableThrowError} from 'rxjs';
import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {UBQ} from '../app-config';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {ITaxonomy} from '../types/taxonomy';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class TaxonomyService extends BaseService<ITaxonomy> {

  constructor(public _http: HttpClient, public ubq: UBQ, public router: Router) {
    super(_http, ubq, router);
    this.init('taxonomies');
  }

  post(tax: ITaxonomy): Promise<ITaxonomy> {
    return this._http.post(`${this.baseURL}/taxonomies`, tax).pipe(
      map(response => this.serializeOne(response)),
      catchError(error => observableThrowError(error)))
      .toPromise();
  }

}

<div class="row">
  <div class="col-12">
    <span class="title text-uppercase">{{title}}</span>
  </div>

  <div *ngFor="let option of options" class="col-3">
    <span class="btn option d-inline-block mt-3 btn-circle" [ngClass]="{selected: selected == option }" (click)="onClick(option)">{{formatNumber(option)}}</span>
  </div>
</div>


import * as d3Force from 'd3-force';

// Based on http://bl.ocks.org/christophermanning/1703449
export class HamiltonianChart {

  private static readonly GRAPHS: {code: string, name: string}[] = [
    { code: '[10]50', name: 'Pienso Default'},
    { code: '[10,9,8,7,6,5,4,3,2,1]10', name: 'Ten Petals'},
    { code: '[2,-3,5,-7,9,-21]8', name: 'Just something I made up'},
    { code: '[5,7,-7,7,-7,-5]13', name: 'Glaucopsyche xerces'}, // 78 points
    { code: '[-25,7,-7,13,-13,25]8', name: 'Emperoptera mirabilis'}, // 48 points
    // { code: '[2]4', name: 'Tetrahedral graph' },
    // { code: '[3]6', name: 'Utility graph' },
    // { code: '[3,-3]4', name: 'Cubical graph' },
    // { code: '[4]8', name: 'Wagner graph' },
    // { code: '[6,4,-4]4', name: 'Bidiakis cube' },
    // { code: '[5,-5]6', name: 'Franklin graph' },
    // { code: '[-5,-2,-4,2,5,-2,2,5,-2,-5,4,2]', name: 'Frucht graph' },
    // { code: '[2,6,-2]4', name: 'Truncated tetrahedral graph' },
    // { code: '[5,-5]7', name: 'Heawood graph' },
    // { code: '[5,-5]8', name: 'Mobius-Kantor graph' },
    // { code: '[5,7,-7,7,-7,-5]3', name: 'Pappus graph' },
    // { code: '[5,-5,9,-9]5', name: 'Desargues graph' },
    // { code: '[10,7,4,-4,-7,10,-4,7,-7,4]2', name: 'Dodecahedral graph' },
    // { code: '[12,7,-7]8', name: 'McGee graph' },
    // { code: '[2,9,-2,2,-9,-2]4', name: 'Truncated cubical graph' },
    // { code: '[3,-7,7,-3]6', name: 'Truncated octahedral graph' },
    // { code: '[5,-9,7,-7,9,-5]4', name: 'Nauru graph' },
    // { code: '[-7,7]13', name: 'F26A graph' },
    // { code: '[-13,-9,7,-7,9,13]5', name: 'Tutte–Coxeter graph' },
    // { code: '[5,-5,13,-13]8', name: 'Dyck graph' },
    { code: '[-25,7,-7,13,-13,25]9', name: 'Gray graph' }, // 54 vertices
    { code: '[30,-2,2,21,-2,2,12,-2,2,-12,-2,2,-21,-2,2,30,-2,2,-12,-2,2,21,-2,2,-21,-2,2,12,-2,2]2', name: 'Truncated dodecahedral graph' }, // 60
    { code: '[-29,-19,-13,13,21,-27,27,33,-13,13,19,-21,-33,29]5', name: 'Harries graph' }, // 70
    { code: '[9,25,31,-17,17,33,9,-29,-15,-9,9,25,-25,29,17,-9,9,-27,35,-9,9,-17,21,27,-29,-9,-25,13,19,-9,-33,-17,19,-31,27,11,-25,29,-33,13,-13,21,-29,-21,25,9,-11,-19,29,9,-27,-19,-13,-35,-9,9,17,25,-9,9,27,-27,-21,15,-9,29,-29,33,-9,-25]', name: 'Harries–Wong graph' }, // 70
    { code: '[-9,-25,-19,29,13,35,-13,-29,19,25,9,-29,29,17,33,21,9,-13,-31,-9,25,17,9,-31,27,-9,17,-19,-29,27,-17,-9,-29,33,-25,25,-21,17,-17,29,35,-29,17,-17,21,-25,25,-33,29,9,17,-27,29,19,-17,9,-27,31,-9,-17,-25,9,31,13,-9,-21,-33,-17,-29,29]', name: 'Balaban 10-cage' }, // 70
    { code: '[17,-9,37,-37,9,-17]15', name: 'Foster graph' }, // 90
    // { code: '[16,24,-38,17,34,48,-19,41,-35,47,-20,34,-36,21,14,48,-16,-36,-43,28,-17,21,29,-43,46,-24,28,-38,-14,-50,-45,21,8,27,-21,20,-37,39,-34,-44,-8,38,-21,25,15,-34,18,-28,-41,36,8,-29,-21,-48,-28,-20,-47,14,-8,-15,-27,38,24,-48,-18,25,38,31,-25,24,-46,-14,28,11,21,35,-39,43,36,-38,14,50,43,36,-11,-36,-24,45,8,19,-25,38,20,-24,-14,-21,-8,44,-31,-38,-28,37]', name: 'Biggs-Smith graph' }, //102
    // { code: '[44,26,-47,-15,35,-39,11,-27,38,-37,43,14,28,51,-29,-16,41,-11,-26,15,22,-51,-35,36,52,-14,-33,-26,-46,52,26,16,43,33,-15,17,-53,23,-42,-35,-28,30,-22,45,-44,16,-38,-16,50,-55,20,28,-17,-43,47,34,-26,-41,11,-36,-23,-16,41,17,-51,26,-33,47,17,-11,-20,-30,21,29,36,-43,-52,10,39,-28,-17,-52,51,26,37,-17,10,-10,-45,-34,17,-26,27,-21,46,53,-10,29,-50,35,15,-47,-29,-41,26,33,55,-17,42,-26,-36,16]', name: 'Balaban 11-cage' }, //112
    // { code: '[47,-23,-31,39,25,-21,-31,-41,25,15,29,-41,-19,15,-49,33,39,-35,-21,17,-33,49,41,31,-15,-29,41,31,-15,-25,21,31,-51,-25,23,9,-17,51,35,-29,21,-51,-39,33,-9,-51,51,-47,-33,19,51,-21,29,21,-31,-39]2', name: 'Ljubljana graph' }, //112
    // { code: '[17,27,-13,-59,-35,35,-11,13,-53,53,-27,21,57,11,-21,-57,59,-17]7', name: 'Tutte 12-cage' } //126
  ];

  public width = 800;
  public height = 500;
  public rcx = this.width / 2;
  public rcy = this.height / 2;
  public radius = 100; // 240;
  public colors = ['#1c41b4', '#ff7f0e', '#2ca02c'];
  public nodes = [];
  public links = [];
  public animationSpeed = 50;
  public freezeFrameAt = -1;
  public currentFrame = 0;
  public charge = -10; // -150;
  public interval: any = null;
  public currentVertex = 0;
  public currentVertexOffset = 0;
  public currentStep = 0;

  public numVertices = 50;
  public steps: Array<number> = [10];
  public edges: Array<any> = [];
  public numSteps: number = 1;

  constructor(graphIndex: number = 0) {
    const graph = HamiltonianChart.GRAPHS[Math.abs(graphIndex % HamiltonianChart.GRAPHS.length)];
    console.log('Hamiltonian graph: ' + graph.name);
    this.parseLCF(graph.code);
  }

  private parseLCF(code: string) {
    code = code.replace(/ /g, '').replace(/−/g, '-'); // ensure correct parsing
    const match = code.match(/\[(.*?)\]/);
    if (!match[1].length) {
      throw Error('Hamiltonian graph must have at least one step');
    }
    this.steps = match[1].split(',').filter(Number).map(e => +e);
    const numRepeats = parseInt(code.split(']')[1]) || 0;
    if (numRepeats < 0) {
      throw Error('Hamiltonian graph repeats must be positive');
    }
    this.numSteps = this.steps.length;
    this.numVertices = this.steps.length * (numRepeats || 1);
  }

  private repulseForce = d3Force.forceManyBody().strength((d, i) => {
    return i === 0 ? 0 : this.charge;
  });

  private centerForce = d3Force.forceCenter(this.rcx, this.rcy);

  private constrainForce = (alpha: number) => {
    const maxForce = 100;
    for (let i = 0; i < this.nodes.length; i++) {
      const n = this.nodes[i];
      if (n) {
        const r = 5;
        const dx0 = Math.max(n.x - r, 1);
        const dx1 = Math.max(this.width - n.x + r, 1);
        const dy0 = Math.max(n.y - r, 1);
        const dy1 = Math.max(this.height - n.y + r, 1);
        let dx = Math.min(dx0, dx1);
        let dy = Math.min(dy0, dy1);
        if (dx > 2 * r) {
          dx = Infinity;
        }
        if (dy > 2 * r) {
          dy = Infinity;
        }
        const dirX = dx0 < dx1 ? 1 : -1;
        const dirY = dy0 < dy1 ? 1 : -1;
        const fx = maxForce / dx;
        const fy = maxForce / dy;
        n.vx += dirX * fx * alpha * 0.1;
        n.vy += dirY * fy * alpha * 0.1;
      }
    }
  };


  force = d3Force.forceSimulation()
    .force('repulse', this.repulseForce)
    .force('center', this.centerForce)
    .force('links', d3Force.forceLink().distance(40)) // (d: any) => d.linkDistance))
    .force('radial', d3Force.forceRadial(this.radius, this.rcx, this.rcy).strength(0.01))
    .force('constrain', this.constrainForce)
    .stop();

  restart() {
    this.force.alpha(1).restart();
  }

  createEdges = () => {
    this.edges = [];
    const dupCache = {};

    // Create edges
    for (let vertex = 0; vertex < this.numVertices; vertex++) {
      const source = vertex % this.numVertices;
      let target = (this.numVertices + vertex + this.steps[vertex % this.numSteps]) % this.numVertices;
      const hp_target = (source + 1) % this.numVertices;
      if (target < 0) {
        target = this.numVertices + target;
      }

      // hamiltonian path
      if (!dupCache[source + ',' + hp_target] && !dupCache[hp_target + ',' + source]) {
        this.edges.push({source: source, target: hp_target, value: 1});
        dupCache[source + ',' + hp_target] = 1;
      }

      if (!dupCache[source + ',' + target] && !dupCache[target + ',' + source]) {
        this.edges.push({source: source, target: target, value: 1});
        dupCache[source + ',' + target] = 1;
      }
    }
  }
}

import {NgModule} from '@angular/core';
import {DownloadFileExampleService} from './download-file-example.service';
import {DataGuidelinesComponent} from './data-guidelines/data-guidelines.component';
import {DownArrowComponent} from './down-arrow/down-arrow.component';
import {ProgressBallsComponent} from './progress-balls.component';
import {SearchInputComponent} from './search-input/search-input.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {CommonModule, DatePipe} from '@angular/common';
import {InlineEditableComponent} from './inline-editable.component';
import {TopicColorService} from './topic-color.service';
import {CollapsiblePanelComponent} from './collapsible-panel/collapsible-panel.component';
import {ArrayFilterByProp} from './pipes/arrayFilterByProp.pipe';
import {BytesPipe} from './pipes/bytes.pipe';
import {KeysPipe} from './pipes/keys.pipe';
import {KeyIs} from './pipes/matchKey.pipe';
import {SortByProp} from './pipes/sortByProp.pipe';
import {StringFilterPipe} from './pipes/stringFilter.pipe';
import {HalfTabsComponent} from './half-tabs/half-tabs.component';
import {OnlyNumberDirective} from './directives/only-number.directive';
import { CountToDirective } from './directives/countTo.directive';
import {DateTimeComponent} from './date-time/date-time.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { FilterSelectComponent } from './top-filter/filter-select/filter-select.component';
import { TopFilterComponent } from './top-filter/top-filter.component';
import { EyeToggleComponent } from './eye-toggle/eye-toggle.component';
import { TimerPipe } from './pipes/timer.pipe';
import { HourglassComponent } from './hourglass/hourglass.component';
import { IconRotateComponent } from './icon-rotate/icon-rotate.component';
import { GradientProgressBarComponent } from './gradient-progress-bar/gradient-progress-bar';
import { PhasedProgressBarComponent } from './phased-progress-bar/phased-progress-bar';
import { SingleMultiLabelComponent } from './single-multi-label.component';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    NgbDatepickerModule,
    ReactiveFormsModule,
  ],
  providers: [
    DatePipe,
    DownloadFileExampleService,
    TopicColorService
  ],
  declarations: [
    ArrayFilterByProp,
    BytesPipe,
    CollapsiblePanelComponent,
    DataGuidelinesComponent,
    DateTimeComponent,
    DownArrowComponent,
    EyeToggleComponent,
    FilterSelectComponent,
    GradientProgressBarComponent,
    PhasedProgressBarComponent,
    HalfTabsComponent,
    HourglassComponent,
    IconRotateComponent,
    InlineEditableComponent,
    KeyIs,
    KeysPipe,
    OnlyNumberDirective,
    CountToDirective,
    ProgressBallsComponent,
    SearchInputComponent,
    SingleMultiLabelComponent,
    SortByProp,
    StringFilterPipe,
    TimerPipe,
    TopFilterComponent,
  ],
  exports: [
    ArrayFilterByProp,
    BytesPipe,
    CollapsiblePanelComponent,
    DataGuidelinesComponent,
    DateTimeComponent,
    DownArrowComponent,
    EyeToggleComponent,
    GradientProgressBarComponent,
    PhasedProgressBarComponent,
    HalfTabsComponent,
    HourglassComponent,
    IconRotateComponent,
    InlineEditableComponent,
    KeyIs,
    KeysPipe,
    OnlyNumberDirective,
    CountToDirective,
    ProgressBallsComponent,
    SearchInputComponent,
    SingleMultiLabelComponent,
    SortByProp,
    StringFilterPipe,
    TimerPipe,
    TopFilterComponent,
  ],
})
export class SharedModule {
  constructor() {
  }
}


import { queryCache, QueryConfig, revalidate } from 'rx-query';
import { Observable } from 'rxjs';
import { concatMap, filter, mapTo, switchMap, take, tap, toArray } from 'rxjs/operators';

export function invalidateQueries(predicate: (entry: cacheEntry) => boolean) {
    return function <T>(source: Observable<T>): Observable<T> {
        return source.pipe(
            switchMap(item => {
                return queryCache.pipe(
                    take(1),
                    concatMap((cache) => Object.values(cache)),
                    filter((group) =>
                        !['query-unsubscribe', 'group-unsubscribe'].includes(group.trigger)
                    ),
                    filter((group) => {
                        const entry: cacheEntry = {
                            key: group.groupState.key,
                            result: group.groupState.result,
                            queryConfig: group.groupState.result
                        }
                        return predicate(entry)
                    }),
                    tap(group => {
                        const key = group.groupState.key;
                        revalidate.next({
                            key,
                            trigger: 'manual',
                            config: (undefined as unknown) as Required<QueryConfig>,
                        });
                    }),
                    toArray(),
                    mapTo(item)
                )
            })
        )
    }
}

export interface cacheEntry {
    key: string;
    queryConfig?: QueryConfig;
    result: unknown;
};
import {Component} from '@angular/core';
import {UBQ} from './app-config';

@Component({
  selector: 'app-component',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(public ubq: UBQ) {
    console.log('Pienso API is attempting to communicate with: ', ubq.apiEndpoint );
  }
}

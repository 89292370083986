import { IBase } from './base';
import { ILens } from "./lens";
import { ISourceFile } from "./source";

export interface ILabeledDataAsset extends IBase {
    key: string | null;

    createdBy: string;
    createdById: string;

    location: string | null;
    givenName: string;

    taskId: string | null;
    taskStatus: any | null;

    lensId?: number;
    lens: ILens;
    source: ISourceFile;

    isMultilabel: boolean | null;

    isFinalStatus: boolean;
    isSoftDeleted: boolean;
}

export interface ILabeledData {
    id: number;
    externalId: number;
    content: string;
    label: string;
}

export interface IAnnoateJobResult {
    taskId: string;
    key: string;
    location: string;
    meta: any;
}

// export enum TaskStates {
//     PENDING,
//     STARTED,
//     SUCCESS,
//     FAILURE,
//     RETRY,
//     REVOKED
// }

export interface AnnotationTaskStatusError {
    state: 'failed';
    msg?: string;
    exc_type?: string;
    exc_module?: string;
    exc_message?: string[];
  }
  
  export interface AnnotationTaskStatusSuccess {
    size: number;
    hasMultilabel: boolean;
    annotatedAssetKey: string;
  }
  
  export interface AnnotationTaskStatusProcessing {
    done: number;
    total: number;
  }
  
  export type AnnotationTaskStatus = AnnotationTaskStatusError | AnnotationTaskStatusSuccess | AnnotationTaskStatusProcessing;
  
  export function isAnnotationSuccess(taskStatus: AnnotationTaskStatus): taskStatus is AnnotationTaskStatusSuccess {
    return (taskStatus as AnnotationTaskStatusSuccess).annotatedAssetKey != null && (taskStatus as AnnotationTaskStatusError).state !== 'failed';
  }
  
  export function isAnnotationError(taskStatus: AnnotationTaskStatus): taskStatus is AnnotationTaskStatusError {
    return (taskStatus as AnnotationTaskStatusSuccess).annotatedAssetKey == null && (taskStatus as AnnotationTaskStatusError).state === 'failed';
  }
  
  export function isAnnotationProcessing(taskStatus: AnnotationTaskStatus): taskStatus is AnnotationTaskStatusProcessing {
    return (taskStatus as AnnotationTaskStatusSuccess).annotatedAssetKey == null && (taskStatus as AnnotationTaskStatusError).state !== 'failed' && (taskStatus as AnnotationTaskStatusProcessing).done != null;
  }
  
  export function annotationStatus(data: ILabeledDataAsset): 'NotStarted' | 'Error' | 'Ready' | 'Processing' {
    if (data.taskStatus) {
      const ptStatus = data.taskStatus as AnnotationTaskStatus;
      return isAnnotationError(ptStatus) ? 'Error' : isAnnotationSuccess(ptStatus) ? 'Ready' : 'Processing';
    } else {
        return 'NotStarted';
    }
  }
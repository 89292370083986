export const EXAMPLE_JSON = {
  'data': [
    {
      'id': '1',
      'tags': 'learning, philosophy',
      'content': 'I cannot teach anybody',
      'title': 'Document T',
      'author': 'Socrates',
      'publisher': 'x',
      'month': '12',
      'day': '08',
      'year': '1987'
    },
    {
      'id': '2',
      'tags': 'truth, war',
      'content': 'I’m trying to think,..',
      'title': 'Document R',
      'author': 'Plato',
      'publisher': 'y',
      'month': 'n/a',
      'day': 'n/a',
      'year': 'n/a'
    }
  ]
};

export const EXAMPLE_TSV = [
  ['1', '"learning, philosophy	I cannot teach anybody...	Document T	Socrates"', '"pub"', '"1"',  '"12"', '"8"', '"1987"'],
  ['2', '"truth, war…	I’m trying to think, don’t…	Document R	Plato"', '"pub"', '"2"', '"n/a"', '"n/a"', '"n/a"'],
  ['3', '"philosophy, aristotle	Those who know, do...	Document F	Aristotle"', '"pub"', '"3"', '"3"', '"6"', '"1850"'],
  ['4', '"ideas, philosophy…	All truly great thoughts...	Document J	Nietzsche"', '"pub"', '"4"', '"9"', '"11"', '"1630"'],
  ['5', '"life, living…	The unexamined life is…	Document G	Socrates"', '"pub"', '"5"', '"16"', '"10"', '"2007"'],
  ['6', '"science, knowledge	Science is organized…	Document L	Kant"', '"pub"', '"6"', '"25"', '"2"', '"2015"']
];

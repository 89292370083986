<div class="card">
  <div class="card-header">
    <div class="title">
      <img src="/core/assets/images/totem8_135.svg" alt="Totem 135" class="logo"/>
      <p>Version 6.5 Update</p>
      <p class="header-topic">Explore enhancements, PromptFactory upgrade</p>
    </div>
    
  </div>
  <div class="card-body">
    <p>
      In this release, we&apos;ve enhanced the search functionality for Explore 
      topics, added a &apos;regenerate&apos; function to NLQ, and upgraded
      the PromptFactory base model for better performance on long documents.
    </p>
  </div>
</div>


import {Injectable} from '@angular/core';
import {UBQ} from '../app-config';

@Injectable()
export class TopicColorService {

  private colors: string[];

  constructor(private ubq: UBQ) {
    this.colors = this.ubq.getColors();
  }

  topicIdColor(topicId: number): string {
    return this.colors[topicId];
  }

  getIndexAlphaColor(index: number, pct: number, maxThreshold = 1, minThreshold = 0): string {
    // let value = Math.exp( -4 * Math.pow(this.uaData.maxThresholdValue - this.value, 6));
    let value = (pct - minThreshold) / (maxThreshold - minThreshold);
    if (value > 1) {
      value = 1;
    }
    if (value < 0) {
      value = 0;
    }
    const a = value === 1 ? '' : ('0' + Math.round(value * 255).toString(16)).substr(-2);
    return this.topicIdColor(index) + a;
  }
}

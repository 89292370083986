import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, NavigationEnd} from '@angular/router';
import {ILens} from '../../types/lens';
import {ModifiersQuery} from '../../model-workspace/modifier-state/modifiers.query';
import {ModifiersState, ModifiersStore} from '../../model-workspace/modifier-state/modifiers.store';
import {StopWordsService} from '../../services-http/stop-words.service';
import {TopicPanelService} from '../../model-workspace/topic-panel/topic-panel.service';
import {filter, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-error-low-coverage',
  templateUrl: './train-model-error.component.html',
  styleUrls: ['./train-model-error.component.scss'],
  providers: [
    ModifiersQuery,
    ModifiersStore,
    StopWordsService,
    TopicPanelService
  ]
})
export class TrainModelErrorComponent implements OnInit {
  public lens: ILens;
  public msg: string;
  constructor(public route: ActivatedRoute, public store: ModifiersStore) { }

  ngOnInit() {
    this.msg = 'Topics estimated after resubmit have insufficient document coverage. For best results, we recommend you finish the model.';
    this.lens = this.route.snapshot.data.lensData;
    // this.store.update({lens: this.lens});
  }
}

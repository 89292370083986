import {Component} from '@angular/core';

@Component({
  selector: 'app-progress-balls',
  template: `
    <div class="la-ball-beat ball-beat-color my-3">
      <div></div>
      <div></div>
      <div></div>
    </div>
  `
})
export class ProgressBallsComponent {}

import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from './dashboard/dashboard.component';
import {TrainingDataListComponent} from './training-data/training-data-list/training-data-list.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {SandBoxComponent} from './sand-box/sand-box.component';
import {PlatformComponent} from './platform/platform.component';
import {UnitListComponent} from './unit-list/unit-list.component';
import {UnitUploadComponent} from './unit-upload/unit-upload.component';

/* tslint:disable:max-line-length*/
import {ConversationalDocumentContainerComponent} from './document-views/conversational/conversational-document-container/conversational-document-container.component';
import {PiensoAuthorizationGuard} from '@pienso/auth-module';
import { NgModule } from '@angular/core';

const routes: Routes =
[
  {path: '', redirectTo: 'platform/home', pathMatch: 'full'},
  {
    path: 'platform', component: PlatformComponent, canActivate: [PiensoAuthorizationGuard], children: [
      {path: '', pathMatch: 'full', component: DashboardComponent},
      {path: 'sandbox', component: SandBoxComponent},
      {path: 'home', component: DashboardComponent},
      {path: 'trainingData', component: TrainingDataListComponent},
      {path: 'lenses', loadChildren: () => import('./lenses/lenses.module').then(m => m.LensesModule)},
      {path: 'stopwords', pathMatch: 'full', redirectTo: 'lenses/stopwords'},
      {path: 'conversation/:lensId/:topicId', component: ConversationalDocumentContainerComponent},
      {path: 'dl', loadChildren: () => import('./dl-model/dl-model.module').then(m => m.DLModelModule)},
      {path: 'units', component: UnitListComponent},
      {path: 'units/upload', component: UnitUploadComponent},
      {path: 'units/analysis', loadChildren: () => import('./unit-analysis/unit-analysis.module').then(m => m.UnitAnalysisModule)},
      {path: 'data-deletion', loadChildren: () => import('./purge/purge.module').then(m => m.PurgeModule)},
      {path: '**', component: PageNotFoundComponent}],
    runGuardsAndResolvers: 'always'
  }
];

const routerOptions: ExtraOptions = { enableTracing: false, useHash: false, onSameUrlNavigation: 'reload' };

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }


import { Injectable } from '@angular/core';
import {BaseService} from './base.service';
import {IStopWordsGlobal} from '../types/stop-words-global';
import {HttpClient} from '@angular/common/http';
import {UBQ} from '../app-config';
import {Router} from '@angular/router';
import {IStopWords} from '../types/stop-words';
import {map, take} from 'rxjs/operators';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class GlobalStopWordService extends BaseService<IStopWordsGlobal> {

  constructor(public _http: HttpClient, public ubq: UBQ, public router: Router) {
    super(_http, ubq, router);
    this.init('globalstopwords');
  }

  public importFromEmbedded(embedded: IStopWords, lensId: number): Observable<IStopWordsGlobal> {
    const url = `${this.getEndpoint()}/import/${lensId}`;
    return this._http.post(url, embedded).pipe(
      take(1),
      map(response => this.serializeOne(response)));
  }

  public clone(dto: IStopWords, callback: Function = null): void {
    const url = `${this.getEndpoint()}/clone`;
    this._http.post(url, dto).pipe(
      take(1),
      map(response => this.serializeMany(response)))
      .subscribe(
        data => {
          if (callback) {
            callback(data);
          }
        },
        error => console.log('Could not clone', (anError: Error) => console.error(anError))
      );
  }

}

<div class="offset-1 col-10 mt-30 mb-30">
  <div class="paper col-12">
    <div class="taxonomy-form">
      <h1>Add Stop Words</h1>
      <div id="tags"  class="po-form">

        <form>
          <p>Stop Words are words that Pienso will ignore when analyzing your data. Here you can upload a file with one Stop Word per line.</p>
          <ng-container *ngIf="!showAddFile(); else dropbox">
            <div class="filebox w-75 mx-auto p-2 d-flex">
              <div>{{file ? file.name : dto ? dtoFilename() : ''}}</div>
              <div *ngIf="!dto" class="ms-auto" (click)="clearFile()"><fa-icon [icon]="['fal', 'times-circle']"></fa-icon></div>
            </div>

            <div class="nameBox mt-4">
              <label for="name">List Name: </label><input type="text" id="name" name="name" size="30" placeholder="{{filename()}}" [(ngModel)]="name">
            </div>
          </ng-container>
        </form>

        <div id="button-group" class="text-center my-5">
          <button class="btn btn-primary" (click)="onSave()" [disabled]="!canSave()">
            <ng-container *ngIf="!dto">Create Global Stopword List</ng-container>
            <ng-container *ngIf="!!dto">Save List</ng-container>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #dropbox>
  <div class="my-4"
       ngfDrop
       selectable="1"
       accept="text/plain, text/csv, text/tab-separated-values"
       [(file)]="file">
    <div class="uploadContainer text-center">
      <div class="info" *ngIf="showAddFile()">
        <div>
          <div class="row">
            <div class="col dragTitle">
              <span class="font-weight-normal">Drag & Drop</span>
              <span>&nbsp;</span><span>or</span><span>&nbsp;</span>
              <span class="font-weight-normal">Click to Add</span>
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col">
              <div>File format: Plain text file (.txt)</div>
              <div>One stop word per line</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

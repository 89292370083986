import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {IDocumentCollection} from '../types/document.collection';
import {HttpClient} from '@angular/common/http';
import {UBQ} from '../app-config';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DocumentCollectionService extends BaseService<IDocumentCollection> {

  constructor(public _http: HttpClient, public ubq: UBQ, public router: Router) {
    super(_http, ubq, router);
    this.init('docset');
  }

}

import {Component, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-feed-changelog-card',
  templateUrl: './changelog-card.component.html',
  styleUrls: ['./changelog-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChangelogCardComponent {

  constructor() { }

}
import {Injectable, Pipe, PipeTransform} from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'sortByProp',
  pure: false
})
@Injectable()
export class SortByProp implements PipeTransform {
  transform(items: any, args: any): any[] {
    if (items && args instanceof Array) {
      if (args[1] && args[1] === 'Descending') {
        args[1] = 'desc';
      }
      items = _.orderBy(items, [args[0]], [args[1] || 'asc']);
      return items;
    } else if (items) {
      return items.sort((a: any, b: any) => parseInt(a[args]) - parseInt(b[args]));
    } else {
      return [];
    }
  }
}

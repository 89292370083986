import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-eye-toggle',
  templateUrl: './eye-toggle.component.html',
  styleUrls: ['./eye-toggle.component.scss']
})
export class EyeToggleComponent implements OnInit {

  @Input() isEyeOpen: boolean;
  @Input() eyeClosedTitle: string;
  @Input() eyeOpenTitle: string;

  constructor() { }

  ngOnInit() {
  }

}

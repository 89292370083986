import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-gradient-progress-bar',
  template: `
    <div class="w-100 h-100 p-bar" [class]="{roundedEdges: roundedEdges}" [ngStyle]="backgroundStyle">
      <div role="progressbar" class="progress-bar progress-bar-animated" [class]="{'bg-info': progressBarColorType === 'info', 'bg-model': progressBarColorType === 'model'}" [ngStyle]="{'clip-path': 'inset(0 ' + (100 - value) + '% 0 0 ' + (roundedEdges ? 'round 5px' : '' ) + ')'}"></div>
    </div>
  `,
  styleUrls: ['./gradient-progress-bar.scss']
})
export class GradientProgressBarComponent {

  @Input() value: number = 0; // 0..100
  @Input() roundedEdges = true;

  @Input() backgroundStyle: any;
  @Input() progressBarColorType: any = 'info';

}
<section id="createLense">
  <div class="row">
    <div class="modal-header col-12">
      <div class="modal-title" (click)="cheat()">
        CREATE NEW MODEL
      </div>
    </div>
  </div>
  <div class="row">
    <div class="modal-body col-12">
      <div id="create" class="po-form">
        <div class="row">
          <div class="form-group col-9">
            <div ngbDropdown class="modal-dropdown">
              <button class="btn" id="dropdownBasic1"
                      [ngClass]="{isEmpty: dto.source == null }"
                      ngbDropdownToggle>
                {{ dataDropDownText }}
                <app-down-arrow></app-down-arrow>
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1" role="menu" class="scrollItems">
                <button class="dropdown-item" *ngFor="let source of sources$ | async| sortByProp: ['id', 'Descending']"
                        (click)="setSource(source)">{{ source.givenName || source.originalname }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Stop-Words -->
        <div class="row">
          <div class="form-group col-9">
            <div class="row">
              <div class="col-12">
                <div ngbDropdown class="modal-dropdown">
                  <button class="btn isEmpty"
                          id="dropdownBasic3"
                          ngbDropdownToggle>
                    {{ stopWordsDropDownText }}
                    <app-down-arrow></app-down-arrow>
                  </button>
                  <div ngbDropdownMenu aria-labelledby="selectStopWords" role="menu" class="swDDMenu scrollItems">
                    <div class="swDDGrid gridHeader">
                      <div>List Name</div>
                      <div>Created By</div>
                      <div>Created At</div>
                    </div>
                    
                    <div *ngFor="let stopWords of globalSW.elements | async | sortByProp: ['id', 'Descending']"
                        class="dropdown-item swDDGrid" [ngClass]="{disabled: isStopWordsSelected(stopWords)}"
                        (click)="stopWordLists.push(stopWords)">
                      <div>{{stopWords.name}}</div>
                      <div>{{stopWords.createdBy}}</div>
                      <div>{{stopWords.createdAt | date:'medium'}}</div>
                    </div>
                    <div class="newLens">
                      <a routerLink="/platform/stopwords" class="btn btn-link">Create a new Global Stop Word List -></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swListBox">
              <div *ngIf="!!stopWordLists.length" class="swLGrid gridHeader">
                <div>List Name</div>
                <div>Created By</div>
                <div>Created At</div>
                <div></div>
              </div>
              <div *ngFor="let sw of stopWordLists" class="swLGrid swItem">
                <div>{{sw.name}}</div>
                <div>{{sw.createdBy}}</div>
                <div>{{sw.createdAt | date:'medium'}}</div>
                <div class="ms-auto">
                  <div class="iconBtn remove" (click)="removeStopWords(sw, $event)"><fa-icon [icon]="['fal', 'times-circle']"></fa-icon></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Taxonomy -->
        <div class="row">
          <div class="form-group col-9">
            <div class="row ms-0 position-relative">
              <div class="p-0 box-border position-relative">
                <button class="btn btn-circle btn-primary plus-btn" (click)="open(taxonomyModal)" title="Add Taxonomy">
                  <ng-container *ngTemplateOutlet="plusButton"></ng-container>
                </button>
              </div>
              <div class="ps-0 modal-right">
                <div ngbDropdown class="modal-dropdown">
                  <button class="btn"
                          id="dropdownBasic2"
                          [ngClass]="{isEmpty: dtoTaxonomy == null }"
                          ngbDropdownToggle>
                    {{ taxonomyDropDownText }}
                    <app-down-arrow></app-down-arrow>
                  </button>

                  <div ngbDropdownMenu aria-labelledby="selectTaxonomy" role="menu" class="scrollItems">
                    <button class="dropdown-item none-item" (click)="setTaxonomy(null)">- None - </button>
                    <button class="dropdown-item"
                            *ngFor="let taxonomy of taxonomies$ | async| sortByProp: ['id', 'Descending']"
                            (click)="setTaxonomy(taxonomy)">{{ taxonomy.name }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-9">
            <div class="input-group">
              <input type="text" id="lens-name" name="lens-name" placeholder="Name Your Model" [value]="dto.name"
                     (input)="onNameChange($event)" class="form-control inherit-height" maxlength="35">
              <span *ngIf="dupName" class="name-error">Model name already exists!</span>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-9 mx-auto">
          <div class="row justify-content-between">
            <div class="col-5 mt-3">
              <app-box-selector class="mb-5" [title]="'Topics'" (emitter)="setTopics($event)"
                                [selected]="dto.numberOfTopics"
                                [options]="numberOfTopics"></app-box-selector>
            </div>
            <div class="col-5 mt-3">
              <app-box-selector class="mb-5" [title]="'Iterations'" (emitter)="setIterations($event)"
                                [selected]="dto.numIteration"
                                [options]="numberOfIterations"></app-box-selector>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 text-center">
          <button type="submit" class="btn btn-primary mt-2"
                  (click)="onSubmit()"
                  [disabled]="!isOkToGo()">Begin Modeling
          </button>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #taxonomyModal>
  <app-taxonomy-input (onClose)="doTaxonomyComplete($event)"></app-taxonomy-input>
</ng-template>

<ng-template #stopWordsModal>
  <app-stop-words-input (onUploadComplete)="doStopWordsComplete($event)"></app-stop-words-input>
</ng-template>

<ng-template #plusButton>
  <svg class="svg-plus" width="20px" height="20px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs></defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Training-Data" transform="translate(-39.000000, -85.000000)" fill="#FFFFFF" fill-rule="nonzero" stroke="#FFFFFF" stroke-width="0.5">
        <g id="secondary-nav" transform="translate(0.000000, 64.000000)">
          <g id="add-data-button" transform="translate(30.000000, 12.000000)">
            <path d="M12.7978055,27.2022917 C12.8741953,27.2786821 12.9744815,27.3170732 13.0747677,27.3170732 C13.1750539,27.3170732 13.2753401,27.2786821 13.35173,27.2022917 L20,20.5539772 L26.64827,27.2022917 C26.7246599,27.2786821 26.8249461,27.3170732 26.9252323,27.3170732 C27.0255185,27.3170732 27.1258047,27.2786821 27.2021945,27.2022917 C27.355366,27.0491192 27.355366,26.801536 27.2021945,26.6483634 L20.5539246,20.000049 L27.2021945,13.3517345 C27.355366,13.198562 27.355366,12.9509788 27.2021945,12.7978062 C27.049023,12.6446337 26.8014415,12.6446337 26.64827,12.7978062 L20,19.4461207 L13.35173,12.7978062 C13.1985585,12.6446337 12.950977,12.6446337 12.7978055,12.7978062 C12.644634,12.9509788 12.644634,13.198562 12.7978055,13.3517345 L19.4460754,20.000049 L12.7978055,26.6483634 C12.6450257,26.8011442 12.6450257,27.0491192 12.7978055,27.2022917 Z" id="add" transform="translate(20.000000, 20.000000) rotate(45.000000) translate(-20.000000, -20.000000) "></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</ng-template>

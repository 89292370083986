import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges
} from '@angular/core';
import {DocumentViewStateService, TextFormat} from './document-view-state.service';
import {Subscription} from 'rxjs';
import * as tokenizer from 'sbd';

interface TextRun {
  text: string;
  highlight: boolean;
}

@Component({
  selector: 'app-document-topic-text',
  templateUrl: './document-topic-text.component.html',
  styleUrls: ['./document-topic-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentTopicTextComponent implements OnChanges, OnDestroy {

  @Input() text: string;
  @Input() index = 1; // zero will be processed synchronously. Greater indices will be processed in order asynchronously
  @Input() topicTerms: string[];
  @Input() phrases: string[];
  @Input() highlightColor: string;

  sbdCache: TextRun[][];
  content: TextRun[][] = [[]];

  subscription: Subscription;

  TextFormat = TextFormat;

  pending: any;

  constructor(public viewService: DocumentViewStateService, private cd: ChangeDetectorRef) {
    this.prepareContent = this.prepareContent.bind(this);
    this.subscription = viewService.textFormat$.subscribe(() => { this.prepareContent(); cd.markForCheck(); });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.sbdCache = null;
    this.content = null;
    if (this.index === 0) {
      if (this.pending) {
        clearTimeout(this.pending);
      }
      this.prepareContent();
    } else {
      if (!this.pending) {
        this.pending = setTimeout(() => this.prepareContent(), this.index * 10);
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private prepareContent() {
    this.pending = null;
    if (!this.text || !this.topicTerms) {
      return;
    }

    if (!this.sbdCache) {
      this.sbdCache = tokenizer.sentences(this.text).map(t => this.highlight(t));
    }
    this.updateContent();
    this.cd.markForCheck();
  }

  private updateContent() {
    if (this.viewService.textFormat === TextFormat.FILTERED) {
      this.content = this.sbdCache.filter(runs => runs.find(run => run.highlight));
    } else {
      this.content = this.sbdCache;
    }
  }

  private highlight(text: string): TextRun[] {
    const words = this.phrases.concat(this.topicTerms).map(w => w.toLowerCase().trim()).filter(w => w.length);
    const joined = words.map(w => w.replace(/[#-.]|[[-^]|[?|{}]/g, '\\$&')).join('|');
    const re = new RegExp('\\b(' + joined + ')\\b', 'gi');
    const splits = text.split(re);
    return splits.filter(s => !!s).map(t => ({text: t, highlight: words.includes(t.toLowerCase())}));
  }
}

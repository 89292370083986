<div class="aTabContainer">
  <div class="aTab" *ngFor="let tab of tabs"
       [ngClass]="{selected: tab.selected, roundTop: roundedOn == 'top', roundBottom: roundedOn == 'bottom',
        highLightWord: hightlight == 'word', highLightBox: hightlight =='box', fontLarge: fontSize == 'lg'}"
       [style.width.%]="(1 / tabs.length) * 100"
       (click)="onMouseCLicked(tab)">
    <span>{{ tab.text}} </span>
    <span *ngIf="hasSubtext(tab)">( {{ tab.subtext}} )</span>
  </div>
</div>

import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  FormGroupDirective,
  Validators
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { BehaviorSubject, fromEvent, Observable, of, Subscription } from "rxjs";
import { catchError, filter, map, switchMap, take, tap } from "rxjs/operators";
import { AnnotateService } from "../../services-http/annotate.service";
import { LensService } from "../../services-http/lens.service";
import { SourceService } from "../../services-http/source.service";
import { ILens } from "../../types/lens";
import { ISourceFile } from "../../types/source";

@Component({
  selector: "app-create-annotate",
  templateUrl: "./create-annotate.component.html",
  styleUrls: ["./create-annotate.component.scss"],
  providers: [SourceService],
})
export class CreateAnnotateComponent implements OnInit, OnDestroy {
  @Input() modalRef: NgbModalRef;

  public sources$: Observable<ISourceFile[]>;
  public lenses$: Observable<ILens[]>;
  public allUsers$ = new BehaviorSubject<boolean>(false);

  public sourceDropDownText: string = "Select data for annotation";
  public lensDropDownText: string = "Select a fingerprint model";

  subscriptions: Subscription[] = [];

  @ViewChild("anForm")
  form: FormGroupDirective;
  @ViewChild("anFormElement", { static: true })
  formEle: ElementRef;

  public annotateFormGroup = new UntypedFormGroup({
    lens: new UntypedFormControl(null, [Validators.required]),
    source: new UntypedFormControl(null, [Validators.required]),
  }) as AnnotateFormGroup;

  constructor(
    private router: Router,
    public annotateService: AnnotateService,
    public lenseService: LensService,
    private sourceService: SourceService,
    private route: ActivatedRoute
  ) { }

  onClose() {
    this.modalRef.close();
  }

  setSource(source: ISourceFile): void {
    const name: string = source?.givenName
      ? source.givenName
      : source.originalname;
    this.sourceDropDownText = `Unlabeled Data: ${name}`;
    this.annotateFormGroup.controls.source.setValue(source);
  }

  setLens(lens: ILens): void {
    this.lensDropDownText = lens.name;
    this.annotateFormGroup.controls.lens.setValue(lens);
  }

  setAllUsers(all: boolean) {
    this.allUsers$.next(all);
  }

  private resetForm(e: any) {
    if (e instanceof Error) {
      this.form.resetForm(this.annotateFormGroup.value);
      return;
    }

    this.modalRef.close();
  }

  ngOnInit(): void {
    this.sources$ = this.allUsers$.pipe(
      switchMap((all) => this.sourceService.loadAllSync(all).pipe(
        map(x => x.filter(y => !y.isSoftDeleted))
      ))
    );

    this.lenses$ = this.allUsers$.pipe(
      switchMap((all) => this.lenseService.getMiniListLeafs(all).pipe(
        map(lenses => lenses.filter((lens) => lens.isFinished))
      ))
    );

    const submitSub = fromEvent(this.formEle.nativeElement, "submit")
      .pipe(
        switchMap((all) => {
          return this.annotateService
            .doAnnotate(
              this.annotateFormGroup.controls.lens.value.id,
              this.annotateFormGroup.controls.source.value.id
            )
            .pipe(
              catchError((err) => {
                console.error(err);
                const wrappedErr = new Error(err.message) as any;
                wrappedErr.cause = err;
                return of(wrappedErr);
              })
            );
        })
      )
      .subscribe({
        next: this.resetForm.bind(this),
        complete: this.resetForm.bind(this),
        error: this.resetForm.bind(this),
      });

    this.subscriptions.push(submitSub);

    this.route.queryParams.pipe(take(1)).subscribe({
      next: params => {
        if (params['source']) {
          this.allUsers$.next(true); // ensure all available data sets are available
          this.sources$.pipe(
            map(sources => sources.find(d => d.id === +params['source'])),
            filter(data => !!data),
            take(1)
          ).subscribe({
            next: data => this.setSource(data)
          });
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((x) => x.unsubscribe());
    this.subscriptions = [];
  }
}

interface AnnotateFormItem {
  source: ISourceFile;
  lens: ILens;
}
type SourceControls = { [key in keyof AnnotateFormItem]: AbstractControl };
type AnnotateFormGroup = UntypedFormGroup & {
  value: AnnotateFormItem;
  controls: SourceControls;
};
import {throwError as observableThrowError, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ILens, LensActions} from '../types/lens';
import {IModifierModel, isNewCustomTopic, IModifier, getLookupId} from '../types/modifier';
import {UBQ} from '../app-config';
import {BaseService} from './base.service';
import {Router} from '@angular/router';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ISourceFile} from '../types/source';
import {catchError, map, tap} from 'rxjs/operators';
import {ModelService} from './models.service';
import {ITermWeight} from '../types/model';
import { ModelTagsService } from '../model-tags/model-tag-modal/model-tag-modal.component';

@Injectable()
export class LensService extends BaseService<ILens> implements ModelTagsService {

  // public onStopwordChange: Subject<IStopWords[]> = new Subject<IStopWords[]>();

  constructor(public _http: HttpClient, public ubq: UBQ, public router: Router, public modelService: ModelService) {
    super(_http, ubq, router);
    this.init('lens');
  }

  async DEV_TOOL_REPUBLISH(id: number): Promise<any> {
      return this._http.get(`${this.baseURL}/${this.endpoint}/${id}/ptf`).toPromise();
    }

  setTags(lensId: number, tags: string[]) {
    return this._http.put(`${this.baseURL}/${this.endpoint}/${lensId}/tags`, {tags}).pipe(
      tap(() => {
        // update stored values
        const items: any[] = this._elementsIO.getValue();
        // @ts-ignore
        if (items) {
          const match: any = items.find(val => val.id === lensId);
          if (!!match) {
            match.tags = tags;
            this._elementsIO.next(items.slice());
            this._elementIO.next(match);
          }
        }
      })
    ).toPromise();
  }

  deleteStopwords(lensId: number, eid: number): Observable<ILens> {
    return this._http.post(`${this.baseURL}/${this.endpoint}/${lensId}/deletestopwords/${eid}`, {})
      .pipe(
        map(response => this.serializeOne(response)),
        // tap((aLens: ILens) => this.onStopwordChange.next(aLens.stopWordLists))
      );
  }

  addStopwords(lensId: number, gid: number): Observable<ILens> {
    return this._http.post(`${this.baseURL}/${this.endpoint}/${lensId}/addstopwords/${gid}`, {})
      .pipe(
        map(response => this.serializeOne(response)),
        // tap((aLens: ILens) => this.onStopwordChange.next(aLens.stopWordLists))
      );
  }

  applyStopwords(lensId: number, newName: string): Observable<ILens> {
    return this._http.post(`${this.baseURL}/${this.endpoint}/${lensId}/applystopwords`, {newName: newName})
      .pipe(
        map(response => this.serializeOne(response)),
        // tap((aLens: ILens) => this.onStopwordChange.next(aLens.stopWordLists))
      );
  }

  getById(id: number): Observable<ILens> {
    return this._http.get(`${this.baseURL}/${this.endpoint}/${id}`).pipe(
      map(response => this.serializeOne(response)));
  }

  getAncestors(id: number): Observable<ILens[]> {
    return this._http.get(`${this.baseURL}/${this.endpoint}/${id}/ancestors`).pipe(
      map(response => this.serializeMany(response)));
  }

  getAllNames(): Observable<object> {
    return this._http.get(`${this.baseURL}/${this.endpoint}/names`);
  }

  getAllTags(): Observable<string[]> {
    return this._http.get(`${this.baseURL}/${this.endpoint}/tags`).pipe(
      map((obj: {tags: string[]}) => obj.tags)
    );
  }

  async setStopwordsViewed(id: number): Promise<any> {
    return this._http.post(`${this.baseURL}/${this.endpoint}/${id}/viewautostopwords`, {}).toPromise();
  }

  getLeafs(all: boolean = false): Observable<ILens[]> {
    let params = new HttpParams();
    if (all) {
      params = params.set('allUsers', 'true');
    }
    return this._http.get(`${this.baseURL}/${this.endpoint}/leafs`, {params}).pipe(
      map(response => this.serializeMany(response)));
  }

  getMiniListLeafs(all: boolean = false): Observable<ILens[]> {
    let params = new HttpParams();
    if (all) {
      params = params.set('allUsers', 'true');
    }
    return this._http.get(`${this.baseURL}/${this.endpoint}/leafs/mini`, {params}).pipe(
      map(response => this.serializeMany(response)));
  }

  clone(id: number, newName: string): Observable<ILens> {
    return this._http.post(`${this.baseURL}/${this.endpoint}/${id}/clone`, {name: newName}).pipe(
      map(response => this.serializeOne(response)));
  }

  reprocess(id: number, action: LensActions): Observable<ILens> {
    const params = new HttpParams();
    params.set('action', action);
    return this._http.get(`${this.baseURL}/${this.endpoint}/${id}/reprocess/${action}`).pipe(
      map(response => this.serializeOne(response)));
  }

  download(lens: ILens): void {
    const url: string = this.ubq.apiEndpoint + `/lens/download/${lens.id}`;
    this._http.get(url, {responseType: 'blob'}).pipe(
      map((response: any) => {
        // gross we have to create a dyn element to change a file name
        const a: any = document.createElement('a'),
          fileURL = URL.createObjectURL(response._body);
        a.style = 'display:none';
        a.href = fileURL;
        a.download = 'pienso.pm';
        window.document.body.appendChild(a);
        a.click();
        window.document.body.removeChild(a);
        URL.revokeObjectURL(fileURL);
      }),
      catchError(error => observableThrowError(error)))
      .subscribe({next: () => {}, error: () => {}});
  }

  post(aLense: ILens): Observable<ILens> {
    return this._http.post(`${this.baseURL}/lens`, aLense).pipe(
      map(response => this.serializeOne(response)),
      catchError(error => observableThrowError(error)));
  }

  publish(lensId: number, action: LensActions, callback: Function): void {
    this._http.post(`${this.baseURL}/lens/${lensId}/process`, {'action': action}).pipe(
      map(response => this.serializeOne(response)),
      catchError(error => observableThrowError(error)))
      .subscribe((response: Object) => callback(response),
        (error: Error) => console.log('Publish Action: Error', error));
  }

  async merge(lensId: number, sourceTopicId: number, targetTopicId: number, callback?: Function): Promise<ILens> {
    this.isPayloadLoading = true;
    const url: string = `${this.baseURL}/lens/${lensId}/merge/${sourceTopicId}/${targetTopicId}`;
    return new Promise<ILens>((resolve, reject) => {
      this._http.post(url, null).pipe(
        map(response => this.serializeOne(response)),
        // catchError(error => observableThrowError(error))
        )
        .subscribe({
          next: (response: ILens) => {
            if (callback) {
              callback(response);
            }
            this.isPayloadLoading = false;
            resolve(response);
          },
          error: (error: Error) => {
            console.log('Merge Topic Error:', error);
            reject(error);
          }
        });
    });
  }

  /** Rename returns a 200 response on success and will throw on error */
  async rename(lensId: number, name: string): Promise<any> {
    const url = `${this.baseURL}/lens/${lensId}/rename/`;
    return this._http.post(url, {name}).toPromise();
  }

  /** Returns a 200 response on success and will throw on error */
  async renameModifier(lensId: number, modifierId: number, name: string): Promise<any> {
    const url = `${this.baseURL}/lens/${lensId}/modifier/${modifierId}/rename/`;
    return this._http.put(url, {name}).toPromise();
  }

  async duplicateModifier(lensId: number, modifierId: number): Promise<ILens> {
    const url = `${this.baseURL}/lens/${lensId}/modifier/${modifierId}/duplicate/`;
    const response = await this._http.get<IModifier>(url).toPromise();
    return this.serializeOne(response);
  }

  async setModifierFilterTerms(lensId: number, modifierId: number, filters: ITermWeight[]): Promise<any> {
    const url = `${this.baseURL}/lens/${lensId}/modifier/${modifierId}/filterterms/`;
    return this._http.put(url, {filters}).toPromise();
  }

  async setModifierFilterPhrases(lensId: number, modifierId: number, phrases: string[]): Promise<any> {
    const url = `${this.baseURL}/lens/${lensId}/modifier/${modifierId}/filterphrases/`;
    return this._http.put(url, {phrases}).toPromise();
  }

  async setModifierCustomFilterPhrases(lensId: number, modifierId: number, phrases: string[]): Promise<any> {
    const url = `${this.baseURL}/lens/${lensId}/modifier/${modifierId}/customfilterphrases/`;
    return this._http.put(url, {phrases}).toPromise();
  }

  getModifierComments(lensId: number, modifierId: number, callback: Function): void {
    const url: string = `${this.baseURL}/lens/${lensId}/modifier/${modifierId}/`;
    this._http.get(url).pipe(
      catchError(error => observableThrowError(error))
    ).subscribe((response: IModifier) => callback(response),
        (error: Error) => console.log('Get Modifer Error:', error));
  }

  onPostHandlerError(error: Error) {
    console.log('POST LENS: Error', error);
  }

  serializeOne(response: any): ILens {
    const lens: ILens = response as ILens;
    // lens.modifiers = plainToInstance(DTOModifier, lens.modifiers);

    // hydrate if the modifiers exists
    if (lens.modifiers && lens.model && lens.model.isCompleted) {
      lens.modifiers.forEach(mod => {
        this.serializeModifier(lens, mod);
      });
    }

    lens.source = lens.source as ISourceFile;
    if (lens.model) {
      lens.model = this.modelService.serializeOne(lens.model);
    }

    return lens;
  }

  serializeModifier(lens: ILens, mod: IModifier) {
    const lookupID: number = getLookupId(mod);

    const isNewCustom = isNewCustomTopic(mod, lens);

    if (isNewCustom) {
      lens.model.suggestionMap[mod.topicId] = {
        suggestedLabel: mod.customTopic.seed,
        suggestedSubLabel: null,
        suggestedMerge: -1,
        suggestedUnknown: false,
        suggestUnclear: false
      };
    }

    const suggestedFroms: number[] = Object.keys(lens.model.suggestionMap).map(idx => ({idx, sug: lens.model.suggestionMap[idx]}))
      .filter(payload => payload.sug.suggestedMerge === mod.topicId).map(payload => parseInt(payload.idx));

    const modifierModel: IModifierModel = {
      suggestedMergedFrom: suggestedFroms,
      documentCount: isNewCustom ? 0 : lens.model.documentCountByTopic[lookupID],
      suggestMap: lens.model.suggestionMap[lookupID],
      topicTopDocumentIds: isNewCustom ? [] : lens.model.topDocumentIds[lookupID].map(d => +d), // TODO: move to topicData call
      topicTerms: isNewCustom ? [] : lens.model.topics[lookupID].filter(w => !!w), // TODO: rely on topicData call
      topicPhrases: isNewCustom ? [] : lens.model.topPhrases[lookupID].filter(w => !!w), // TODO: rely on topicData call
      topicWeights: isNewCustom ? 0 : lens.model.topicWeights[lookupID],
      topTermWeights: lens.modelDisplayTermWeights[lookupID] || []
    };
    mod.modifierModel = modifierModel;
  }

  serializeMany(response: any): Array<any> {
    const lenses: ILens[] = response;
    lenses.forEach(lens => this.serializeOne(lens));
    return lenses;
  }

  downloadTopics(lensId: number): Observable<string>  {
    const url: string = this.ubq.apiEndpoint + `/lens/${lensId}/downloadTopics`;
    return this.downloadFileToDisk(url);
  }
}

<div mat-tab-nav-bar [tabPanel]="tabPanel" class="lens-tab">
  <a mat-tab-link routerLink="./" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}" class="item">
    <span>Models</span>
  </a>
  <a mat-tab-link routerLink="stopwords" routerLinkActive="active-link" class="item">
    <span>Stop Words</span>
  </a>
</div>
<mat-tab-nav-panel #tabPanel>
  <router-outlet></router-outlet>
</mat-tab-nav-panel>
  
import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {distinctUntilChanged, map, takeUntil} from 'rxjs/operators';
import {getRenderLabel, IModifier} from '../../types/modifier';

@Injectable()
export class DocumentTopicDistributionService implements OnDestroy {

  private _modifiers = new BehaviorSubject<IModifier[]>(null);

  private destroy$ = new Subject<boolean>();

  public readonly distributionLabels$ = this._modifiers.pipe(
    takeUntil(this.destroy$),
    map(modifiers => {
      return modifiers.map(modifier => {
        const topicId = modifier.topicId;
        const labels = modifiers.filter(mod => mod.duplicateOfTopicId === topicId)
          .map(mod => getRenderLabel(mod));
        labels.push(getRenderLabel(modifiers.find(mod => mod.topicId === topicId)));
        return {
          topicId,
          label: labels.join(' & ')
        };
      });
    }),
    map(arr => {
      const newArr = new Array<string>(arr.length);
      arr.forEach(v => newArr[v.topicId] = v.label);
      return newArr;
    }),
    distinctUntilChanged((a, b) => a.length === b.length && a.every((d, i) => b[i] === d))
  );

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  public set modifiers(modifiers: IModifier[]) { this._modifiers.next(modifiers); }

}

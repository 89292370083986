import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ModifierReviewComponent} from './modifier-review.component';
import {ModelProgressChartComponent} from './model-progress-chart/model-progress-chart.component';
import {RouterModule} from '@angular/router';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterModule
  ],
  declarations: [
    ModelProgressChartComponent,
    ModifierReviewComponent
  ],
  exports: [
    ModifierReviewComponent
  ]
})
export class ModifierReviewModule {}

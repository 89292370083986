import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

export enum ViewState {
  LIST = 1,
  FEED = 2
}

export enum TextFormat {
  PLAIN,
  SENTENCES,
  FILTERED
}

@Injectable()
export class DocumentViewStateService implements OnDestroy {

  private _viewState$ = new BehaviorSubject<ViewState>(ViewState.LIST);
  private _textFormat$ = new BehaviorSubject<TextFormat>(TextFormat.PLAIN);
  private _commenting$ = new BehaviorSubject<boolean>(false);

  private destroy$ = new Subject<boolean>();

  constructor() {
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  public get viewState$() { return this._viewState$.asObservable(); }

  public get viewState() { return this._viewState$.getValue(); }
  public set viewState(v: ViewState) {
    if (v < ViewState.LIST) {
      v = ViewState.LIST; // handle old data that's set to WordCloud mode
    }
    this._viewState$.next(v);
  }

  public get textFormat$() { return this._textFormat$.asObservable(); }
  public get textFormat() { return this._textFormat$.getValue(); }
  public set textFormat(tf: TextFormat) { this._textFormat$.next(tf); }

  public get commenting$() { return this._commenting$.asObservable(); }
  public get commenting() { return this._commenting$.getValue(); }
  public set commenting(c: boolean) { this._commenting$.next(c); }

  public topicScrollPos = 0;

  public updateTopicScrollPos() {
    this.topicScrollPos = document.getElementById('topicBoxes').scrollTop;
    // console.log('dvss scroll pos: ' + this.topicScrollPos);
  }

  public setTopicScrollPos() {
    const boxes = document.getElementById('topicBoxes');
    if (boxes) {
      boxes.scrollTop = this.topicScrollPos;
    }
    // console.log('set scroll pos to ' + this.topicScrollPos);
  }

}

import {IModel} from './model';
import {ISourceFile} from './source';
import {ITaxonomy} from './taxonomy';
import {IStopWords} from './stop-words';
import {IStopWordsRoot} from './stop-words-root';
import {IStopWordsGlobal} from './stop-words-global';
import {IBase} from './base';
import {ITermWeight} from './model';
import {IModifier} from './modifier';
import {ITaggedModel} from './tags';

export interface ILens extends IBase, ITaggedModel {
    model: IModel;
    modelId: number;
    source: ISourceFile;
    sourceId: number;
    taxonomy: ITaxonomy;
    taxonomyId: number;
    modifiers: IModifier[];
    threshold: number;
    name: string;
    createdBy: string;
    description: string;
    tags: string[];
    isFinished: boolean;
    isSoftDeleted: boolean;
    numIteration: number;
    numberOfTopics: number;
    stage: number;
    contentType: number;
    uid: string;
    stopWordLists: IStopWords[];
    rootStopWords: IStopWordsRoot[];
    hasViewedGeneratedStopwords: boolean;
    nextGlobalStopwords: IStopWordsGlobal[];
    modelJobState: LensModelState;
    modelDisplayTermWeights: ITermWeight[][];
}

export type LensModelState =
    'FATAL' |
    'QUEUED' |
    'PENDING' |
    'TIMEDOUT' |
    'FAILED' |
    'FINISHED' |
    'READY';

export enum LensActions {
    APPLY = 'apply',
    FIND_TOPICS = 'findTopics',
    FINISH = 'finishModel'
}

export interface ITopicData {
    topicId: number;
    name: string;
    filtered: ITermWeight[];
    isUnclear: boolean;
    isMerged: boolean;
    filteredPhrases: string[];
    topTermWeights: ITermWeight[];
    topicPhrases: string[];
    topDocumentIds: number[];
}
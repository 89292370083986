import {Injectable} from '@angular/core';
import {EXAMPLE_JSON, EXAMPLE_TSV} from './examples';
import {DomSanitizer} from '@angular/platform-browser';
import * as _ from 'lodash';

@Injectable()
export class DownloadFileExampleService {

  constructor(private sanitizer: DomSanitizer) {}

  createExampleJsonHref() {
    const exampleJsonStr: any = JSON.stringify(EXAMPLE_JSON);
    return this.sanitizer.bypassSecurityTrustUrl('data:text/json;charset=UTF-8,' +
      encodeURIComponent(exampleJsonStr));
  }

  createExampleTsvFile() {
    let values = '';
    _.each(EXAMPLE_TSV, row => {
      values += row.join('\t') + '\n';
    });
    window.location.href = 'data:text/tab-separated-values,' + encodeURIComponent(values);
  }
}


import * as _ from 'lodash';
import { query, QueryOutput } from "rx-query";
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { RxQueryNames } from '../../../app/utils/query/RxQueryNames';
import { SourceService } from '../../services-http/source.service';
import { ListFilterService } from '../../shared/top-filter/list-filter.service';
import { ITrainingDataItem } from '../../types/trainingDataItem';
import { SELECT_FILTER_OPTIONS } from './training-data-list.constants';
import { DataSource } from '@angular/cdk/table';
import { IViewBy } from '../../../app/shared/top-filter/iviewby.interface';
import { CollectionViewer } from '@angular/cdk/collections';
import { MatSort, Sort } from '@angular/material/sort';
import { sortData } from '../../../app/utils/material-data-sorter';

export class TrainingListDataSource implements DataSource<ITrainingDataItem> {
  selectOptions = _.cloneDeep(SELECT_FILTER_OPTIONS);

  // parameters
  public sortCriteria = new BehaviorSubject<Sort>({
    active: 'createdAt',
    direction: 'desc'
  });
  private allUsers$ = new BehaviorSubject<boolean>(false);
  private filterText$ = new BehaviorSubject<string>('');
  private view$ = new BehaviorSubject<any[] | IViewBy>(this.selectOptions);
  private labeledData$ = new BehaviorSubject<boolean>(false);
  private searchInFields = ["name", "createdBy"];
  private queryParams = combineLatest([
    this.allUsers$,
    this.labeledData$,
    this.filterText$,
    this.view$,
    this.sortCriteria
  ]);

  constructor(
    private listFilterService: ListFilterService,
    private sourceService: SourceService,
  ) { }

  sources$: Observable<QueryOutput<ITrainingDataItem[]>> = query(
    RxQueryNames.trainingDatas,
    this.queryParams,
    ([allUsers, onlyLabeled, filterText, view, sortCriteria]) => {
      return this.sourceService.getTrainingDataItems({
        allUsers,
        onlyLabeled
      }).pipe(
        map(items => {
          return this.listFilterService.applyFilter(this.searchInFields)([items, filterText, view])
        }),
        map(items => {
          return sortData(items, sortCriteria)
        })
      );
    }, {
    retries: 1000,
    retryDelay: 5000,
    refetchInterval: 5000,
    keepPreviousData: true
  });

  connect(collectionViewer: CollectionViewer): Observable<readonly ITrainingDataItem[]> {
    return this.sources$.pipe(
      map(result => result.data),
    )
  }

  disconnect(collectionViewer: CollectionViewer): void {

  }

  onOnlyLabeledChange(onlyLabeled: boolean) {
    this.labeledData$.next(onlyLabeled);
  }

  onAllUsersChanged(value: boolean) {
    this.allUsers$.next(value);
  }

  onFilterTextChanged(value: string) {
    this.filterText$.next(value);
  }

  onViewChanged(value: any) {
    this.view$.next(value)
  }

  onSortDataChanged(value: Sort) {
    this.sortCriteria.next(value);
  }

  get allUsers() { return this.allUsers$.getValue(); }
}
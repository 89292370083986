import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {catchError, map, take} from 'rxjs/operators';
import {BehaviorSubject, Observable, Subject, throwError as observableThrowError} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {UBQ} from '../../app-config';
import {ToastMessage, ToastStatus, UbqToastService} from '../../ubq-toast.service';
import {IStopWords} from '../../types/stop-words';
import {KeycloakService} from 'keycloak-angular';
import {GlobalStopWordService} from '../../services-http/global-stop-word.service';

@Component({
  selector: 'app-stop-words-input',
  templateUrl: './stop-words-upload.component.html',
  styleUrls: ['./stop-words-upload.component.scss']
})
export class StopWordsUploadComponent implements OnInit {

  @Input() dto: IStopWords; // dto to edit; undefined if creating a new one

  public file: File;
  public name: string;
  public isPosting = false;

  @Output() onUploadComplete: EventEmitter<IStopWords> = new EventEmitter<IStopWords>();

  constructor(private service: GlobalStopWordService,
              private http: HttpClient,
              private ubqToast: UbqToastService,
              private kc: KeycloakService,
              private ubq: UBQ) {
    this.onComplete = this.onComplete.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  ngOnInit() {
    if (this.dto) {
      this.name = this.dto.name;
    }
  }

  showAddFile() { return !this.file && (!this.dto || !this.dto.source); }
  filename() { return this.file ? this.file.name : this.dto ? this.dto.name : null; }
  clearFile() {
    this.file = null;
    if (this.dto) {
      this.dto.source = null;
    }
  }
  canSave() { return this.file || (this.dto && this.dto.source); }

  async onSave() {
    this.dto ? this.saveUpdate() : this.saveNew();
  }

  async saveNew() {
    this.isPosting = true;
    const profile = await this.kc.loadUserProfile();
    const words$ = new Subject<string>();
    words$.pipe(take(1)).subscribe(words => {
      const formData = new FormData();
      // formData.append('UBQ-FILE', this.file, this.file.name);
      formData.append('words', words);
      formData.append('createdBy', profile.email);
      formData.append('source', 'file:' + this.file.name);
      if (this.name) {
        formData.append('name', this.name);
      }

      this.http.post(this.ubq.apiEndpoint + '/globalstopwords/upload', formData).pipe(
        catchError(error => observableThrowError(error)))
        .subscribe({
          next: this.onComplete,
          error: this.handleError,
          complete: () => this.isPosting = false
        });
    });

    this.readFile(words$);
  }

  saveUpdate() {
    const words$ = new Subject<string>();
    let list$: Observable<string[]>;
    if (this.file) {
      // the file was replaced. Replace the words in the DTO
      list$ = words$.pipe(
        map(w => w.split(/(?:\r\n|\r|\n|,|\t)/g).map(s => s.trim()).filter(s => s.length > 0))
      );
      this.readFile(words$);
    } else {
      list$ = new BehaviorSubject<string[]>(this.dto.words);
    }
    list$.pipe(
      take(1)
    ).subscribe({
      next: words => {
        this.dto.words = words;
        this.dto.name = this.name;
        if (this.file) {
          this.dto.source = 'file:' + this.file.name;
        }
        this.service.update(this.dto, () => this.onComplete(this.dto));
      },
      error: this.handleError
    });
  }

  dtoFilename() {
    return (this.dto.source && this.dto.source.startsWith('file:')) ? this.dto.source.substring(5) : null;
  }

  private readFile(subject$: Subject<string>) {
    const reader = new FileReader();
    reader.onload = (e) => subject$.next(<string>(<FileReader>e.target).result);
    reader.readAsText(this.file);
  }

  private handleError(error: Error) {
    const msg: ToastMessage = new ToastMessage();
    msg.title = 'Failure';
    msg.message = 'File Upload Failed!';
    msg.status = ToastStatus.Error;
    this.ubqToast.sendMsg(msg);
  }

  private onComplete(src: IStopWords) {
    this.onUploadComplete.emit(src);
  }

}

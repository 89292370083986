import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BaseService} from './base.service';
import {HttpClient} from '@angular/common/http';
import {UBQ} from '../app-config';
import {IComment} from '../types/comment';
import {Router} from '@angular/router';
import {first, map} from 'rxjs/operators';

@Injectable()
export class CommentService extends BaseService<IComment> {
  constructor(public _http: HttpClient, public ubq: UBQ, public router: Router) {
    super(_http, ubq, router);
    this.init('comments');
  }

  public save(comment: IComment, callback: Function) {
    const url = `${this.baseURL}/${this.endpoint}`;

    this._http.post(url, comment).pipe(first())
      .subscribe(payload => callback(payload));
  }

  public delete(id: number, callback: Function) {
    const url = `${this.baseURL}/${this.endpoint}/${id}`;
    this._http.delete(url).pipe(first())
      .subscribe(payload => callback(payload));
  }

  public loadByQuery(modifierIds: string[]): Observable<IComment[]> {
    return this._http.post(`${this.baseURL}/${this.endpoint}/query`, {ids: modifierIds}).pipe(
      map(response => this.serializeMany(response)));
  }

}

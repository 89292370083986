export function compareArrays<T>(a: T[], b: T[], equalityTest?: (a: T, b: T) => boolean) {
  if (!a || !b || a.length !== b.length) {
    return false;
  }
  for (let i = 0; i < a.length; i++) {
    if ((equalityTest && !equalityTest(a[i], b[i])) || a[i] !== b[i]) {
      return false;
    }
  }
  return true;
}

export function arrayComparator<T>(equalityTest?: (a: T, b: T) => boolean) {
  return (a: T[], b: T[]) => compareArrays(a, b, equalityTest);
}

export function objectKeysEqualityTest<T>(a: T, b: T): boolean {
  if (a == null && b == null) {
    return true;
  }
  const aKeys = Object.keys(a);
  if (aKeys.length !== Object.keys(b).length) {
    return false;
  }
  return aKeys.every(key => a[key] === b[key]);
}

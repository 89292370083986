import {NgModule} from '@angular/core';
import {ModelTagModalComponent} from './model-tag-modal/model-tag-modal.component';
import {ModelTagsComponent} from './model-tags.component';
import {NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {MatChipsModule} from '@angular/material/chips';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatFormFieldModule} from '@angular/material/form-field';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatFormFieldModule,
    NgbModalModule
  ],
  declarations: [
    ModelTagModalComponent,
    ModelTagsComponent
  ],
  exports: [
    ModelTagsComponent
  ]
})
export class ModelTagModule {}

import { Component, Input } from '@angular/core';
import { faTag, faTags } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-single-multi-label',
  template: `
    <ng-container *ngIf="isMultilabel != null && !isMultilabel"><fa-icon [icon]="singleLabelIcon"></fa-icon> Single</ng-container>
    <ng-container *ngIf="isMultilabel != null && isMultilabel"><fa-icon [icon]="multiLabelIcon"></fa-icon> Multi</ng-container>
  `
})
export class SingleMultiLabelComponent {
  @Input() isMultilabel: boolean | null = null;

  singleLabelIcon = faTag;
  multiLabelIcon = faTags;
}
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {LensService} from '../services-http/lens.service';
import {ILens} from '../types/lens';
import {filter} from 'rxjs/operators';

@Injectable()
export class CompletionService {
  // TODO: Delete this class if things compile and work!
  //
  // private percentSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  //
  // constructor(private lensService: LensService) {
  //   this.lensService.element.pipe(
  //     filter(lens => lens != null))
  //     .subscribe((lens: ILens) => {
  //       let completeCount = 0;
  //       let percent = 0;
  //
  //       if (lens.model.isCompleted) {
  //         const itemCount: number = Object.keys(lens.model.topics).length;
  //         Object.keys(lens.model.topics).forEach((topicId: string) => {
  //           const match = lens.modifiers.find(mod => mod.topicId === parseInt(topicId));
  //           if (match) {
  //             if (match.label) {
  //               completeCount++;
  //             } else if (match.isUnclear) {
  //               completeCount++;
  //             } else if (match.mergedClusterId !== null && match.mergedClusterId >= 0) {
  //               completeCount++;
  //             }
  //           }
  //         });
  //
  //         percent = completeCount / itemCount;
  //         percent = Math.round(percent * 100);
  //       }
  //
  //       this.percentSubject.next(percent);
  //     });
  // }
  //
  // public get percent(): Observable<number> {
  //   return this.percentSubject.asObservable();
  // }
  //
  // public clear() {
  //   this.percentSubject.next(null);
  // }
}

import {IModelStatus} from '../types/model';
import {ILens} from '../types/lens';

class ModelProcessingStateManager {

  private _loading_state_on_resubmit: any = {};
  private _loading_state_on_finish: any = {};
  private _progress: number = 0;
  private _lastModel: IModelStatus;
  public state: IModelProcessingDisplayStatus;

  constructor() {
    // Model Training Generation Step Progress
    this._loading_state_on_resubmit[ModelProcessingStatusType.PREPROCESSING] = {'startAt': 0, 'percent': .10};
    this._loading_state_on_resubmit[ModelProcessingStatusType.PROCESSING] = {'startAt': 10, 'percent': .70};
    this._loading_state_on_resubmit[ModelProcessingStatusType.DOCUMENTING] = {'startAt': 70, 'percent': .25};
    this._loading_state_on_resubmit[ModelProcessingStatusType.CLASSIFYING] = {'startAt': 95, 'percent': .05};
    this._loading_state_on_resubmit[ModelProcessingStatusType.COMPLETING] = {'startAt': 100, 'percent': 0};

    // Model Training Finish & Classify Progress
    this._loading_state_on_finish[ModelProcessingStatusType.PROCESSING] = {'startAt': 0, 'percent': .10};
    this._loading_state_on_finish[ModelProcessingStatusType.DOCUMENTING] = {'startAt': 10, 'percent': .20};
    this._loading_state_on_finish[ModelProcessingStatusType.CLASSIFYING] = {'startAt': 30, 'percent': .70};
    this._loading_state_on_finish[ModelProcessingStatusType.COMPLETING] = {'startAt': 100, 'percent': 0};
  }

  reset(): void {
    this._progress = 0;
  }

  update(lens: ILens): IModelProcessingDisplayStatus {
    const modelStatus: IModelStatus = lens.model.status;

    // debug output
    if (!this._lastModel || (this._lastModel.status !== modelStatus.status || this._lastModel.completion !== modelStatus.completion) ) {
      this._lastModel = modelStatus;
    }

    const displayInfo = this.calculateDisplay(lens.isFinished, modelStatus.status);
    this.state = {
      ... modelStatus,
      ... displayInfo,
      completionByWhole: this.calculateWholePercent(lens.isFinished, modelStatus.completion, modelStatus.status),
    };
    if (lens.model.isFailed) {
      this.state.status = 'failed';
    }
    return this.state;
  }

  calculateDisplay(isFinishing: boolean, status: string): {actionLabel: string, actionWithStepLabel: string, stepCurrent: number, stepTotal: number} {
    let tag: string = '';
    let fullLabel = '';
    let step: number = 0;
    const steps: number = isFinishing ? 5 : 4;

    switch (status) {

      case ModelProcessingStatusType.QUEUED:
        step = 0;
        tag = `Model added to queue`;
        break;

      case ModelProcessingStatusType.PREPROCESSING:
        step = 1;
        tag = `Preprocessing training data`;
        break;

      case ModelProcessingStatusType.PROCESSING:
        step = 2;
        tag = isFinishing ? 'Finalizing Models' : `Estimating Model`;
        break;

      case ModelProcessingStatusType.DOCUMENTING:
        step = 3;
        tag = `Persisting Document Fingerprints`;
        break;

      case ModelProcessingStatusType.CLASSIFYING:
        step = 4;
        tag = `Estimating Binary Classifiers`;
        break;

      case ModelProcessingStatusType.COMPLETING:
        step = steps;
        tag = `Completing`;
        break;
    }

    if (step) {
      fullLabel = `${tag} &nbsp; (Step ${step} of ${steps})`;
    } else {
      fullLabel = tag;
    }

    return {actionLabel: tag, actionWithStepLabel: fullLabel, stepCurrent: step, stepTotal: steps};
  }

  calculateWholePercent(isFinishing: boolean, inboundPercent: number, status: string): number {
    let calculatedValue: number;
    let lensing: any;

    // Padma has inconsistent / missing responses for status, steps change depending on stage of training
    if (isFinishing) {
      lensing = this._loading_state_on_finish;
    } else {
      lensing = this._loading_state_on_resubmit;
    }

    if (!!lensing[status]) {
      calculatedValue = lensing[status].startAt + (inboundPercent *  lensing[status].percent);

      // never go backwards despite what padma says...
      this._progress = Math.round((this._progress <= calculatedValue) ? calculatedValue : this._progress);
    }

    return this._progress;
  }
}

// each of these can have a value of 0 to 100
// once a new status is posted - it applies any remaining weight from the config
enum ModelProcessingStatusType {
  QUEUED = 'queued',
  PREPROCESSING = 'preprocessing',
  PROCESSING = 'processing',
  DOCUMENTING = 'documents',
  CLASSIFYING = 'classifying',
  COMPLETING = 'completed'
}

interface IModelProcessingDisplayStatus extends IModelStatus {
  actionLabel: string;
  actionWithStepLabel: string;
  stepCurrent: number;
  stepTotal: number;
  completionByWhole: number;  // 'completion' value from IModelStatus is per step
}

export {ModelProcessingStatusType, ModelProcessingStateManager, IModelProcessingDisplayStatus};

import { Component, OnInit } from '@angular/core';
import {filter, map, skip, takeUntil, tap} from 'rxjs/operators';
import {Observable, timer} from 'rxjs';
import {Router, RouterEvent, Event} from '@angular/router';
import {FeedService} from './feed.service';
import {FeedCardConfig, FeedCardType} from '../types/feed';

export interface CardDateGroup {
  date: Date;
  dateKey: string; // used for comparisons
  cards: FeedCardConfig<any>[];
}

@Component({
  selector: 'app-feed',
  templateUrl: './feed.component.html',
  styleUrls: ['./feed.component.scss']
})
export class FeedComponent implements OnInit {

  feedCardType = FeedCardType;

  cardDateGroup$: Observable<CardDateGroup[]>;

  // welcomeConfig: FeedCardConfig<any> = {type: 'WELCOME'}

  isReady = false;

  // private timer;

  constructor(private feedService: FeedService,
              private router: Router) {
  }

  ngOnInit() {
    timer(0, 5000).pipe(
      takeUntil(this.router.events.pipe(
        filter((event: Event | RouterEvent) => event instanceof RouterEvent)
      ))
    ).subscribe(() => this.feedService.refreshFeed());

    this.cardDateGroup$ = this.feedService.cardConfig$.pipe(
      skip(1),
      map(cards => {
        // console.log(cards.length + ' cards');
        const dateKey = (d: Date) => d.toDateString();
        const groups = cards.reduce((acc: CardDateGroup[], card: FeedCardConfig<any>) => {
          const prev = acc.length ? acc[acc.length - 1] : null;
          const prevKey = prev ? prev.dateKey : null;
          const key = dateKey(card.dto.createdAt);
          if (key === prevKey) {
            prev.cards.push(card);
          } else {
            acc.push({date: card.dto.createdAt, dateKey: key, cards: [card]});
          }
          return acc;
        }, []);
        return groups;
      }),
      tap(() => this.isReady = true)
    );
  }

  dateGroupTrackBy(index: number, group: CardDateGroup) {
    return group.dateKey;
  }

  cardTrackBy(index: number, config: FeedCardConfig<any>) {
    return config.type + '|' + (config.dto ? config.dto.id : '__');
  }

}

import {NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';

export const MODAL_CONFIG: NgbModalOptions = {
  windowClass: 'modal-gt',
  size: 'lg',
  centered: true
};

export const MODAL_CONFIG_XS: NgbModalOptions = {
  centered: true,
  backdrop: 'static',
  size: 'sm',
  windowClass: 'modal-xs',
};

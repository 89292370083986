import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {FeedCardConfig, FeedCardType} from '../../types/feed';
import {Router} from '@angular/router';
import {ILens, LensModelState} from '../../types/lens';
import {ModelService} from '../../services-http/models.service';
import {ConfigService} from '../../services-http/config.service';
import {IModelProcessingDisplayStatus, ModelProcessingStateManager} from '../../lens-loader/modelProcessingStateManager';

@Component({
  selector: 'app-feed-lens-card',
  template: `
    <app-feed-card [title]="title" [time]="config?.dto.createdAt" [isError]="isError">
      <ng-container class="feed-card-body">
        <div class="p-feedcard-primary">{{config?.dto.name}}</div>
        <div class="p-feedcard-secondary">
          <span class="p-feedcard-dt">Data Set : </span>{{config?.dto?.source?.givenName || config?.dto?.source?.originalname}}
        </div>
        <div *ngIf="state === 'PENDING' || state === 'QUEUED'" class="p-feedcard-progress" (click)="doModelingWorkspace()">
          <div [innerHTML]="status.actionWithStepLabel"></div>
          <ngb-progressbar
            class="p-bar"
            type="info"
            [striped]='true'
            [value]="status.completionByWhole" [animated]="true">
          </ngb-progressbar>
          <span class="d-inline-block ms-3">
            {{ status.completionByWhole || 0 }} %
          </span>
        </div>
      </ng-container>
      <ng-container class="feed-card-footer">
        <button *ngIf="state === 'READY' || state === 'FAILED'" type="button" class="btn btn-primary"
                [attr.disabled]="(state === 'FAILED' || isRunning()) ? true : null" (click)="gotoLens()">
          {{config.dto.stage === 1 ? 'Train Model' : 'Resume Training'}}
        </button>
        <button *ngIf="state === 'TIMEDOUT'" type="button" class="btn btn-primary" (click)="gotoLens()">
          View Error
        </button>
        <button *ngIf="state === 'FINISHED'" type="button" class="btn btn-primary" (click)="doUnitAnalysis()">
          Create Data Set Analysis
        </button>
        <button *ngIf="state === 'FINISHED' && canDeploy" type="button" class="btn btn-primary" (click)="doDeploy()">
          Deploy Model
        </button>
      </ng-container>
    </app-feed-card>
  `,
  styleUrls: ['feed-card.component.scss']
})
export class LensCardComponent implements OnChanges {

  @Input() config: FeedCardConfig<ILens>;
  private _progress: ModelProcessingStateManager = new ModelProcessingStateManager();

  state: LensModelState;
  status: IModelProcessingDisplayStatus;
  title = 'Model';
  isError = false;
  canDeploy = false;

  constructor(private configService: ConfigService,
              private modelService: ModelService,
              private router: Router) {
    this.configService.getPiensoApps().toPromise().then(apps => {
      this.canDeploy = apps.includes('ANALYSIS');
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const lens = this.config.dto;
    const state = this.state = lens.modelJobState;
    const stage = lens.stage;

    this.isError = state === 'FAILED' || state === 'TIMEDOUT' || state === 'FATAL';

    switch (state) {
      case 'PENDING':
      case 'QUEUED':
        this.status = this._progress.update(lens);
        this.title = stage === 1 ? 'Fingerprint Model Processing: New Model Initiated' : 'Model Processing: Model stage ' + (stage - 1) + ' Initiated';
        break;
      case 'READY':
        this.title = stage === 1 ? 'Fingerprint Model Created' : 'Model Updated';
        break;
      case 'FAILED':
        this.title = 'Fingerprint Model Failed';
        break;
      case 'TIMEDOUT':
        this.title = 'Fingerprint Model Timed Out';
        break;
      case 'FINISHED':
        this.title = 'Fingerprint Model Training Completed';
        break;
      case 'FATAL':
        this.title = 'Fatal Error';
        break;
    }
  }

  isRunning() {
    const state = this.state;
    const status = this.status;
    return (state === 'PENDING' || state === 'QUEUED') && (status.status === 'processing' || status.status === 'completed');
  }

  gotoLens() {
    this.router.navigateByUrl(`/platform/lenses/train/${this.config.dto.id}`);
  }

  doUnitAnalysis() {
    this.router.navigate(['/platform/units'],
      { queryParams: { new: true, source: this.config.dto.sourceId, lens: this.config.dto.id, from: 'model' } });
  }

  doModelingWorkspace() {
    this.router.navigate([`/platform/lenses/train/${this.config.dto.id}`]);
  }

  doDeploy() {
    window.location.href = window.location.origin + '/analysis/deploy?model=' + encodeURIComponent(this.config.dto.model.uuid);
  }
}

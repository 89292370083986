<div *ngIf="!content" class="placeholder"></div>
<ng-container *ngIf="viewService.textFormat === TextFormat.PLAIN">
  <ng-container *ngFor="let block of content">
    <ng-container *ngFor="let run of block">
      <ng-container *ngTemplateOutlet="run.highlight ? highlight : no_highlight; context: {$implicit: run.text}"></ng-container>
    </ng-container>
  </ng-container>
</ng-container>
<ng-container *ngIf="viewService.textFormat !== TextFormat.PLAIN">
  <p *ngFor="let block of content">
    <ng-container *ngFor="let run of block">
      <ng-container *ngTemplateOutlet="run.highlight ? highlight : no_highlight; context: {$implicit: run.text}"></ng-container>
    </ng-container>
  </p>
</ng-container>

<ng-template #highlight let-text><mark [ngStyle]="{backgroundColor: highlightColor}">{{text}}</mark> </ng-template>
<ng-template #no_highlight let-text>{{text}} </ng-template>

import {ChangeDetectionStrategy, Component, Input, OnChanges} from '@angular/core';
import {FeedCardConfig} from '../../types/feed';
import {Router} from '@angular/router';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { ILabeledDataAsset, annotationStatus, isAnnotationProcessing, isAnnotationError } from '../../types/labeled-data';

@Component({
  selector: 'app-feed-annotation-card',
  template: `
    <div [ngStyle]="{display: showCard() ? 'block' : 'none'}">
      <app-feed-card [title]="title()" [time]="config?.dto.createdAt" [isError]="status === 'Error'">
        <ng-container class="feed-card-body">
          <div class="p-feedcard-primary">{{name()}}</div>
          <div class="p-feedcard-secondary"><span class="p-feedcard-dt">Fingerprint Model : </span>{{config.dto.lens.name}}</div>
          <div *ngIf="isShowProgress()" class="p-feedcard-progress">
            <ngb-progressbar type="info" [striped]="true" [animated]="true" [value]="progress()"></ngb-progressbar>
          </div>
          <div *ngIf="status === 'Error'" class="error">
            <fa-icon [icon]="errorIcon"></fa-icon> {{errorMessage()}}
          </div>
        </ng-container>
        <ng-container class="feed-card-footer">
          <button *ngIf="config?.dto?.isValidDL && status === 'Ready'" type="button" class="btn btn-primary" (click)="trainDL()">Create Deep Learning Model</button>
        </ng-container>
      </app-feed-card>
    </div>
  `,
  styleUrls: ['feed-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnnotationCardComponent implements OnChanges {

  @Input() config: FeedCardConfig<ILabeledDataAsset>;

  status: string;

  errorIcon = faExclamationTriangle;

  constructor(private router: Router) {}

  ngOnChanges() {
    this.status = annotationStatus(this.config.dto);
  }

  title() {
    switch (this.status) {
      case 'NotStarted':
        return 'Annotation Pending';
      case 'Processing':
        return 'Annotation Processing';
      case 'Ready':
        return 'Annotation Created';
      case 'Error':
        return 'Annotation Error';
    }
  }

  isShowProgress() {
    return this.status === 'Processing';
  }

  progress() {
    const taskStatus = this.config.dto.taskStatus;
    if (isAnnotationProcessing(taskStatus)) {
      return Math.floor((taskStatus.done / taskStatus.total) * 100);
    }
  }

  errorMessage() {
    if (isAnnotationError(this.config.dto.taskStatus)) {
      const ts = this.config.dto.taskStatus;
      return ts.msg || ts.exc_message?.join('; ')
    }
    return '';
  }

  trainDL() {
    this.router.navigateByUrl(`/platform/dl?new=true&data=${this.config.dto.id}`);
  }

  name() {
    return this.config?.dto?.givenName ?? '';
  }

  showCard() {
    return this.config?.dto && !this.config.dto.isSoftDeleted;
  }
}

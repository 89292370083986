<section id="createLense">
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="modal-header col-12">
          <div class="modal-title">Annotate</div>
          <div class="exit">
            <span class="btn" (click)="onClose()">
              <fa-icon [icon]="['fal', 'times']"></fa-icon>
            </span>
          </div>
        </div>
      </div>

      <form
        #anForm="ngForm"
        #anFormElement
        [formGroup]="annotateFormGroup"
        class="modal-body"
      >
        <div class="row">
          <div class="col-12">
            <div id="create" class="po-form">
              <div class="row">
                <div class="d-flex justify-content-center mb-5 col-12 userOptions">
                  <span
                    class="me-3"
                    [ngClass]="{selected: !(allUsers$ | async)}"
                    >MY DATA / MODELS</span
                  >
                  <mat-slide-toggle
                    [checked]="(allUsers$ | async)"
                    (change)="setAllUsers($event.checked)"
                    color="#33D9CB"
                ></mat-slide-toggle>
                  <span class="ms-3" [ngClass]="{selected: (allUsers$ | async)}"
                    >ALL DATA / MODELS</span
                  >
                </div>
                <div class="form-group col-9">
                  <div ngbDropdown class="modal-dropdown">
                    <button
                      type="button"
                      class="btn"
                      id="dropdownBasic1"
                      [ngClass]="{ isEmpty: annotateFormGroup.controls.source.value?.id == null }"
                      ngbDropdownToggle
                    >
                      {{ sourceDropDownText }}
                      <app-down-arrow></app-down-arrow>
                    </button>
                    <div
                      ngbDropdownMenu
                      aria-labelledby="dropdownBasic1"
                      role="menu"
                      class="scrollItems"
                    >
                      <ng-container
                        *ngIf="sources$ | async as sources; else loadingDropdownItem"
                      >
                        <ng-container [ngSwitch]="sources.length > 0">
                          <ng-container
                            *ngSwitchCase="false"
                            [ngTemplateOutlet]="emptyDropdownItem"
                          ></ng-container>
                          <ng-container *ngSwitchCase="true">
                            <button
                              type="button"
                              class="dropdown-item"
                              *ngFor="let source of sources | sortByProp: ['id', 'Descending']"
                              (click)="setSource(source)"
                            >
                              {{ source.givenName || source.originalname }}
                            </button>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div id="create" class="po-form">
              <div class="row">
                <div class="form-group col-9">
                  <div ngbDropdown class="modal-dropdown">
                    <button
                      class="btn"
                      id="lenses"
                      type="button"
                      [ngClass]="{ isEmpty: annotateFormGroup.controls.lens.value?.id == null }"
                      ngbDropdownToggle
                    >
                      {{ lensDropDownText }}
                      <app-down-arrow></app-down-arrow>
                    </button>
                    <div ngbDropdownMenu aria-labelledby="lenses" role="menu" class="scrollItems">
                      <ng-container
                        *ngIf="lenses$ | async as lenses; else loadingDropdownItem"
                      >
                        <ng-container [ngSwitch]="lenses.length > 0">
                          <ng-container
                            *ngSwitchCase="false"
                            [ngTemplateOutlet]="emptyDropdownItem"
                          ></ng-container>
                          <ng-container *ngSwitchCase="true">
                            <button
                              type="button"
                              class="dropdown-item"
                              *ngFor="let lens of lenses | sortByProp: ['id', 'Descending']"
                              (click)="setLens(lens)"
                            >
                              {{ lens.name }}
                            </button>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="formButton" class="col-12 text-center">
          <button
            type="submit"
            class="btn btn-primary mt-2"
            [disabled]="annotateFormGroup.invalid || anForm.submitted"
          >
            Annotate
          </button>
        </div>
      </form>
    </div>
  </div>
  <ng-template #loadingDropdownItem>
    <button type="button" class="dropdown-item" disabled>
      <mat-spinner [diameter]="16" [strokeWidth]="2"></mat-spinner>
    </button>
  </ng-template>
  <ng-template #emptyDropdownItem>
    <button type="button" class="dropdown-item" disabled>List is empty</button>
  </ng-template>
</section>

import {ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {FeedCardConfig} from '../../types/feed';
import {Router} from '@angular/router';
import {IUnitAnalysis} from '../../types/unit-analysis';

@Component({
  selector: 'app-feed-unit-collection-card',
  template: `
    <div [ngStyle]="{display: canShowCard() ? 'block' : 'none'}">
      <app-feed-card [title]="title" [time]="config?.dto.createdAt">
        <ng-container class="feed-card-body">
          <div class="p-feedcard-primary">{{config?.dto.givenName}}</div>
          <div class="p-feedcard-secondary"><span class="p-feedcard-dt">Model : </span>{{config?.dto.lens.name}}</div>
          <div class="p-feedcard-secondary"><span class="p-feedcard-dt">Data Set : </span>{{config?.dto.documentCollection.name}}</div>
          <app-progress-balls *ngIf="!config?.dto.isCompleted"></app-progress-balls>
        </ng-container>
        <ng-container class="feed-card-footer">
          <button type="button" class="btn btn-primary" (click)="openUnits()" [attr.disabled]="config?.dto.isCompleted ? null : true">
            Explore Data Set
          </button>
        </ng-container>
      </app-feed-card>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnitAnalysisCardComponent implements OnChanges {

  @Input() config: FeedCardConfig<IUnitAnalysis>;

  title: string;

  constructor(private router: Router) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.config) {
      this.title = this.config.dto.isCompleted ? 'Data Set Analysis Created' : 'Data Set Analysis : New Project Initiated';
    }
  }

  canShowCard() {
    return this.config && !this.config.dto.isFailed;
  }

  openUnits() {
    this.router.navigateByUrl(`/platform/units/analysis/${this.config.dto.id}`);
  }
}

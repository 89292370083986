<div class="row" *ngIf="distributions">
  <div class="col-12">
    <table class="table border-0" *ngFor="let i of [1,2]" [ngClass]="{rounded: i === 1}">
      <tbody (mouseout)="clearHover()">
      <tr>
        <td class="border-0" *ngFor="let dist of distributions; first as isFirst; last as isLast"
            (mouseover)="onHoverCell(dist.topicId)"
            (click)="topicClicked.emit(dist.topicId)"
            [ngClass]="{'visible': isHover(dist), first: isFirst, last: isLast}"
            [style.background-color]="getColor(dist)"
            [style.width.%]="dist.weightFloat * 100">
        </td>

        <td class="border-0 last" [style.background-color]="'#f0f0f0'"
            [style.width.%]="getEmptyPercent() * 100">
          <!--{{ (getEmptyPercent() * 100).toPrecision(2) }}-->
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
<!-- Row-order topic order: -->
<div class="row mt-2">
  <div *ngFor="let dist of labelDistributions; trackBy: distTrackBy"
       class="distLabel mb-2"
       [ngClass]="'col-' + (12 / numLabelColumns)"
       (click)="topicClicked.emit(dist.topicId)"
       (mouseover)="hoverDistribution = dist"
       (mouseout)="clearHover()">
    <div class="topicDot d-inline-block position-relative align-middle" [ngStyle]="{'background-color': getColor(dist)}"></div>
    <div class="ms-2 text-right pct" [ngStyle]="{'font-weight': getDotPercentFontWeight(dist)}">
      {{ dist.weightFloat | percent:'1.0-0' }}
    </div>
    <div class="ms-2 flex-grow-1 flex-shrink-1" [ngStyle]="{'font-weight': getDotTopicFontWeight(dist)}">
      {{ getTopicLabel(dist.topicId) }}
    </div>
  </div>
</div>

<section id="createLense">
  <div class="row m-0">
    <div class="modal-header col-12">
      <div class="modal-title" (click)=forceWrite(lens)>
        <fa-icon [icon]="['fal', 'info-circle']"></fa-icon>
        {{ lens.name }}
      </div>
    </div>
  </div>
  <div class="row m-0">
    <div class="col-4 text-center p-4 bb-1">
      <div class="topics">
        <span> TOPICS </span>
      </div>
      <div class="lens-quantity">
        <span> {{ lens.modifiers.length }} </span>
      </div>
    </div>
    <div class="col-8 p-4 bl-1 bb-1 ps-5 details">
      <div>Created</div><div>{{lens.createdAt | date: 'medium'}}</div>
      <div>Training Data</div><div>{{lens.source.originalname}}</div>
      <div>Iterations</div><div>{{lens.model.numIteration}}</div>
      <div>Initial Topics</div><div>{{lens.model.numberOfTopics}}</div>
      <div>Stop Words</div><div>{{stopWordNames().join('; ')}}</div>
      <div *ngIf="lens.isFinished">Download PTF</div><div *ngIf="lens.isFinished">
        <fa-icon *ngIf="isPtfDownloading" [icon]="['fal', 'circle-notch']" [spin]="true"></fa-icon>
        <button *ngIf="!isPtfDownloading" (click)="download()" class="btn-link">Download</button>
      </div>
    </div>
  </div>
  <cdk-table class="gridList" [dataSource]="data$" matSort matSortActive="topicId" matSortDirection="asc">
    <ng-container cdkColumnDef="topicId">
      <cdk-header-cell *cdkHeaderCellDef mat-sort-header>#</cdk-header-cell>
      <cdk-cell *cdkCellDef="let m">{{m.topicId < 9 ? '0' : ''}}{{m.topicId + 1}}</cdk-cell>
    </ng-container>
    <ng-container cdkColumnDef="label">
      <cdk-header-cell *cdkHeaderCellDef mat-sort-header>TOPIC</cdk-header-cell>
      <cdk-cell *cdkCellDef="let m">
        <span class="aRound d-inline-block align-middle" [ngStyle]="{'background-color': colorService.topicIdColor(m.topicId)}">&nbsp;</span>&nbsp;&nbsp;&nbsp;{{topicLabel(m)}}
      </cdk-cell>
    </ng-container>
    <ng-container cdkColumnDef="coverage">
      <cdk-header-cell *cdkHeaderCellDef mat-sort-header="modifierModel.topicWeights">COVERAGE</cdk-header-cell>
      <cdk-header-cell *cdkHeaderCellDef mat-sort-header="modifierModel.topicWeights">Coverage</cdk-header-cell>
      <cdk-cell *cdkCellDef="let m">{{+(m.modifierModel.topicWeights) | percent:'1.1-1'}}</cdk-cell>
    </ng-container>
    <ng-container cdkColumnDef="topTerms">
      <cdk-header-cell *cdkHeaderCellDef>Top Terms</cdk-header-cell>
      <cdk-cell *cdkCellDef="let m">{{m.modifierModel.topicTerms.join(', ')}}...</cdk-cell>
    </ng-container>
    <ng-container cdkColumnDef="boosted">
      <cdk-header-cell *cdkHeaderCellDef>Boosted Phrases</cdk-header-cell>
      <cdk-cell *cdkCellDef="let m">{{m.filterPhrases.join(', ')}}{{m.filterPhrases.length ? '...' : ''}}</cdk-cell>
    </ng-container>
    <cdk-header-row *cdkHeaderRowDef="['topicId', 'label', 'topTerms', 'boosted', 'coverage']"></cdk-header-row>
    <cdk-row *cdkRowDef="let lens; columns: ['topicId', 'label', 'topTerms', 'boosted', 'coverage']"></cdk-row>
  </cdk-table>
</section>

import {ChangeDetectionStrategy, Component, Input, OnChanges} from '@angular/core';
import {FeedCardConfig} from '../../types/feed';
import {Router} from '@angular/router';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { ISourceFile } from '../../types/source';

@Component({
  selector: 'app-feed-training-data-card',
  template: `
    <div [ngStyle]="{display: showCard() ? 'block' : 'none'}">
      <app-feed-card title="Training Data Added" [time]="config?.dto.createdAt" [isError]="isError">
        <ng-container class="feed-card-body">
          <div class="p-feedcard-primary">{{this.config?.dto?.originalname}}</div>
          <div *ngIf="isError" class="error">
            <fa-icon [icon]="errorIcon"></fa-icon> {{config.dto.validationMsg}}
          </div>
        </ng-container>
        <ng-container class="feed-card-footer">
          <button *ngIf="!isError" type="button" class="btn btn-primary" (click)="trainFP()">Create Fingerprint Model</button>
          <button *ngIf="!isError && this.config?.dto?.isValidDL" type="button" class="btn btn-primary" (click)="trainDL()">Create Deep Learning Model</button>
        </ng-container>
      </app-feed-card>
    </div>
  `,
  styleUrls: ['feed-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrainingDataCardComponent implements OnChanges {

  @Input() config: FeedCardConfig<ISourceFile>;

  isError = false;

  errorIcon = faExclamationTriangle;

  constructor(private router: Router) {}

  ngOnChanges() {
    this.isError = this.config?.dto?.validationState !== 1;
  }

  trainFP() {
    this.router.navigateByUrl(`/platform/lenses?new=true&source=${this.config.dto.id}`);
  }

  trainDL() {
    this.router.navigateByUrl(`/platform/dl?new=true&data=${this.config?.dto?.labeledDatas?.filter(ld => ld.lensId == null)[0].id}`);
  }

  showCard() {
    return this.config?.dto && !this.config.dto.isSoftDeleted;
  }
}

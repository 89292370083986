import { ConfigService } from '../../services-http/config.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { KeycloakService } from 'keycloak-angular';
import * as _ from 'lodash';
import { Observable, Subject } from 'rxjs';
import { UBQ } from '../../app-config';
import { MODAL_CONFIG } from '../../modal/modal.config';
import { ModalService } from '../../shared/modal.service';
import { TopFilterComponent } from '../../shared/top-filter/top-filter.component';
import { ITrainingDataItem } from '../../types/trainingDataItem';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { MatSort } from '@angular/material/sort';
import { TrainingListDataSource } from './TrainingListDataSource';
import { ListFilterService } from '../../../app/shared/top-filter/list-filter.service';
import { SourceService } from '../../../app/services-http/source.service';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';


@Component({
  selector: "app-training-data-list",
  templateUrl: "./training-data-list.component.html",
  styleUrls: ["./training-data-list.component.scss"],
  providers: []
})
export class TrainingDataListComponent implements OnInit {
  public profile: Keycloak.KeycloakProfile;
  public modalRef: NgbModalRef;
  public trainingData = new TrainingListDataSource(this.listFilterService, this.sourceService);
  public deepLearningEnabled$: Observable<boolean>;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  dataSource = new MatTableDataSource<ITrainingDataItem>();

  errorIcon = faExclamationTriangle;

  @ViewChild(TopFilterComponent, { static: true })
  private topFilter: TopFilterComponent;

  @ViewChild(MatSort, { static: false }) matSort;

  @ViewChild('annotatemodal', {static: true}) annotateModal: ElementRef;

  private destroy$ = new Subject<void>();
  
  constructor(
    public ubq: UBQ,
    public modalService: ModalService,
    public configService: ConfigService,
    public router: Router,
    public route: ActivatedRoute,
    public kc: KeycloakService,
    private listFilterService: ListFilterService,
    private sourceService: SourceService,
  ) {}

  ngOnInit() {
    this.kc.loadUserProfile().then((aProfile) => (this.profile = aProfile));

    this.deepLearningEnabled$ = this.configService.get('hardware.gpu_pool_size').pipe(
      map(response => parseInt(response[0]) > 0)
    );

    const openModal = this.open.bind(this);
    const annotateModal = this.annotateModal;
    this.route.queryParams.pipe(take(1))
      .subscribe(params => {
        if (!!params['new']) {
          setTimeout(() => openModal(annotateModal));
        }
      });
  }

  ngAfterViewInit(){
    setTimeout(()=>{
      this.trainingData.sources$.pipe(takeUntil(this.destroy$), filter(data => !!data.data)).subscribe(data => {
        setTimeout(()=>{
          this.dataSource.paginator = this.paginator
          this.dataSource.data = [...data.data]
        })
      })
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  open(content) {
    const options: NgbModalOptions = MODAL_CONFIG;
    options.windowClass = "onUploadData modal-gt";
    this.modalRef = this.modalService.openModal(content, options);
  }

  gotoLens(item: ITrainingDataItem) {
    this.router.navigate(['/platform/lenses'], {
      queryParams: {
        'new': true,
        source: item.sourceDataId
      }
    });
  }

  gotoDl(item: ITrainingDataItem) {
    this.router.navigate(['/platform/dl'], {
      queryParams: {
        'new': true,
        data: item.labeledDataId
      }
    });
  }

  navigateToIngest() {
    window.location.href = "/ingest/upload";
  }

  trackById(index, item : ITrainingDataItem) {
    return item.id;
  }
}

import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import * as _ from 'lodash';
import {Observable, Subject} from 'rxjs';
import {LensService} from '../../services-http/lens.service';
import {ModelService} from '../../services-http/models.service';
import {ILens} from '../../types/lens';
import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {LENS_SELECT_FILTER_OPTIONS} from './lens-list.constants';
import {MODAL_CONFIG} from '../../modal/modal.config';
import {ActivatedRoute, Event, Router, RouterEvent} from '@angular/router';
import {IViewBy} from '../../shared/top-filter/iviewby.interface';
import {filter, finalize, first, take, takeUntil} from 'rxjs/operators';
import {TopFilterComponent} from '../../shared/top-filter/top-filter.component';
import {ListFilterService} from '../../shared/top-filter/list-filter.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-lens',
  templateUrl: './lens-list.component.html',
  styleUrls: ['./lens-list.component.scss']
})
export class LensListComponent implements OnInit {
  public lenses$: Observable<ILens[]>;
  public selectOptions: Array<IViewBy>;
  public order: any;
  public selectedModel: ILens;
  public modalRef: NgbModalRef;
  public loaderMap: Map<string, boolean> = new Map();

  @ViewChild('newLensModal', { static: true }) newLensModal: ElementRef;

  @ViewChild(TopFilterComponent, { static: true })
  public topFilter: TopFilterComponent;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource = new MatTableDataSource<ILens>();

  private destroy$ = new Subject<void>();  
  
  constructor(public modelService: ModelService,
              public route: ActivatedRoute,
              public modalService: NgbModal,
              public lenseService: LensService,
              private listFilterService: ListFilterService,
              private router: Router) {
  }

  ngOnInit() {

    this.init();

    this.route.queryParams.pipe(take(1))
      .subscribe(params => {
      if (!!params['new']) {
        setTimeout(() => {
          this.openModal(this.newLensModal, null, 'modal-gt');
        });
      }
    });

    this.router.events.pipe(
      filter((event: Event | RouterEvent) => event instanceof RouterEvent),
      take(1)
    ).subscribe(() => {
      if (this.modalRef) {
        this.modalRef.close();
      }
    });
  }

  ngAfterViewInit(){
    setTimeout(()=>{
      this.lenses$.pipe(takeUntil(this.destroy$)).subscribe(data => {
        this.dataSource.data = [...data]
        this.dataSource.paginator = this.paginator
      });
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private init() {
    this.selectOptions = LENS_SELECT_FILTER_OPTIONS();
    const defaultView = this.selectOptions.find(item => item.selectedByDefault);

    this.lenses$ = this.listFilterService.createObservableFilter<ILens[]>(
      this.lenseService.getMiniListLeafs.bind(this.lenseService),
      this.topFilter,
      defaultView, ['name', 'createdBy', 'tags']);
  }

  public fetch(): void {
    this.lenseService.loadAll(this.topFilter.allUsers);
  }

  getImageForState(lens: ILens): string {
    const state: string = lens.modelJobState;
    switch (state) {
      case 'READY':
      case 'FINISHED':
        return '/core/assets/images/explore-lens-blue-small.png';
      case 'TIMEDOUT':
        return '/core/assets/images/explore-lens-yellow-small.png';
      default:
        return '/core/assets/images/explore-lens-grey-small.png';
    }
  }

  isExplorerActive(lens: ILens): boolean {
    const state = lens.modelJobState;
    return (state === 'FINISHED' || state === 'READY');
  }

  openModal(content, selectedModel, modalClass: string = '') {
    const options: NgbModalOptions = _.cloneDeep(MODAL_CONFIG);
    options.windowClass = modalClass;
    this.selectedModel = selectedModel;
    this.modalRef = this.modalService.open(content, options);
  }

  onHide(lens: ILens): void {
    lens.isSoftDeleted = !lens.isSoftDeleted;
    this.lenseService.update(lens, () => this.fetch());
  }

  openSummaryModal(content, selectedModel, child?: any) {
    if (!this.isExplorerActive(selectedModel)) {
      return false;
    }

    this.loaderMap.set(selectedModel.id, true);
    this.lenseService.load(selectedModel.id, 'id', false);
    this.lenseService.element.pipe(
      first((lens: ILens) => !!lens && lens.id === selectedModel.id),
      finalize(() => {
        this.loaderMap.set(selectedModel.id, false);
      })
    )
      .subscribe((lens: ILens) => {
        this.selectedModel = lens;
        this.modalService.open(content, _.cloneDeep(MODAL_CONFIG));
      });
  }
}

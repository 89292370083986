import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-box-selector',
  templateUrl: './box-selector.component.html',
  styleUrls: ['./box-selector.component.scss']
})

export class BoxSelectorComponent implements OnInit {

  @Input() options: Array<any>;
  @Input() title: string;
  @Input() selected: any;
  @Output() emitter: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
  }

  onClick(option: any) {
    this.selected = option;
    this.emitter.emit(option);
  }

  formatNumber(aNumber: number): string {
    return new Intl.NumberFormat().format(aNumber);
  }
}

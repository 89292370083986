import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import * as d3Force from 'd3-force';
import * as d3Array from 'd3-array';
import * as d3Sel from 'd3-selection';
import * as d3Drag from 'd3-drag';
import {HamiltonianChart} from './hamiltonian-chart';

@Component({
  selector: 'app-hamiltonian-chart',
  templateUrl: './hamiltonian-chart.component.html',
  styleUrls: ['./hamiltonian-chart.component.scss']
})
export class HamiltonianChartComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() lensId: number = 0; // Used to ensure a consistent graph for the lens (won't change on page reload)
  @Input() completion: number = 0;
  private graph: HamiltonianChart;
  private stepsArray = [];

  private svg: any;
  private ready: boolean = false;
  private count: number = 0;
  private draws: number = 0;

  ngOnInit(): void {
    this.graph = new HamiltonianChart(this.lensId);
    // This code is only for testing:
    // interval(50).subscribe(v => {
    //   this.completion = v;
    //   this.count = this.completion * 5.5;
    //   this.animateLcf();
    // });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.ready) {
      if (changes.completion.currentValue === null) {
        this.reset();
      }
      if ((changes.completion.currentValue && changes.completion.previousValue
        && changes.completion.currentValue > changes.completion.previousValue)
        || (changes.completion.currentValue && !changes.completion.previousValue)) {
        const currentValue = Math.round(changes.completion.currentValue);
        this.count = currentValue === 100 ? -1 : Math.round(currentValue * 5.5);
        this.animateLcf();
      }
    }
  }

  ngAfterViewInit() {
    this.svg = d3Sel.select('#hamilton-svg')
      .append('svg:svg')
      .attr('width', this.graph.width)
      .attr('height', this.graph.height);

    this.initialize();
    this.draw();
  }

  initialize() {
    this.graph.createEdges();
    this.graph.force.on('tick', () => {

      if (this.graph.currentFrame === this.graph.freezeFrameAt) {
        this.graph.force.stop();
        return;
      }

      this.svg.selectAll('circle')
        .attr('cx', function (d) {
          return d.x;
        })
        .attr('cy', function (d) {
          return d.y;
        })
        .call(d3Drag.drag()
          .on('drag', (d: any) => {
            d.x = d.fx = d3Sel.event.x;
            d.y = d.fy = d3Sel.event.y;
          })
          .on('end', (d: any) => {
            delete d.fx;
            delete d.fy;
          })
        );

      this.svg.selectAll('line.link')
        .attr('x1', function (d) {
          return d.source.x;
        })
        .attr('y1', function (d) {
          return d.source.y;
        })
        .attr('x2', function (d) {
          return d.target.x;
        })
        .attr('y2', function (d) {
          return d.target.y;
        });

      this.graph.currentFrame++;
    });
  }

  draw() {
    const numVertices = this.graph.numVertices;
    this.stepsArray = this.graph.steps;
    const lcfEdges = this.graph.edges;

    clearInterval(this.graph.interval);

    // magic vertex
    this.graph.nodes = [{fixed: true, x: this.graph.rcx, y: this.graph.rcy, fx: this.graph.rcx, fy: this.graph.rcy}];
    // arrange nodes in a circle
    this.graph.nodes = this.graph.nodes.concat(d3Array.range(numVertices).map((d, i) => {
      return {
        x: this.graph.rcx + this.graph.radius * Math.cos((i * 2 * Math.PI / numVertices) - Math.PI / 2),
        y: this.graph.rcy + this.graph.radius * Math.sin((i * 2 * Math.PI / numVertices) - Math.PI / 2)
      };
    }));

    this.graph.links = [];
    this.graph.currentVertex = 0;
    this.graph.currentVertexOffset = 0;
    this.graph.force.nodes(this.graph.nodes);

    this.graph.restart();
    if (this.graph.animationSpeed === 0) {
      this.svg.selectAll('.vertex').style('fill', this.graph.colors[0]);
      const nodes = this.graph.nodes.slice(1);
      lcfEdges.forEach((edge) => {
        this.graph.links.push({source: nodes[edge.source], target: nodes[edge.target]});
      });
      this.drawLines();
      (<d3Force.ForceLink<any, any>>this.graph.force.force('links')).links(this.graph.links);
    } else {
      // hamiltonian path
      this.graph.nodes.slice(1).forEach((target, i) => {
        this.graph.links.push({
          source: this.graph.nodes[i === this.graph.nodes.length - 2 ? 1 : i + 2],
          target: target,
          linkDistance: 0
        });
      });
      this.svg.selectAll('.vertex').style('fill', 'white');
      this.drawLines();
      this.graph.interval = setTimeout(this.animateLcf(), this.graph.animationSpeed);
    }
    // drawn last so it has the highest z-index
    this.drawCircles();
    this.ready = true;
  }

  animateLcf(): Function {
    if (this.count !== -1 && (this.count === 0 || this.count <= this.draws)) {
      return;
    }
    const stepInstruction = this.stepsArray[this.graph.currentVertex % this.stepsArray.length];
    this.graph.currentStep = (this.graph.currentVertex + this.graph.currentVertexOffset) -
      this.graph.numVertices * Math.floor((this.graph.currentVertex + this.graph.currentVertexOffset) / this.graph.numVertices);
    const circles = this.svg.selectAll('.vertex');
    if (this.graph.currentVertexOffset === 0) {
      circles.filter((d, i) => this.graph.currentVertex === i).style('fill', this.graph.colors[2]);
      circles.filter((d, i) => this.graph.currentVertex > i).style('fill', this.graph.colors[0]);
      circles.filter((d, i) => this.graph.currentVertex < i).style('fill', 'transparent');
      this.graph.currentVertexOffset += (stepInstruction > 0 ? 1 : -1);
    } else if (this.graph.currentVertexOffset !== stepInstruction) {
      circles.filter((d, i) => this.graph.currentStep === i).style('fill', this.graph.colors[1]);
      this.graph.currentVertexOffset += (stepInstruction > 0 ? 1 : -1);
    } else {
      circles.filter((d, i) => this.graph.currentStep === i).style('fill', this.graph.colors[1]);
      if (this.graph.nodes.slice(1)[this.graph.currentVertex]) {
        this.graph.links.push({
          source: this.graph.nodes.slice(1)[this.graph.currentVertex],
          target: this.graph.nodes.slice(1)[this.graph.currentStep]
        });
      }
      this.drawLines();
      this.graph.currentVertex++;
      this.graph.currentVertexOffset = 0;
    }
    if (this.graph.currentVertex !== this.graph.numVertices) {
      this.graph.interval = setTimeout(() => {
        this.animateLcf();
      }, this.graph.animationSpeed);
    } else {
      this.graph.interval = null;
      this.svg.selectAll('.vertex').style('fill', this.graph.colors[0]);
    }
    (<d3Force.ForceLink<any, any>>this.graph.force.force('links')).links(this.graph.links);
    this.graph.restart();
    this.draws++;
  }

  drawCircles() {
    this.svg.selectAll('circle')
      .data(this.graph.nodes)
      .enter()
      .append('circle')
      .attr('r', function (d, i) {
        return i === 0 ? 0 : 5;
      })
      .attr('class', function (d, i) {
        return i === 0 ? 'magic-vertex' : 'vertex';
      })
      .attr('cx', function (d) {
        return d.x;
      })
      .attr('cy', function (d) {
        return d.y;
      })
      .style('fill', 'transparent')
      // .call(this.graph.force.drag)
    ;
  }

  drawLines() {
    this.svg.selectAll('line.link')
      .data(this.graph.links)
      .enter()
      .append('line')
      .attr('class', 'link')
      .attr('x1', function (d) {
        return d.source.x;
      })
      .attr('y1', function (d) {
        return d.source.y;
      })
      .attr('x2', function (d) {
        return d.target.x;
      })
      .attr('y2', function (d) {
        return d.target.y;
      });
  }

  private reset() {
    d3Sel.select('#hamilton-svg').selectAll('.link').remove();
    d3Sel.select('#hamilton-svg').selectAll('.vertex').remove();
    d3Sel.select('#hamilton-svg').selectAll('.magic-vertex').remove();
    this.count = 0;
    this.draws = 0;
    this.graph = new HamiltonianChart();
    this.initialize();
    this.draw();
  }
}

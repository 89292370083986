import { RouterModule, Routes } from "@angular/router";
import { LensesIndexComponent } from "./lenses.component";
import { LensListComponent } from "./lens-list/lens-list.component";
import { StopWordListComponent } from "./stop-word-list/stop-word-list.component";
import { NgModule } from "@angular/core";
import { CreateLensComponent } from "./create-lens/create-lens.component";
import { LensLoaderComponent } from "../lens-loader/lens-loader.component";
import { TrainModelErrorComponent } from "../lens-loader/train-model-error/train-model-error.component";
import { LensResolver } from "../model-workspace/resolvers/lens-resolver.service";

const routes: Routes = [
    {
        path: '', component: LensesIndexComponent, children: [
            { path: '', pathMatch: 'full', component: LensListComponent },
            { path: 'stopwords', component: StopWordListComponent }
        ],
    },
    { path: 'new', component: CreateLensComponent },
    { path: 'training/:id', component: LensLoaderComponent },
    { path: 'training/:id/error/lowdocumentcount', component: TrainModelErrorComponent, resolve: { lensData: LensResolver }, runGuardsAndResolvers: 'always' },
    { path: 'train', loadChildren: () => import('../model-workspace/model-workspace.module').then(m => m.ModelWorkspaceModule) },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LensesRoutingModule {

}
/* tslint:disable:max-line-length*/

import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgModule } from '@angular/core';
import {AppComponent} from './app.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {AppRoutingModule} from './app-routing.module';
import {TrainingDataListComponent} from './training-data/training-data-list/training-data-list.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {SourceService} from './services-http/source.service';
import {FileUploadComponent} from './training-data/training-data-list/upload.component';
import {ToastrModule} from 'ngx-toastr';
import {LensService} from './services-http/lens.service';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ModelService} from './services-http/models.service';
import {UtilsService} from './utils/utils.service';
import {UBQ} from './app-config';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SandBoxComponent} from './sand-box/sand-box.component';
import {ModelDocumentService} from './services-http/model-document.service';
import {PlatformComponent} from './platform/platform.component';
import {CompletionService} from './services-state/completion.service';
import {UploadTrainingDataComponent} from './upload-training-data/upload-training-data.component';
import {UbqToastService} from './ubq-toast.service';
import {UnitListComponent} from './unit-list/unit-list.component';
import {UnitUploadComponent} from './unit-upload/unit-upload.component';
import {CommentService} from './services-http/comment.service';
import {BusyIndicatorService} from './busy-indicator.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {UploadInstructionsComponent} from './upload-instructions/upload-instructions.component';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {PiensoAuthorizationModule} from '@pienso/auth-module';
import {TaxonomyService} from './services-http/taxonomy.service';
import {PiensoNavigationModule} from '@pienso/pienso-navigation';
import {SharedModule} from './shared/shared.module';
import {FeedModule} from './feed/feed.module';
import {MatSortModule} from '@angular/material/sort';
import {MatLegacySlideToggleModule as MatSlideToggleModule} from '@angular/material/legacy-slide-toggle';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {
  faAlignLeft,
  faArrowToBottom,
  faBars,
  faCopy as falCopy,
  faMinus,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faCheck,
  faCheckCircle,
  faFileAlt,
  faFilter as falFilter,
  faHome,
  faList,
  faLayerPlus,
  faSearch,
  faLock,
  faEye,
  faPencil,
  faFileExport,
  faServer,
  faPlus as falPlus,
  faPlusCircle as falPlusCircle,
  faLevelDown,
  faCircleNotch,
  faSync,
  faShareAltSquare,
  faSlidersVSquare,
  faTimesCircle,
  faEdit,
  faTimes as falTimes,
  faFingerprint,
  faMindShare,
  faExclamationTriangle as falExclamationTriangle,
  faInfoCircle,
  faBullseyeArrow as falBullseyeArrow,
  faClock as falClock,
  faGreaterThanEqual as falGreaterThanEqual,
  faUndo as falUndo,
  faPlay as faPlay,
  // faThTarget,
  faCircle as falCircle,
  faCheckDouble,
  faArrowUp,
  faArrowDown,
  faStar as falStar, faPen, faLockAlt, faMagic, faUpload, faTasksAlt, faAnalytics, faTag, faFilter
} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeModule, FaIconLibrary} from '@fortawesome/angular-fontawesome';
import {
  faCircle as fasCircle,
  faExclamationTriangle as fasExclamationTriangle,
  faFilter as fasFilter,
  faStar as fasStar,
  faLock as fasLock,
  faTimes as fasTimes,
  faCopy as fasCopy,
  faListUl, faTh,
  faPlus as fasPlus, faPlusCircle,
  faPlay as fasPlay,
  faStop as fasStop,
  faRocket,
  faBolt
} from '@fortawesome/pro-solid-svg-icons';
import {CdkTableModule} from '@angular/cdk/table';
import {StopWordsService} from './services-http/stop-words.service';
import {ListFilterService} from './shared/top-filter/list-filter.service';
import {DocumentViewModule} from './document-views/document-view.module';
import {GlobalStopWordService} from './services-http/global-stop-word.service';
import {UnitAnalysisService} from './services-http/unit-analysis.service';
import {DocumentService} from './services-http/document.service';
import {VarDirective} from './utils/var.directive';
import { ngfModule } from 'angular-file';
import {StopWordEditorModule} from './stop-word-editor/stop-word-editor.module';
import {ModelTagModule} from './model-tags/model-tag.module';
import { ModifierReviewModule } from './modifier-review/modifier-review.module';
import {LensLoaderModule} from './lens-loader/lens-loader.module';
import { AnnotateService } from './services-http/annotate.service';
import { AuthInterceptor } from '@pienso/auth-module';
import { CreateAnnotateComponent } from './training-data/create-annotate/create-annotate.component';
import { TrainingDataListItemComponent } from './training-data/training-data-list-item/training-data-list-item.component';
import {MatChipsModule} from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator'; 
import { LensesModule } from './lenses/lenses.module';


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    FileUploadComponent,
    PageNotFoundComponent,
    PlatformComponent,
    SandBoxComponent,
    TrainingDataListComponent,
    UnitListComponent,
    UnitUploadComponent,
    UploadInstructionsComponent,
    UploadTrainingDataComponent,
    VarDirective,
    CreateAnnotateComponent,
    TrainingDataListItemComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CdkTableModule,
    DocumentViewModule,
    FeedModule,
    FontAwesomeModule,
    FormsModule,
    HttpClientModule,
    LensLoaderModule,
    MatChipsModule,
    MatFormFieldModule,
    MatSortModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    ModelTagModule,
    ModifierReviewModule,
    ngfModule,
    NgbModule,
    PiensoAuthorizationModule.forRoot('/core'),
    PiensoNavigationModule,
    ReactiveFormsModule,
    SharedModule,
    StopWordEditorModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-center',
      closeButton: true
    }),
    MatPaginatorModule,
    LensesModule
  ],
  providers: [
    BusyIndicatorService,
    CommentService,
    CompletionService,
    DocumentService,
    ModelDocumentService,
    AnnotateService,
    LensService,
    ListFilterService,
    ModelService,
    SourceService,
    StopWordsService,
    GlobalStopWordService,
    TaxonomyService,
    UBQ,
    UbqToastService,
    UnitAnalysisService,
    UtilsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faArrowToBottom);
    library.addIcons(faAlignLeft);
    library.addIcons(faBars);
    library.addIcons(faChevronLeft);
    library.addIcons(faChevronRight);
    library.addIcons(faChevronDown);
    library.addIcons(faChevronUp);
    library.addIcons(falCircle);
    library.addIcons(faCircleNotch);
    library.addIcons(fasCircle);
    library.addIcons(falCopy);
    library.addIcons(fasCopy);
    library.addIcons(faCheck);
    library.addIcons(faCheckDouble);
    library.addIcons(faCheckCircle);
    library.addIcons(faEdit);
    library.addIcons(faEye);
    library.addIcons(falExclamationTriangle); // light
    library.addIcons(fasExclamationTriangle); // solid
    library.addIcons(faFileAlt);
    library.addIcons(falFilter, fasFilter);
    library.addIcons(faFileExport);
    library.addIcons(faHome);
    library.addIcons(faInfoCircle);
    library.addIcons(faLayerPlus);
    library.addIcons(faLevelDown);
    library.addIcons(faList);
    library.addIcons(faMinus);
    library.addIcons(faLock);
    library.addIcons(fasLock);
    library.addIcons(faPencil);
    library.addIcons(falPlus);
    library.addIcons(fasPlus);
    library.addIcons(faPlusCircle);
    library.addIcons(falPlusCircle);
    library.addIcons(faSearch);
    library.addIcons(faSync);
    library.addIcons(faShareAltSquare);
    library.addIcons(faTimesCircle);
    library.addIcons(falTimes);
    library.addIcons(fasTimes);
    library.addIcons(falStar);
    library.addIcons(fasStar);
    library.addIcons(faPen);
    library.addIcons(faLockAlt);
    library.addIcons(faMagic);
    library.addIcons(faListUl);
    library.addIcons(faTh);
    library.addIcons(faBolt);
    library.addIcons(faRocket);
    library.addIcons(faTag);
    library.addIcons(faFilter);
    library.addIcons(faFingerprint);
    library.addIcons(faMindShare);
    library.addIcons(falBullseyeArrow);
    library.addIcons(falClock);
    library.addIcons(falGreaterThanEqual);
    library.addIcons(falUndo);
    library.addIcons(faPlay);
    library.addIcons(faArrowUp);
    library.addIcons(faArrowDown);
    library.addIcons(fasPlay);
    library.addIcons(fasStop);
  }
}

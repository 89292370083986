<span id="eyeOpen" [title]="eyeOpenTitle" class="eye-hide" *ngIf="!isEyeOpen">
  <svg xmlns:xlink="http://www.w3.org/1999/xlink" width="22px" height="16px" viewBox="0 0 22 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs></defs>
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Training-Data---Hide-Data" transform="translate(-1388.000000, -446.000000)" fill="#9B9B9B" fill-rule="nonzero">
              <g id="training-data-list" transform="translate(38.000000, 124.000000)">
                  <g id="hide-from-list" transform="translate(1350.000000, 73.000000)">
                      <g id="noun_hide_1235606-(1)-copy-4" transform="translate(0.000000, 249.000000)">
                          <path d="M3.29664813,11.569103 C5.13518667,13.1342554 7.99856883,15 11.1966989,15 C12.0838502,14.9954896 12.9657643,14.8628436 13.8154576,14.6061206 C13.9519815,14.565333 14.0297996,14.4208919 13.989269,14.2835023 C13.9487385,14.1461126 13.8052074,14.0678012 13.6686835,14.1085887 C12.8666654,14.3513168 12.0341877,14.4769802 11.1966989,14.4817376 C5.86133215,14.4817376 1.35511022,8.99593031 0.582615035,8.00345785 C1.61103489,6.69724933 2.78133714,5.51083452 4.0717183,4.4663171 C4.14680439,4.40955199 4.1852264,4.31615105 4.17198072,4.22258693 C4.15873503,4.12902282 4.09592255,4.05013577 4.00807127,4.01673084 C3.92021999,3.98332591 3.82126405,4.00070147 3.7498453,4.06207244 C2.36918734,5.17018751 1.1267515,6.44225379 0.0495933554,7.85057045 C-0.0165311185,7.94163427 -0.0165311185,8.06528143 0.0495933554,8.15634525 C1.00667229,9.4082763 2.09533924,10.5524999 3.29664813,11.569103 Z" id="Shape"></path>
                          <path d="M21.9514439,8.14805307 C22.0161854,8.05986871 22.0161854,7.94013129 21.9514439,7.85194693 C21.0151718,6.63973355 19.9501239,5.53169548 18.7748211,4.54710129 C16.9722138,3.03143936 14.1687182,1.22469003 11.0374758,1.22469003 C9.41386859,1.22469003 7.72723319,1.70648985 6.02042875,2.65252387 L3.41610234,0.0603404646 C3.31607395,-0.0249222433 3.16696582,-0.0191896971 3.07384312,0.073498814 C2.98072041,0.166187325 2.97496101,0.314600213 3.06062313,0.414162207 L18.6588492,15.9396595 C18.7588776,16.0249222 18.9079857,16.0191897 19.0011084,15.9265012 C19.0942311,15.8338127 19.0999905,15.6853998 19.0143284,15.5858378 L16.4931993,13.0764637 C18.5912129,11.7679953 20.4392818,10.0993192 21.9514439,8.14805307 Z M8.76845953,5.3777041 C10.151829,4.2303801 12.1859023,4.32226481 13.4591708,5.58959647 C14.7324392,6.85692813 14.8247543,8.88151737 13.6720557,10.2584367 L8.76845953,5.3777041 Z M16.13772,12.7201326 L14.0275349,10.6197865 C15.401856,9.04700101 15.3189863,6.68385375 13.8378366,5.21027092 C12.3566869,3.73668809 9.98243268,3.65526424 8.4028958,5.02388236 L6.38599248,3.01638311 C7.97934611,2.1657053 9.53992505,1.72656484 11.0374758,1.72656484 C16.2612554,1.72656484 20.6732314,7.03890973 21.4295701,8 C20.9404711,8.62483414 18.9160044,11.079002 16.13772,12.7201326 Z" id="Shape"></path>
                          <path d="M11,11.7621879 C11,11.6308479 10.8935279,11.5243757 10.7621879,11.5243757 C8.94815507,11.5217562 7.4782438,10.0518449 7.47562426,8.23781213 C7.47562426,8.10647212 7.36915214,8 7.23781213,8 C7.10647212,8 7,8.10647212 7,8.23781213 C7.00262046,10.3145247 8.68547528,11.9973795 10.7621879,12 C10.8935279,12 11,11.8935279 11,11.7621879 Z" id="Shape"></path>
                      </g>
                  </g>
              </g>
          </g>
      </g>
  </svg>
</span>

<span id="eyeClosed" [title]="eyeClosedTitle" class="eye-hide" *ngIf="isEyeOpen">
  <svg width="24px" height="15px" viewBox="0 0 24 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs></defs>
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Group-42" fill="#9B9B9B" fill-rule="nonzero">
              <path d="M23.0842935,7.48738632 C23.0336702,7.42025348 21.8251191,5.8178902 19.8565776,4.19157341 C17.2318557,2.02283586 14.5106932,0.87653467 11.9878587,0.87653467 C9.46470372,0.87653467 6.7435412,2.02283586 4.11881927,4.19157341 C2.14995742,5.8178902 0.941726659,7.42025348 0.89110336,7.48738632 L0.824139629,7.57721196 C0.787293557,7.62637968 0.787293557,7.7064348 0.824139629,7.75560252 C0.861306102,7.80540059 0.89110336,7.84542816 0.89110336,7.84542816 C0.941726659,7.912561 2.15027782,9.51492428 4.11881927,11.1412411 C6.7432208,13.3099786 9.46470372,14.4562798 11.9878587,14.4562798 C14.5106932,14.4562798 17.2315353,13.3099786 19.8565776,11.1412411 C21.8254395,9.51492428 23.0336702,7.912561 23.0842935,7.84542816 L23.1509369,7.75560252 C23.1877829,7.7064348 23.1877829,7.62637968 23.1509369,7.57721196 C23.1140908,7.52741389 23.0842935,7.48738632 23.0842935,7.48738632 Z M22.3396825,7.80067293 C21.2810788,9.08281569 17.0034101,13.8530297 11.9878587,13.8530297 C6.97198685,13.8530297 2.69431808,9.08281569 1.63571442,7.80067293 C1.5745179,7.72692135 1.5745179,7.6062083 1.63539401,7.5315112 C2.69303648,6.24999879 6.96750124,1.47978473 11.9878587,1.47978473 C17.0030897,1.47978473 21.2810788,6.25062914 22.3396825,7.5315112 C22.4005586,7.6062083 22.4005586,7.72723653 22.3396825,7.80067293 Z" id="Shape"></path>
              <path d="M11.9876958,3.82865318 C9.81948759,3.82865318 8.05555362,5.55024523 8.05555362,7.66640724 C8.05555362,9.78256925 9.81948759,11.5041613 11.9876958,11.5041613 C14.1555889,11.5041613 15.9195229,9.78256925 15.9195229,7.66640724 C15.9195229,5.55024523 14.1555889,3.82865318 11.9876958,3.82865318 Z M11.9876958,10.9149204 C10.152234,10.9149204 8.65897155,9.45781019 8.65897155,7.66640724 C8.65897155,5.87500429 10.152234,4.41758651 11.9876958,4.41758651 C13.8228425,4.41758651 15.316105,5.87531183 15.316105,7.66640724 C15.316105,9.45750265 13.8228425,10.9149204 11.9876958,10.9149204 Z" id="Shape"></path>
          </g>
      </g>
  </svg>
</span>

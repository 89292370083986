import {ISuggestion, ITermWeight} from './model';
import {ILens} from './lens';
import {ITopicData} from './lens';
import {IBase} from './base';

export enum ModifierState {
    UNCLEAR = 'unclear',
    MERGED = 'merged',
    LABELED = 'labeled',
    UNLABELED = 'unlabeled',
    CUSTOM = 'custom'
}

export enum LabelSource {
    USER = 0,
    SUGGESTION
}

export interface IModifier extends IBase {
    topicId: number;
    label: string;
    labelChangedOnStage: number;
    labelSource: LabelSource;
    isUnclear: boolean;
    mergedClusterId: number;
    duplicateOfTopicId: number;
    filters: ITermWeight[];
    filterPhrases: string[];
    customFilterPhrases: string[];
    lens?: ILens;
    lensId: number;
    customTopic: ICustomTopic | null;
    modifierModel: IModifierModel;
    customTopicStatus?: ICustomTopicStatus;
    removeCustomFilterPhrases: string[];
    removeFilterPhrases: string[];
    appliedCustomFilterPhrases: string[];
    appliedFilterPhrases: string[];
}

export interface IModifierModel {
    suggestedMergedFrom: number[];
    suggestMap: ISuggestion;
    documentCount: number;
    topicPhrases: string[];
    topicTopDocumentIds: number[];
    topicTerms: string[];
    topicWeights: number;
    topTermWeights: ITermWeight[];
}

export interface ICustomTopic {
    seed: string;
    stageCreated: number;
}

export function isNewCustomTopic(mod: IModifier, lens: ILens) {
    return !!mod.customTopic && mod.customTopic.stageCreated === lens.stage;
}

export type ICustomTopicStatus = {
    seed: string;
    status: 'PROCESSING' | 'COMPLETE';
    progress: number;
    message?: string;
    topicData?: ITopicData;
} | {
    seed?: string;
    status: 'ERROR';
    progress?: number;
    message: string;
    topicData?: ITopicData;
};

export function getRenderLabel(modifier: IModifier): string {
    if (modifier.mergedClusterId != null) {
        return `Merged Into ${modifier.mergedClusterId + 1}`;
    } else if (modifier.isUnclear) {
        return 'Unclear ' + (modifier.topicId + 1);
    } else if (modifier.label) {
        return modifier.label;
    } else {
        return 'Topic ' + (modifier.topicId + 1);
    }
}

export function getModifierState(modifier: IModifier): ModifierState {
    if (modifier.customTopic) {
        return ModifierState.CUSTOM;
    } else if (modifier.isUnclear) {
        return ModifierState.UNCLEAR;
    } else if (isMerged(modifier)) {
        return ModifierState.MERGED;
    } else if (isLabeled(modifier)) {
        return ModifierState.LABELED;
    } else {
        return ModifierState.UNLABELED;
    }
}

export function isMerged(modifier: IModifier) {
    return modifier.mergedClusterId != null && modifier.mergedClusterId >= 0;
}

export function isLabeled(modifier: IModifier) {
    return !!modifier.label;
}

export function setUnclear(modifier: IModifier) {
    modifier.isUnclear = true;
    modifier.label = null;
    modifier.mergedClusterId = null;
    // modifier.filters = [];
}

export function setDefault(modifier: IModifier) {
    modifier.isUnclear = false;
    modifier.label = null;
    modifier.mergedClusterId = null;
    modifier.filters = [];
}

export function setModifierLabel(modifier: IModifier, label: string, stage: number, source: LabelSource) {
    modifier.label = label;
    modifier.labelSource = source;
    modifier.mergedClusterId = null;
    modifier.isUnclear = false;
    modifier.labelChangedOnStage = stage;
}

export function getLookupId(modifier: IModifier): number {
    if (isDuplicateTopic(modifier)) {
        return modifier.duplicateOfTopicId;
    } else {
        return modifier.topicId;
    }
}

export function isDuplicateTopic(modifier: IModifier): boolean {
    return modifier.duplicateOfTopicId != null && modifier.duplicateOfTopicId > -1;
}

import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {UBQ} from '../app-config';
import {FeedCardConfig} from '../types/feed';
import {map} from 'rxjs/operators';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {SourceService} from '../services-http/source.service';

@Injectable()
export class FeedService {

  private endpoint: string;

  private _cardConfig$ = new BehaviorSubject<FeedCardConfig<any>[]>([]);

  constructor(private http: HttpClient, private appConfig: UBQ, private sourceService: SourceService) {
    this.endpoint = appConfig.apiEndpoint + '/feed';
  }

  private httpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }

  private hydrateDTOs(feed: FeedCardConfig<any>[]) {
    feed.forEach(c => {
      if (c.dto && c.dto.createdAt && typeof c.dto.createdAt === 'string') {
        c.dto.createdAt = new Date(c.dto.createdAt);
      }
    });
    return feed;
  }

  public get cardConfig$(): Observable<FeedCardConfig<any>[]> { return this._cardConfig$.asObservable(); }

  refreshFeed(): Subscription {
    return this.http.get<FeedCardConfig<any>[]>(this.endpoint, this.httpOptions()).pipe(
      map(feed => this.hydrateDTOs(feed)),
    ).subscribe(cards => this._cardConfig$.next(cards));
  }
}

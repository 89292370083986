<div class="row topnav-secondary control-bar">
  <div class="col-12 ps-3 pe-4">
    <ng-template #annotatemodal>
      <app-create-annotate [modalRef]="modalRef"></app-create-annotate>
    </ng-template>
    <div class="d-flex justify-content-between">
      <div class="action-buttons">
        <button class="btn btn-primary" (click)="navigateToIngest();">ADD DATA</button>

        <button class="btn btn-primary" *ngIf="deepLearningEnabled$ | async" (click)="open(annotatemodal);">
          CREATE ANNOTATION
        </button>

      </div>
      <div class="pe-0">

        <div class="alldata d-inline-block">
          <label class="me-1">ALL DATA</label>
          <mat-slide-toggle (change)="trainingData.onOnlyLabeledChange($event.checked)"
            color="#33D9CB">
          </mat-slide-toggle>
          <label class="ms-1 me-5">LABELED</label>
        </div>
        <app-top-filter [hasTextFilter]="true" [hasOrderFilter]="true" [selectOptions]="trainingData.selectOptions"
          (textFilterChange)="trainingData.onFilterTextChanged($event);"
          (onAllUsers)="trainingData.onAllUsersChanged($event);"
          (orderOptionSelected)="trainingData.onViewChanged($event);">
        </app-top-filter>
      </div>
    </div>
  </div>
</div>

<div class="tableWrapper">
  <cdk-table matSort (matSortChange)="trainingData.onSortDataChanged($event);" [matSortDisableClear]="true"
    [matSortStart]="trainingData.sortCriteria.value.direction" [matSortActive]="trainingData.sortCriteria.value.active"
    [matSortDirection]="trainingData.sortCriteria.value.direction" class="gridList paginated-table" [dataSource]="dataSource" [ngClass]="dataSource.data.length > 25 ? 'paginator-visible' : ''">
    <ng-container cdkColumnDef="name">
      <cdk-header-cell *cdkHeaderCellDef mat-sort-header>Data name</cdk-header-cell>
      <cdk-cell *cdkCellDef="let row" class="pe-1 allow-padding-right">
        <span [matTooltip]="row.name" appShowIfTruncated>
          {{row.name}}
        </span>
      </cdk-cell>
    </ng-container>
    <ng-container cdkColumnDef="labeled">
      <cdk-header-cell *cdkHeaderCellDef mat-sort-header>Labeled</cdk-header-cell>
      <cdk-cell *cdkCellDef="let row" class="text-start">
        <fa-icon *ngIf="row.labeled && row.isDlEnabled && row.progress == null && row.status === 'Ready'" [icon]="['fal', 'check-circle']"></fa-icon>
        <fa-icon *ngIf="row.progress != null" [icon]="['fal', 'circle-notch']" [spin]="true"></fa-icon>
      </cdk-cell>
    </ng-container>
    <ng-container cdkColumnDef="labelType">
      <cdk-header-cell *cdkHeaderCellDef mat-sort-header>Label Type</cdk-header-cell>
      <cdk-cell *cdkCellDef="let row">
        <app-single-multi-label *ngIf="row.isDlEnabled" [isMultilabel]="row.multilabel"></app-single-multi-label>
      </cdk-cell>
    </ng-container>
    <ng-container cdkColumnDef="labelSource">
      <cdk-header-cell *cdkHeaderCellDef mat-sort-header>Label source</cdk-header-cell>
      <cdk-cell *cdkCellDef="let row">
        <fa-icon *ngIf="row.labelSourceType == 'IngestUpload'" [icon]="['fal', 'upload']">
        </fa-icon>
        <fa-icon *ngIf="row.labelSourceType == 'Fingerprint'" [icon]="['fal', 'fingerprint']">
        </fa-icon>
        <span [matTooltip]="row.name" appShowIfTruncated>
          {{row.labelSource}}
        </span>
      </cdk-cell>
    </ng-container>
    <ng-container cdkColumnDef="size">
      <cdk-header-cell *cdkHeaderCellDef mat-sort-header>Size</cdk-header-cell>
      <cdk-cell *cdkCellDef="let row" class="d-flex align-items-center justify-content-left">
        {{row.size ? (row.size | bytesPipe) : ''}}
      </cdk-cell>
    </ng-container>
    <ng-container cdkColumnDef="createdAt">
      <cdk-header-cell *cdkHeaderCellDef mat-sort-header>Created</cdk-header-cell>
      <cdk-cell *cdkCellDef="let row">{{row.createdAt | date:'medium'}}</cdk-cell>
    </ng-container>
    <ng-container cdkColumnDef="createdBy">
      <cdk-header-cell *cdkHeaderCellDef mat-sort-header>Uploaded By</cdk-header-cell>
      <cdk-cell *cdkCellDef="let row">{{row.createdBy}}</cdk-cell>
    </ng-container>
    <ng-container cdkColumnDef="next">
      <cdk-header-cell *cdkHeaderCellDef></cdk-header-cell>
      <cdk-cell *cdkCellDef="let row" class="d-flex align-items-center justify-content-center">
        <div *ngIf="row.isDlEnabled && row.status !== 'Error'" ngbDropdown class="d-inline-block">
          <a class="action-link" id="dropdownBasic1" ngbDropdownToggle>Create New Model</a>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <button class="btn btn-sm" (click)="gotoDl(row);" ngbDropdownItem>Deep Learning</button>
            <button *ngIf="row.isValidFP" class="btn btn-sm" (click)="gotoLens(row);" ngbDropdownItem>Fingerprint</button>
          </div>
        </div>
        <span *ngIf="row.isFpEnabled">
          <a title="Create New Model" class="action-link" (click)="gotoLens(row);">Create New Model</a>
        </span>
        <div *ngIf="row.errorMessage" [matTooltip]="row.errorMessage" class="error">
          <fa-icon [icon]="errorIcon"></fa-icon>&nbsp;Error
        </div>
        <div class="flex-fill" *ngIf="row.status === 'Processing'">
          <div *ngIf="row.progress != null" class="d-flex flex-column">
            <div class="progress ubprogress">
              <div class="progress-bar bg-info progress-bar-striped progress-bar-animated ubprogress-bar"
                [style.width.%]="row.progress">
              </div>
            </div>
            <div class="ubprogress-text">{{row.progressText}}</div>
          </div>
          <div *ngIf="row.progress == null" class="la-ball-beat ball-beat-color">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <ng-template #newLensModal>
          <app-create-lens [modalRef]="modalRef" [sourceId]="row?.id"></app-create-lens>
        </ng-template>
        <div *ngIf="row.status === 'Unknown'" class="la-ball-beat ball-beat-color">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </cdk-cell>
    </ng-container>
    <ng-container cdkColumnDef="download">
      <cdk-header-cell *cdkHeaderCellDef></cdk-header-cell>
      <cdk-cell *cdkCellDef="let row">
        <app-training-data-list-item itemType="downloadAction" [row]="row"></app-training-data-list-item>
      </cdk-cell>
    </ng-container>
    <ng-container cdkColumnDef="visibility">
      <cdk-header-cell *cdkHeaderCellDef></cdk-header-cell>
      <cdk-cell *cdkCellDef="let row" class="d-flex align-items-center">
        <app-training-data-list-item itemType="deleteAction" [row]="row"></app-training-data-list-item>
      </cdk-cell>
    </ng-container>

    <cdk-header-row 
      *cdkHeaderRowDef="['name', 'labeled', 'labelType', 'labelSource', 'size', 'createdAt', 'createdBy', 'next', 'download', 'visibility']">
    </cdk-header-row>
    <cdk-row 
      *cdkRowDef="let lens; columns: ['name', 'labeled', 'labelType', 'labelSource', 'size', 'createdAt', 'createdBy', 'next', 'download', 'visibility']">
    </cdk-row>
  </cdk-table>
  <mat-paginator [hidden]="dataSource.data.length < 25"
                [length]="25"
                [pageSize]="25"
                [pageSizeOptions]="[25, 50, 100]"
                aria-label="Select page">
  </mat-paginator>
</div>
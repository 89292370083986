import {Component, Input, OnInit} from '@angular/core';
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons';
import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import {MODAL_CONFIG} from '../modal/modal.config';
import { ITaggedModel } from '../types/tags';
import {ModelTagsService, ModelTagsUpdateHandler} from './model-tag-modal/model-tag-modal.component';

@Component({
  selector: 'app-model-tags',
  templateUrl: './model-tags.component.html',
  styleUrls: ['./model-tags.component.scss']
})
export class ModelTagsComponent implements OnInit {

  @Input() model: ITaggedModel;
  @Input() modelTagsUpdateHandler: ModelTagsUpdateHandler;
  @Input() modelTagsService: ModelTagsService;

  addIcon = faPlusCircle;

  public modalRef: NgbModalRef;

  constructor(public modalService: NgbModal) { }

  ngOnInit() {
  }

  openModal(content, modalClass: string = '') {
    const options: NgbModalOptions = _.cloneDeep(MODAL_CONFIG);
    options.windowClass = modalClass;

    this.modalRef = this.modalService.open(content, options);
  }

}

import { IModelAsset } from '../types/model-asset';
import {Injectable} from '@angular/core';
import {UBQ} from '../app-config';
import {BaseService} from './base.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AnnotateService extends BaseService<any> {

  constructor(public _http: HttpClient, public ubq: UBQ, public router: Router) {
    super(_http, ubq, router);
    this.init('annotate');
  }

  doAnnotate(lensId: number, sourceId: number): Observable<AnnotateResponse> {
    return this._http.post<AnnotateResponse>(`${this.baseURL}/${this.endpoint}/startAnnotation`, {
      lensId,
      sourceId
    });
  }

  revoke(modelAssetId: string | number): Observable<any> {
    return this._http.get<any>(`${this.baseURL}/${this.endpoint}/revoke/${modelAssetId}`);
  }

  getModelAssetStatus(modelAssetId: number): Observable<IModelAsset> {
    return this._http.get<IModelAsset>(`${this.baseURL}/deep-learning/${modelAssetId}`);
  }

  downloadAnnotateById(id: number, asFileName: string): Observable<string> {
    const url: string = this.ubq.apiEndpoint + `/annotate/download/labeled/${id}`;
    return this.downloadFileToDisk(url, asFileName);
  }

  downloadDeepById(id: number, asFileName: string): Observable<string> {
    const url: string = this.ubq.apiEndpoint + `/annotate/download/deep/${id}`;
    return this.downloadFileToDisk(url, asFileName);
  }
}

export type AnnotateResponse = {taskId: string, modelAsset: IModelAsset};

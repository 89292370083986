import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import * as _ from 'lodash';
import {KeycloakService} from 'keycloak-angular';
import {Observable, Subject} from 'rxjs';
import {UBQ} from '../app-config';
import {UnitAnalysisService} from '../services-http/unit-analysis.service';
import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {SELECT_FILTER_UA_OPTIONS} from './unit-list.constants';
import {MODAL_CONFIG} from '../modal/modal.config';
import {ActivatedRoute, Event, Router, RouterEvent} from '@angular/router';
import {filter, take, takeUntil} from 'rxjs/operators';
import {ListFilterService} from '../shared/top-filter/list-filter.service';
import {TopFilterComponent} from '../shared/top-filter/top-filter.component';
import {IUnitAnalysis} from '../types/unit-analysis';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-unit-list',
  templateUrl: './unit-list.component.html',
  styleUrls: ['./unit-list.component.scss']
})
export class UnitListComponent implements OnInit, AfterViewInit {
  public profile: Keycloak.KeycloakProfile;
  public units$: Observable<IUnitAnalysis[]>;
  public modalRef: NgbModalRef;
  public loaderMap: Map<number, boolean> = new Map();

  public order: any = _.first(SELECT_FILTER_UA_OPTIONS);
  public selectUAOptions: Array<any>;

  @ViewChild('uploadModal', { static: true }) uploadModal: ElementRef;

  @ViewChild(TopFilterComponent, { static: true })
  public topFilter: TopFilterComponent;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  dataSource = new MatTableDataSource<IUnitAnalysis>();

  private destroy$ = new Subject<void>();

  constructor(public unitService: UnitAnalysisService,
              public ubq: UBQ,
              public modalService: NgbModal,
              private route: ActivatedRoute,
              private router: Router,
              public kc: KeycloakService,
              private listFilterService: ListFilterService) {
  }

  ngOnInit() {
    this.selectUAOptions = _.cloneDeep(SELECT_FILTER_UA_OPTIONS);
    this.kc.loadUserProfile().then(aProfile => this.profile = aProfile);

    this.units$ = this.listFilterService.createTablePipeline<IUnitAnalysis>(this.unitService,
      this.topFilter, this.selectUAOptions[0], ['givenName', 'source.givenName', 'lens.name', 'createdBy']);

    this.router.events.pipe(
      filter((event: Event | RouterEvent) => event instanceof RouterEvent),
      take(1)
    ).subscribe(() => {
      if (this.modalRef) {
        this.modalRef.close();
      }
    });
  }

  ngAfterViewInit() {
    this.route.queryParams.subscribe(params => {
      if (!!params['new']) {
        this.open(this.uploadModal);
      }
    });

    setTimeout(()=>{
      this.units$.pipe(takeUntil(this.destroy$)).subscribe(data => {
          this.dataSource.paginator = this.paginator
          this.dataSource.data = [...data]
        })
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  toFile(row: IUnitAnalysis): void {
    const url = this.ubq.apiEndpoint + '/analysis/report/' + row.id;
    this.loaderMap.set(row.id, true);
    this.unitService.downloadFileToDisk(url).subscribe({
      complete: () =>{
        this.loaderMap.set(row.id, false);
      }
    });
  }

  doComplete(success: boolean) {
    this.modalRef.close();
  }

  open(content) {
    const options: NgbModalOptions = MODAL_CONFIG;
    options.windowClass = 'onUploadData modal-gt';

    this.modalRef = this.modalService.open(content, options);
  }

  onHide(ua: IUnitAnalysis): void {
    ua.isSoftDeleted = !ua.isSoftDeleted;
    this.unitService.update(ua);
  }
}

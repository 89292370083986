import {Component} from '@angular/core';
import {KeycloakService} from 'keycloak-angular';


@Component({
  selector: 'app-feed-welcome-card',
  template: `
    <app-feed-card [title]="'Welcome'">
      <ng-container class="feed-card-body">
        <img class="d-block" src="/core/assets/images/welcome.png" style="width: 100%; border-radius: 4px; margin-bottom: 10px">
        <p class="p-feedcard-secondary text-start">Welcome to Pienso, a machine learning platform that lets you explore language data without a single line of code. Experiment, train, and deploy effortlessly, with an interactive and responsive learning interface that imprints your expertise at AI scale. Check out the User Manual for detailed walk-throughs of every part of the application. Or if you're already familiar with Pienso and prefer to dive right in, head to Ingest to start by uploading a data set.</p>
      </ng-container>
      <ng-container class="feed-card-footer">
        <button type="button" class="btn btn-primary" (click)="navDocs()">User Manual</button>
        <button type="button" class="btn btn-primary" (click)="navIngest()">Upload Data</button>
      </ng-container>
    </app-feed-card>
  `
})
export class WelcomeCardComponent {

  profile: Keycloak.KeycloakProfile;

  constructor(private kc: KeycloakService) {
    kc.loadUserProfile().then((aProfile => this.profile = aProfile));
  }

  navDocs() {
    window.open(
      'https://pienso.atlassian.net/wiki/spaces/usermanual/overview',
      '_blank'
    );
  }

  navIngest() {
    window.location.href = window.location.origin + '/ingest';
  }

}
